.ontodia-drag-resizable-column.ontodia-drag-resizable-column--docked-bottom,
.ontodia-drag-resizable-column.ontodia-drag-resizable-column--docked-top {
  .ontodia-drag-resizable-column__handle {
    height: 8px;
    width: 100%;
    top: initial;
    left: 0;
    cursor: ns-resize;
  }

  .ontodia-drag-resizable-column__handle-btn {
    height: 100%;
    width: 40px;
    top: 0;
    left: 50%;
    margin-left: -20px;
    margin-top: 0px;
  }
}

.ontodia-drag-resizable-column {
  display: flex;
  flex-direction: column;
  position: relative;

  &__handle {
    background: $color-white url("../images/resizable-column-handle.png") repeat;
    width: 6px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -12px;
    left: initial;
    z-index: 2;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s;
    cursor: ew-resize;
  }

  &__handle-btn {
    background: #eee;
    cursor: pointer;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -20px;
    width: 100%;
    z-index: 1;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s;

    &::before {
      background-position: 0 0 ;
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      height: 6px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -3px;
      margin-left: -3px;
      width: 6px;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      transition: 0.3s;
    }

    &:hover {
      background: #b3b3b3;
      transform: scale(1.2);
    }
  }

  &--closed &__handle-btn {
    &::before { transform: rotate(180deg); }
  }

  &--docked-left { 
    margin-left: 3px;
    margin-top: 45px;
    min-width: fit-content;
  }

  &--docked-left &__handle { right: -8px; }
  &--docked-left &__handle-btn {
    &::before { background-image: url("../images/arrow-left.png"); }
    &:hover::before { background-image: url("../images/arrow-left1.png"); }
  }

  &--docked-right { 
    margin-left: 8px; 
    margin-top: 45px;
    min-width: fit-content;
  }

  &--docked-right &__handle { left: -8px; }
  &--docked-right &__handle-btn {
    &::before { background-image: url("../images/arrow-right.png"); }
    &:hover::before { background-image: url("../images/arrow-right1.png"); }
  }

  &--docked-top { margin-bottom: 8px; }
  &--docked-top &__handle { bottom: -8px; }
  &--docked-top &__handle-btn {
    &::before { background-image: url("../images/arrow-top.png"); }
    &:hover::before { background-image: url("../images/arrow-top1.png"); }
  }

  &--docked-bottom { margin-top: 8px; }
  &--docked-bottom &__handle { top: -8px; }
  &--docked-bottom &__handle-btn {
    &::before { background-image: url("../images/arrow-bottom.png"); }
    &:hover::before { background-image: url("../images/arrow-bottom1.png"); }
  }
}
