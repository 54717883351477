.ontodia-halo-link {

  &__button {
    background: transparent;
    border: 0 none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    opacity: 0.8;
    outline: none;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__edit,
  &__delete {

    border-radius: 2px;
    border: 1px solid $color-border;
    background-color: $color-white;
    cursor: pointer;
    padding: 0;
    outline: none;
    opacity: 0.8;
    height: 32px;
    width: 32px;

    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;

    i {
      font-size: 23px;
      color: $color-secondary;
    }
  }

  &__spinner {
    position: absolute;
  }

  &__edit-label-button {
   // background: transparent url("../images/font-awesome/pen-square-solid.svg") no-repeat;
  //  background-size: cover;
    border-radius: 2px;
    border: 1px solid $color-border;
    background-color: $color-white;
    cursor: pointer;
    padding: 0;
    position: absolute;
    margin-left: 5px;
    outline: none;
    opacity: 0.8;
   // transition: opacity 0.3s;

   i {
    font-size: 23px;
    color: $color-secondary;
   }

    &:hover {
      opacity: 1;
    }
  }
}
