@keyframes ontodia-progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

.ontodia-progress-bar {
  flex-shrink: 0;
  -webkit-flex-shrink: 0; /* safari 8 */
  width: 100%;

  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  overflow: hidden;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);

  &__bar {
    float: left;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: $color-white;
    text-align: center;
    transition: width .6s ease;

    background-color: #337ab7;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-repeat: repeat-x;
    background-size: 40px 40px;

    animation: ontodia-progress-bar-stripes 2s linear infinite;
  }

  &--error &__bar {
    background-color: #E72F2F;
  }

  &--loading,
  &--error {
    /* property name | duration | delay */
    transition: height 300ms 300ms;
  }

  &--completed {
    /* property name | duration | delay */
    transition: height 200ms 0ms;
  }
}
