/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@import '~basic-styles.scss';
@import '../../commons/variables.scss';

$rs-button-size: 38px;
$rs-button-arrow-border: $rs-button-size * 0.74;

$rs-breadcrumbs-scale: 0.74;
$rs-breadcrumb-button-size: $rs-button-size * $rs-breadcrumbs-scale;
$rs-breadcrumb-arrow-border: $rs-button-arrow-border * $rs-breadcrumbs-scale;
$rs-breadcrumb-arrow-top: $rs-arrow-top * $rs-breadcrumbs-scale;
$rs-breadcrumb-arrow-side: $rs-arrow-side * $rs-breadcrumbs-scale;

.breadcrumbs {
//  min-height: $rs-button-size;
 // margin-bottom: 5px;
//  overflow: hidden;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 15px;
}

.conjunct {
  display: flex;
}

.values {

  padding: 0 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 30px;
  text-align: center;
  white-space: nowrap;
  cursor: auto;

  color: $color-secondary;
  background: $color-light;
  text-shadow: none;

  border: 1px solid $color-border-darker;
  border-right: none;
  border-radius: $border-radius;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &:hover, &:focus, &focus {
    color: $color-secondary;
  }

}

.relation {
  font-size: $font-size-small;
  font-weight: 500;
}

.disjunct {
  text-transform: uppercase;
  font-weight: 700;
}

.or {
  font-size: $font-size-small;
  font-weight: 500;
}

.cancelButton {

  background: $color-light;

  padding: 0 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid $color-border-darker;

  display: flex;
  align-items: center;

  &:hover, &:focus, &focus {
  //  color: $color-secondary;
  }

  & > i {

    color: $color-secondary;
    font-size: 14px;
  //  @include icon(remove);
  }
}
