/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.create-ldp-resource-modal {
  &__form {
    .Select {
      width: 200px;
    }

    display: flex;
    justify-content: center;

    margin: $padding-base-vertical $padding-base-horizontal;

    &__collection-name {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }

    &__save-button {
      display: inline-block;
      width: auto;
      vertical-align: middle;
      @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    }
  }
}
