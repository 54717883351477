.ontodia-accordion {
  flex: auto;
  display: flex;
  height: 100%;
  width: 100%;

  &--scrollable {
    overflow: auto;
  }

  &--vertical {
    flex-direction: column;
  }

  &--vertical:not(&--resizing) > .ontodia-accordion-item {
    transition: height 0.3s ease-in-out;
  }

  &.ontodia-accordion--horizontal  {
    .ontodia-accordion-item:first-child,
    .ontodia-accordion-item:last-child {
      padding: 0 8px;
    }    
  }
}

.ontodia-accordion-item {
  display: flex;
  position: relative;
  flex: auto;

  &--resizing > &__handle, &__handle:hover {
    background: $color-resize-bar-hover;
  }

  &--vertical {

    &:first-child {
      border-top-width: 0;
    }
  }

  &--horizontal {
    border-right: 1px solid $color-border;

    &.ontodia-accordion-item--collapsed {
      background-color: $color-sidebar-bg;
    }

    &:last-child {
      border-right-width: 0;
    }
  }

  &__handle {
    position: absolute;
    z-index: 2;
  }

  &__handle-vertical {
    height: 4px;
    bottom: -2.5px;
    left: 0;
    width: 100%;
    cursor: ns-resize;

    background: $color-resize-bar;
  }

  &__handle-horizontal {
    height: 100%;
    top: 0;
    right: -2.5px;
    width: 5px;
    cursor: ew-resize;
  }

  &__handle-btn {
    background: $color-white;
    border: 1px solid $color-border;
  //  box-shadow: 0 0 0 1px rgba(9, 30, 66, 0.08), 0 2px 4px 1px rgba(9, 30, 66, 0.08);
    border-radius: 10px;
    cursor: pointer;
    height: 20px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    z-index: 20;
    transition: 0.3s;

    &::before {
     background-position: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      height: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -4px;
      margin-left: -4px;
      width: 8px;
      transition: background 0.3s; 
    }

    &:hover {
      background: $color-km-handle;
    }
  }

  &__handle-btn-left {
    border-radius: 50%;
    height: 25px;
    width: 25px;

    left: 100%;
    margin-left: -12px;
    &:before { background-image: url("../images/arrow-left.png"); }
    &:hover:before { background-image: url("../images/arrow-left1.png"); }
  }

  &__handle-btn-right {
    border-radius: 50%;
    height: 25px;
    width: 25px;

    right: 100%;
    margin-right: -10px;
    &:before { 
      background-image: url("../images/arrow-right.png"); 
    // @include materialIcon('\e5cc', 18px);
    // color: $color-secondary-light;
    }
    &:hover:before { 
      background-image: url("../images/arrow-right1.png");
   // @include materialIcon('\e5cc', 18px);
  //  color: $color-white;
     }
  }

  &--collapsed &__handle-btn:before {
    transform: rotate(180deg);
  }

  &__inner {
    flex: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__body {
    flex: 1 1 0px; // IE 11 requires a unit to be added to the third argument
    display: flex;
    flex-direction: column;
  }

  &__header {

    position: relative;

    flex-shrink: 0;

    cursor: default;
    /* unselectable */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    color: $color-dark;
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 0.2px;
    padding: 1em 0 .75em 1.6em;
    box-shadow: none;
    border: none;

    &:before {
      border-top: 5px solid $color-dark;
      border-left: 3.5px solid transparent;
      border-right: 3.5px solid transparent;
      content: "";
      position: absolute;
      top: 50%;
      left: 9px;

      -webkit-transition: 0.1s;
      -moz-transition: 0.1s;
      transition: 0.1s;
    }
  }

  &--collapsed &__header:before {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  &--collapsed &__body {
    display: none;
  }
}
