/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 // style for src/main/resources/org/researchspace/apps/default/data/templates/http%3A%2F%2Fwww.researchspace.org%2Fresource%2FImageAnnotationWorkspace.html

 .Dashboard--viewContainer:has(.imageAnnotation__container) {
    container-name: image-annotation-container;
    container-type: inline-size;
  }

.imageAnnotation__container {

    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 4fr 370px;
    grid-template-rows: 100%;
    column-gap: 3px;
    grid-template-areas: 'viewer resourceAnnotation';

    overflow: hidden;

    .imageAnnotation__viewer {

        grid-area: viewer;
        height: 100%;
        position: relative;
    }

    .imageAnnotation__viewer-customActions {

        position: absolute;
        top: 12px;
        left: 17px;
        z-index: 100000;

        display: flex;
        align-items: center;
        gap: 10px;

        & div {
            
            cursor: pointer;
            color: $color-btn-default;
            display: flex;
            align-items: center;
            font-size: 12.5px;
            gap: 5px;
            line-height: 1.2;

            i {
                font-size: 19px;
            }
        }

        & button {
            padding: 0;
            border: none;
        }

        & .btn-default {
            &:active,
            &:active:hover,
            &.active,
            &:active:hover,
            &:active:focus,
            &:active.focus,
            &.active:hover,
            &.active:focus,
            &.active.focus {

                background-color: transparent;
            }
        }

        & .open > .btn-default.dropdown-toggle,
        & .open > .btn-default.dropdown-toggle:hover,
        & .open > .btn-default.dropdown-toggle:focus,
        & .open > .btn-default.dropdown-toggle.focus {

            background-color: transparent;
        }
        
    }

    .imageAnnotation__resourceAnnotation-container {

        height: 100%;
        grid-area: resourceAnnotation;
        padding: 3.5px;
        padding-left: 0;

        display: flex;
        flex-direction: column;
        row-gap: 3px;

        .imageAnnotation__resourceAnnotation-header {

            border: 1px solid $color-border;
            background-color: $color-miradorSlot-bg;
            border-radius: $border-radius;
            min-height: 34px;
            height: 34px;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0 10px;

            h6 {
                margin: 0;
                color: $color-secondary;
            }
        }

        .imageAnnotation__resourceAnnotation-result {

            flex: 1;
            border: 1px solid $color-border;
            background-color: $color-miradorSlot-bg;
            border-radius: $border-radius;

            height: 100%;
            overflow-y:hidden;

            .imageAnnotation__resourceAnnotation-result-tabs {

                display: flex;
                flex-direction: column;
                height: 100%;
                font-size: 13.5px;

                & .nav.nav-tabs {
                    margin: 0;
                    min-height: 43px;
                    display: flex;
                    align-items: center;
                    overflow-x: auto;
                    overflow-y: hidden;

                    & > li {
                        height: 42px;
                        padding: 10px;
                        
                    

                        &:last-of-type {
                            & > a span {
                                width: max-content;
                            }
                        }

                        & > a {

                            display: flex;
                            gap: 8px;
                            align-items: center;

                            .material-icons-round,
                            .material-symbols-rounded {

                                cursor: pointer;
                                font-size: 20px;
                                opacity: .8;
                            }

                            & span {
                                width: 120px;
                                max-width: fit-content;
                            }
                        }

                        &.active {

                            .material-icons-round,
                            .material-symbols-rounded {

                                color: $color-action;
                            }
                        }
                    }
                }

                & .tab-content {
                    flex: 1;
                    overflow: hidden;
                    height: 100%;

                    & > .tab-pane {

                        height: 100%;
                        padding: 0;
                    }
                }
            }

            .imageAnnotation__resourceAnnotation-result-items {

                height: 100%;
                overflow-y:auto;

                & .imageAnnotation__resourceAnnotation-result-items-header {

                    display: flex;
                    align-items: flex-start;
                    border-bottom: 1px solid $color-border;
                    padding: 5px 10px;
                    gap: 15px;

                    & .imageAnnotation__resourceAnnotation-result-items-header-title {
                        color: $color-secondary;
                        font-weight: 600;
                        font-size: 14.5px;
                        display: block;
                        flex: 1;
                        align-self: center;
                    }

                    & .imageAnnotation__resourceAnnotation-result-items-header-actions {
                        display: flex; 
                        align-items: flex-start; 
                        gap: 5px;
                        align-self: center;
                        
                        .btn-default {
                            align-items: center;
                            background: transparent;
                            display: flex;
                            padding: 8px 5px;
                        }
                    }
                }
    
                & > .imageAnnotation__resourceAnnotation-result-item:last-child {
                    border-bottom: none;
                }
    
                .imageAnnotation__resourceAnnotation-result-item {
    
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 10px;
                    border-bottom: 1px solid $color-light;
    
                    &:hover {
                        background-color: $color-lighter;
                    }
    
                    .imageAnnotation__resourceAnnotation-result-item-actions {

                        display: flex;
                        align-items: stretch;
                        gap: 1px;

                        .btn-default {
                            display: flex;
                            align-items: center;
                            background: transparent;
                            padding: 8px 5px;
                        }

                        .btn-default:focus, 
                        .btn-default:active, 
                        .btn-default:focus-within, 
                        .btn-default:hover {

                            background-color: transparent;
                        }
                    }
                }

                
            }
        }
   

           
    }
}

@container image-annotation-container (width <= 1000px) {

    .Dashboard--template > div { 
        padding-right: 5px;
    }

    .imageAnnotation__container {

        grid-template-columns: 100%;
        grid-template-rows: 70vh auto;
        column-gap: 0;
        grid-template-areas: 'viewer' 
                             'resourceAnnotation';
    
        overflow: auto;
        padding-right: 5px;
    }

    .imageAnnotation__container .imageAnnotation__resourceAnnotation-container {
        padding: 0 0 0 3.5px;
    }
  }


