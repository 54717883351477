/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

// Mixins
/////////

@mixin icon($icon) {
  @include fa-icon;
  &:before {
    content: map-get($fa-icons, $icon);
  }
}

@mixin materialIcon($icon, $size) {
  content: $icon;
  font-size: $size;
  font-family: 'Material Icons';
}

@mixin materialIconSymbol($icon, $size) {
  content: $icon;
  font-size: $size;
  font-family: 'Material Symbols Rounded';
}

@mixin spin-icon() {
  animation: fa-spin 1.15s infinite linear;
  @keyframes fa-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
}

@mixin input-loading-state() {
  display: flex;
  position: relative;
  &:after {
    @include icon(cog);
    content: $fa-var-cog;
    position: absolute;
    right: 10px;
    top: calc($input-height-base / 2) - calc($fa-font-size-base / 2);
  }
}

@mixin fixed-width-col($width) {
  width: $width;
}

@mixin col-offset($width) {
  margin-left: $width;
}