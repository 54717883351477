.ontodia-halo {
  position: absolute;
  pointer-events: none;

  border: 1.5px solid $color-action;
  border-radius: $border-radius;
  box-shadow: 0 0 5px 0  $color-box-shadow inset;
  z-index: 1;

  $buttonWidth: 20px;
  $buttonHeight: 20px;
  $buttonMargin: 2px;

  @mixin halo-button {
    position: absolute;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 0 none;
    cursor: pointer;
    outline: none;
    padding: 0;
    pointer-events: auto;
    width: $buttonWidth;
    height: $buttonHeight;

    opacity: 0.5;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.2;
    }
  }

  @mixin spinner {
    position: absolute;
    width: $buttonWidth;
    height: $buttonHeight;
  }

  @mixin sw-docked {
    bottom: -($buttonHeight + $buttonMargin);
    right: -($buttonWidth + $buttonMargin);
  }

  &__navigate {
    @include halo-button;

    top: 35%;
    right: -25px;

    &--open::before {
    //  background-image: url("../images/connections.svg");
    @include materialIcon ('\e4fd', 24px);
    }

    &--closed::before {
      @include materialIcon ('\e4fd', 24px);
      //background-image: url("../images/close-connections.svg");
    }
  }

  &__folow {
    @include halo-button;
    top: 50%;
    left: -($buttonWidth + $buttonMargin);
   // background-image: url("../images/link.svg");
    &::before {
      @include materialIcon ('\e157', 22px);
    }
  }

  &__remove {
    @include halo-button;

    top: -($buttonHeight + $buttonMargin);
    right: -($buttonWidth + $buttonMargin);

  //  background-image: url("../images/delete.svg");
    &::before {
      @include materialIcon ('\e5cd', 22px);
    }
  }

  &__expand {
    @include halo-button;
    bottom: -($buttonHeight + $buttonMargin);
    left: 47%;

    &--open {
   //   background-image: url("../images/expand-properties.png");
   &::before {
    @include materialIcon ('\e5ce', 22px);
  }
    }

    &--closed {
    //  background-image: url("../images/collapse-properties.png");
    &::before {
      @include materialIcon ('\e5cf', 22px);
    }
    }
  }

  &__add-to-filter {
    @include halo-button;

    top: 6;
    right: -($buttonWidth + $buttonMargin);
  //  background-image: url("../images/add-to-filter.png");
    &::before {
      @include materialIcon ('\f114', 22px);
    }
  }

  &__revert {
    @include halo-button;
    background-image: url("../images/font-awesome/undo-solid.svg");

    top: -($buttonHeight + $buttonMargin);
    left: 50%;
    margin-left: calc($buttonWidth / 2);
  }

  &__establish-connection {
    @include halo-button;
    @include sw-docked;
   // background-image: url("../images/font-awesome/plug.svg");
   &::before {
    @include materialIcon ('\e5da', 22px);
   }
  }

  &__establish-connection-spinner {
    @include spinner;
    @include sw-docked;
  }
}
