/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.component {
  /**/
}

.headers {
  /**/
}

.sectorHeader,
.subsectorHeader {
  path {
    stroke: black;
    stroke-width: 1;
  }
}
.sectorPopoverTarget {
  fill: none;
  stroke: none;
}

.background {
  /**/
}
.ringArea {
  fill: #f9fdff;
}

.grid {
  /**/
}
.sectorLine {
  stroke: black;
  stroke-width: 1;
}
.subsectorLine {
  stroke: black;
  stroke-width: 1;
  stroke-dasharray: 5, 5;
}
.ringLine {
  fill: none;
  stroke: gray;
  stroke-width: 1;
}
.ringLabel {
  /**/
}

.clusters {
  /**/
}
.cluster {
  fill-opacity: 0.3;
  stroke-width: 1;
  stroke-dasharray: 5, 5;
}

.items {
  /**/
}
.item {
  /**/
}
