.ontodia {
  display: flex;
  flex-direction: column;
  height: 100%;
  // defaults for inheritable properties
  box-sizing: border-box;
  white-space: initial;
  color: $color-dark;
  font-size: 14px;
  line-height: 1.2;
  padding-top: 1px;

  background-color: $color-km-bg;

  *, *:before, *:after {
    box-sizing: inherit;
  }

  &__toolbar-widget {
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__workspace {
    display: flex;
    flex: auto;
    overflow: hidden;
    flex-direction: column;

    .ontodia-accordion-item--horizontal.ontodia-accordion-item--collapsed {

      background-color: $color-km-bg;
    }
  }

  &__main-panel {
    display: flex;
    flex: auto;
    overflow: hidden;
    width: 0;
  }

  &--unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &--horizontal-resizing * { cursor: ew-resize !important; }
  &--vertical-resizing * { cursor: ns-resize !important; }

  h6 {
    font-weight: 500;
    line-height: 1.1;
  }

  select {
    color: inherit;
    margin: 0;
    font: inherit;
    line-height: inherit;
    text-transform: none;
  }

  hr {
    border: 0;
    border-top: 1px solid $color-border;
    height: 0;
  }
}

.ontodia-scrollable {
  flex: 1 1 0px; // IE 11 requires a unit to be added to the third argument
  overflow-y: scroll;
}

// fixes the bug in Chrome with redrawing children in scrollable elements
.ontodia-scrollable > * {
  position: relative;
}
