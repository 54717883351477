/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.realm-manager-component {
  & > div {
    & > .tab-content {
      padding-top: $padding-base-vertical;
    }
  }
}

#security-manager-tabs-pane-ini-realm {

  padding-top: 25px;

  .researchspace-table-widget-holder .griddle-filter .filter-container {

    margin-bottom: 10px;
  }
}

.account-manager-component {
  &__create-account-panel {
    padding-top: 20px;
    width: 40%;
  }

  &__create-account-btn {
    display: flex;
    justify-content: flex-end;
  }

  &__role-multi-selector {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &__account-permissions-ul {
    border: 1px solid #e6e6e6;
    max-height: 100px;
    overflow: auto;
    padding: 0;
    text-indent: 10px;
  }

  &__account-permissions-li {
    line-height: 25px;
    padding-right: 15px;
   padding-left: 15px;
  }
}
