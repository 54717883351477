.ontodia-authoring-tools {
  margin: 10px;

  &__create-entity {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    white-space: normal;
  }

  &__type-label {
    color: black;
    border: black 1px dashed;
    background: rgb(255, 210, 33);
    padding: 0 .5em 0 .5em;
    word-wrap: break-word;
  }
}
