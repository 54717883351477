.ontodia-edit-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__body {
    overflow: auto;
    padding: 15px;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__controls {
  //  border-top: 1px solid $color-border;
  //  padding: 10px;
    text-align: right;
    flex: 0 0 auto;

    display: flex;
    justify-content: end;
    gap: 5px;
  }

  &__apply-button {
  //  margin-right: 5px;
  }

  &__form-row {
    display: block;
    margin-bottom: 10px;
  }

  &__form-row:last-of-type {
    margin-bottom: 0;
  } 

  &__element-selector {
  //  flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__search {
    flex-shrink: 0;
    position: relative;
  }

  &__search-icon {
    opacity: 0.6;
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -7px;
  }

  &__search-input {
    padding-left: 33px;
  }

  &__existing-elements-list {
    flex: 1 1 0;
    margin-top: 7px;
    padding-right: 10px;
    overflow-y: scroll;

    height: 100%;
  }

  &__separator {
    margin: 12px 0;
    overflow: hidden;
    text-align: center;
  }

  &__separator-text {
    color: #555;
    display: inline-block;
    font-size: 13px;
    position: relative;

    &:before, &:after {
      content: "";
      border-top: 1px solid lightgray;
      position: absolute;
      top: 50%;
      margin: 0 10px;
      width: 500px;
    }

    &:before {
      left: 100%;
    }

    &:after {
      right: 100%;
    }
  }

  &__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__control-row {
    position: relative;
    padding-bottom: 18px;
  }

  &__control-error {
    color: $color-danger-text;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
