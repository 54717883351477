.ontodia-standard-template {
  min-width: 180px;
  max-width: 400px;
  float: left;

  display: flex;
  flex-direction: column;

  &__main {
    border-radius: 2px;
    border: 1px solid;
  }

  &__body {
    margin-left: 8px;
    border-radius: 0 2px 2px 0;
    border-left: 1px solid;
    padding: 3px 0;
    background: #fafaf9;
  }

  &__body-horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  &__body-content {
    flex-grow: 1;
    min-width: 0;
    margin-right: 12px;
  }

  &__label {
    font-size: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__thumbnail {
    font-size: 26px;
    margin: 5px 10px;
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__thumbnail-image {
    max-height: 100%;
    max-width: 100%;
  }

  &__thumbnail-icon {
    max-height: 26px;
    max-width: 26px;
  }

  &__photo {
    border-bottom: 1px solid;
  }

  &__photo-image {
    width: 100%;
    border-radius: 2px 2px 0 0;
  }

  &__type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
    font-style: italic;
    color: #999;
    display: flex;
  }

  &__type-value {
    width: 0;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__iri {
    width: 100%;
    overflow: hidden;
    display: flex;
  }

  &__iri-key {
    font-size: 12px;
    margin-right: 4px;
    color: #b6b6b6;
  }

  &__iri-value {
    font-size: 12px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__iri-value a {
    color: #b6b6b6;
  }

  &__hr {
    margin: 5px 0;
  }

  &__properties {
    font-size: 15px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &__propertites-row {
    white-space: nowrap;
  }

  &__properties-key {
    display: inline-block;
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
  }

  &__properties-values {
    display: inline-block;
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__properties-value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    padding-right: 10px;
  }

  &__pinned-props {
    border-top: 1px solid;
    margin: 0 5px;
  }

  &__dropdown {
    border-radius: 2px;
    background-color: white;
    margin-top: 5px;
    border: 1px solid;
  }

  &__dropdown-content {
    width: 100%;
    padding: 9px;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    button {
      padding: 5px;
      min-width: 60px;
    }
  }
}
