/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @author Artem Kozlov <ak@metaphacts.com>
 * @author Mike Kelly <mkelly@britishmuseum.org>
 */

@import '~basic-styles.scss';
@import '../commons/variables.scss';

$year-input-width: inherit;
$epoch-input-width: 4em;

.holder {
  display: flex;
  align-items: center;
  flex: 1;

  & > div {
    margin-bottom: 0;
  }

  :global(.form-group) {
    margin: 0;
    flex: 0;
  }

  & > :global(.form-group:first-of-type) {
    flex: 1;
  }
}

.year {
 // margin-left: $padding-base-horizontal;
  width: $year-input-width;
}

.epoch {
  width: $epoch-input-width;
 // margin-right: $padding-base-horizontal;
  margin-left: -5px;
}

:global(select).epoch:global(.form-control) {
  padding: 0px 10px;
}
