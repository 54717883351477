/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.SplitPane {
  overflow: initial !important;
  position: relative !important;
  z-index: $zindex-clipboard-sidebar;

  .split-pane {

    &__sidebar {

      background-color: $color-sidebar-bg;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      &.split-pane__rightsidebar {
        border-left: 1px solid $color-border;
      }

      &.split-pane__leftsidebar {
        border-right: 1px solid $color-border;    
      }

      .btn-toggle-on {

        display: flex;
        align-items: flex-start;
        border: none;
        height: 100%;
        padding: 30px 20px;
        font-size: 18px;
        background: transparent;
    
      }
    
      .btn-toggle-on-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 7px;
        width: 100%;

        &.content-center {
          justify-content: center;

        }

        h4 {
          margin: 0;
        }
      }

      .split-pane__sidebar-header {

        background-color: $color-sidebar-bg;
        border: none;
        font-size: 18px;

        .btn-toggle-off {

          width: 100%;
          border-radius: 0;
          border: none; 
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 30px 20px 25px;

          h4 {
            margin: 0;
          }
        }
      }

      .split-pane__sidebar-open {

        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0px 20px 20px 20px;
      }

    }

    &__leftsidebar-caption {
      font-size: 20px;
      margin: 20px 0 0;
      position: absolute;
      top: 45px;
      left: 100%;
      transform: rotate(90deg);
      transform-origin: 0 0;
      white-space: nowrap;
    }
  
    &__leftsidebar-footer {
  
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  
    &__leftsidebar-toggle {
      font-size: 30px;
      float: right;
      height: 42px;
      text-align: center;
      width: 30px;
      transition: 0.3s;
  
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.Pane1 {
  display: flex;
  align-items: stretch;
}

.Resizer.vertical {
  width: 6px;
  margin: 0 -1px;
  border-right: 4px solid hsla(0, 0%, 100%, 0);
  cursor: col-resize;
  z-index: $zindex-clipboard-sidebar !important;
}

.Resizer.horizontal {
  height: 6px;
  margin: -5px 0;
  border-bottom: 4px solid hsla(0, 0%, 100%, 0);
  cursor: row-resize;
  z-index: $zindex-clipboard-sidebar !important;
}

.Resizer:hover {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-right: 4px solid rgba(0, 0, 0, 0.1);
}

.Resizer.vertical:hover {
  border-right: 5px solid rgba(0, 0, 0, 0.1);
}

.Resizer.horizontal:hover {
  border-bottom: 5px solid rgba(0, 0, 0, 0.1);
}