/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.formContent {
  min-height: 300px;
}

.selectedFragment {
  margin-bottom: 10px;
}

.selectedFragmentText {
  border: 1px dashed lightgray;
  background: #f7f7f7;
  padding: 5px;
}

.bodyInput {
  margin: 10px 0;
}

.bodyTypeIcon {
  height: 24px;
  margin-right: 5px;
}

.cancelButton {
  margin-left: 5px;
}
