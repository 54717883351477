/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 .list-group-item {
  
  padding: 10px 25px;
  margin-bottom: 1px;
  background-color: transparent;
  border: none;
}

 a.list-group-item {

  color: $color-dark;
}

a.list-group-item:hover, 
a.list-group-item:focus, 
button.list-group-item:hover, 
button.list-group-item:focus {

  color: inherit;
  background: $color-light;
}

.list-group-item-label {

  background-color: $color-dark;
  color: $color-white;
  display: inline-block;
  font-size: .8em;
  padding: 3px 5px;
  margin-bottom: 5px;
}