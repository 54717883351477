/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.mp-print {
  display: flex;
  height: 100%;

  &__body {
    flex: 1 1 100%;
    display: flex;
  }

  &__body-inner {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
  }

  &__body-content {
    display: flex;
    flex: 1 1 100%;
  }

  &__body-footer {
    flex: 0 0 auto;
  }

  &__preview {
    display: flex;
    flex: 1 1 100%;
    margin: 0;
  }

  &__iframe {
    border: 0 none;
    height: 100%;
    width: 100%;
  }

  &__select {
    display: flex;
    flex-direction: column;
    flex: 0 0 325px;
    margin: 0 0 0 20px;
  }

  &__select-header {
    flex: 0 0 auto;
  }

  &__select-body {
    flex: 1 1 100%;
    overflow: auto;
  }

  &__select-footer {
    flex: 0 0 auto;
  }
}

@media print {
  a[href]:after {
    content: none !important;
  }
}
