/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.RdfUpload {
  &__tab {
    padding: 20px 0;
  }

  &__tabContainerClass {

    display: flex;
    align-items: flex-start;
    gap: 30px;
    flex-wrap: wrap;

    .dropzone-area  {
      min-width: auto;
      width: auto;
      max-width:270px;
    }
  }

  &__advance {
    margin-bottom: 10px;
    display: block;
  }
  
  &__rdf-dropzone {
    margin-top: 10px;
  }
  &__rdf-dropzone-content {

    line-height: 1.5;
    padding: 10px 25px;
    cursor: pointer;
    text-align: center;

    .upload_icon.material-icons-round,
    .upload_icon.material-symbols-rounded {
      font-size: 25px;
      padding-bottom: 10px;
      color: inherit;
    }
  }

  &__advanced_options {
    flex: 1;
    padding-bottom: 10px;
  }

}
