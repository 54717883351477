/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

//rs-card-default is the default card for Clipboard, Search and Knowledge Map */

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    word-wrap: break-word;

    background-color: transparent;
    border: 1px solid $color-border;

    &:hover {
    //    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    }
}

.resource-card {

    border-radius: $border-radius;
    border: 1.5px solid $color-border;
    border-radius: $border-radius;
    background-color: $color-white;

    position: relative;

    height: 100%;
    width: 100%;

    &:hover,
    &:focus,
    &:focus-within {

        border: 1.5px solid $color-action;
        cursor: grab;

        .resource-card__button {
            opacity: 1;
        }

    }

    &.resource-card-small {

        .resource-card__media-container {
            height: 100px;
            min-height: 100px;
            max-height: 100px;

            & .image-thumbnail {

                min-height: 100px;
            }

            .resource-card__thumbnail {
                min-height: 100px;
            }
        }

        .resource-card__footer {

            height: 47px;
            gap: 2px;

            .resource-card__footer-type {
                font-size: 12px;
            }

            .resource-card__footer-title {
                font-size: 12.5px;
            }
        }

        .resource-actions__dropdown-menu.dropdown-menu {

            left: -54px;
            right: -39px;
            min-width: unset;
            max-height: 130px;

            hr {
                margin: 5px 0;
            }

            & > li >a {
                padding: 3px 8px;
                font-size: 12.5px;

                i {
                    font-size: 17px;
                }
            }
        }
    }

    &.resource-card__no-hover {

        &:hover,
        &:focus,
        &:focus-within {
    
            border: 1.5px solid $color-border;
            cursor: pointer;
        }
    }

    .resource-card__header-actions {

        position: absolute;
        top: 0;
        padding: 5px;
        background-color: transparent;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 5px;
        width: 100%;
    }

    .resource-card__buttons-container {

        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: end;
        gap: 2px;
        width: 100%;
    }

    .resource-card__button {
        display: flex;
        padding: 5px;
        border: 1px solid $color-medium;
        opacity: 0;
    }

    .resource-actions__dropdown-menu.dropdown-menu {

        left: -118px;
        right: -3px;
        min-width: 155px;

        &.resource-card__dropdown-km {
            left: 74px;
            top: -58px;
            box-shadow: none;
        }

        &.resource-card__dropdown-multitle-actions {

            left: -81px;
            right: -39px;
            min-width: unset;
        }

        &.resource-card__dropdown-dragAndDropInputCard {

            left: -85px;
            right: -38px;
            max-height: 155px;
        }
    }

    .resource-card__media-container {

        height: 135px;
        min-height: 135px;
        width: 100%;
        border-bottom: 1px solid $color-border;
        background-color: $color-light;

        display: flex;
        align-items: center;
    }
    
    .image-thumbnail,
    .resource-card__thumbnail {

        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 0%;
        min-height: 135px;
    }

    .resource-card__icon-container {

        min-height: 135px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
        justify-content: center;
        color: $color-base;
        font-weight: 600;
        font-size: 14px;
        padding: 0 15px;
        text-align: center;
        overflow-wrap: anywhere;

        i {
            color: $color-base;
            font-size: 45px;
        }

        span {
            max-height: 42px;
            overflow-y: auto;
        }
    }

    .resource-card__footer {

        height: 50px;
        width: 100%;
        padding: 0 10px;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        gap: 3px;
        justify-content: center;
        line-height: 1.2;

        position: relative;

        .resource-card__footer-type {
            letter-spacing: .2px;
        }

        .resource-card__footer-class,
        .resource-card__footer-title {

            a:hover {
                background-color: transparent;
            }
        }

        .resource-card__footer-title {
            font-size: $font-size-small;
        }

    }
}

.resource-card__kmTopButtons {

    z-index: 1;
    position: absolute;
    right: 6px;
    top: 37px;

    display: flex;
    align-items: center;
    gap: 2px;
    
    .resource-card__button {
        display: flex;
        padding: 5px;
        border: 1px solid $color-medium;
        opacity: 0;
    }
}

.ontodia-exported-element {

    .resource-card__header-actions,
    .resource-card__kmTopButtons {
        display: none;
    }    
}

/*  Card deck */
// Bootstrap 4 Cards
$card-group-margin: calc(30px / 2) !default;
$card-deck-margin: $card-group-margin !default;

.card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    .card {
        margin-bottom: $card-deck-margin;
    }
}

@media (min-width: 576px) {
    .card-deck {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -$card-deck-margin;
        margin-left: -$card-deck-margin;
    }

    .card-deck .card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: $card-deck-margin;
        margin-left: $card-deck-margin;
    }

    .card-deck .card {
        max-width: calc(25% - 30px);
        min-width: calc(25% - 30px);
    }

    .card-deck.cards-latest-section .card {
        max-width: calc(50% - 30px);
        min-width: calc(50% - 30px);
    }
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-height: 195px;
}

/* .card-header {
    padding: 0 8px;
    height: 27px;
    margin-bottom: 0;
} 

.card-img {
    display: block;
    height: 126px;
    width: 100%;
    padding: 0;
}

.card-icon {
    height: 126px;
    width: 100%;
    padding: 0;
}

.card-footer {
    min-height: 63px;
}

.rs-default-card__img-container {
    position: relative;
    flex-grow: 1;
    min-height: 100px;

    a {
        width: 100%;
    }

    .rs-icon {

        display: flex;
        height: 100%;
        font-size: 5.3em;
        justify-content: center;
        align-items: center;
    }
}  
    .card.rs-object-card { */

/*     Default card is used Clipboard, Search and KM */
/*     &.rs-default-card {

       .rs-default-card__hover {
            display: none;
            position: absolute;
            top: 0;
            width: 100%;
            height: 72%;
            background: #f5f5f5c2;
            padding: 0px 10px;
            align-items: center;
            z-index: 1;

            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            &.rs-default-card__hover-additional {
                bottom: 0;
                top: initial;
                height: 28%;
                justify-content: center;

                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
    
                .rs-default-card__hover-options {
                    -webkit-flex-flow: unset;
                    border-top: 1px solid #8080803b;
                    padding-top: 5px;

                    &:empty {

                        border-top: none;
                        padding-top: 0;
                    }
                }
            }

            .rs-default-card__hover-institute {
                text-shadow: -4px -4px 7px $color-white;
                font-family: $font-family-base;
                color: $color-dark;
                font-size: 11px;
                line-height: 1.3;
                width: 100%;
                text-align: center;
            }

            .rs-default-card__hover-DigImg {
                padding: 10px 0;
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                justify-content: space-around;
            }

             & .rs-default-card__hover-options {
                width: 100%;
                display: flex;
                -webkit-flex-flow: row wrap;
                justify-content: center;
                align-content: center;

                .rs-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    background-color: transparent;
                    border: none;
                    height: 24px;
                    width: 100%;
                    padding: 0px;
                }

                .rs-default-card__hover-icon {
                    width: 33%;
                    height: fit-content;
                    text-align: center;
                    padding: 10px 0px;
                    border-radius: 3px;

                    &:hover {
                        background: $color-card-icon-hover;
                    }

                    .fa {
                        font-size: 1.8em;
                    }

                    img {
                        height: 97%;
                        width: auto;
                    }

                    .iconmoon {

                        font-size: 1.75em;
                    }
                    
                    .rs-icon {

                        font-size: 1.75em;

                        &.rs-icon-media {

                            font-size: 1.9em;
                        }

                        &.rs-icon-image {

                            font-size: 1.5em;
                        }

                    }
                }
            } 
        } 

         .rs-default-card__front {
            height: 100%;
            display: flex;
            flex-direction: column;
        } 

        &:hover {
            box-shadow: 1px 2px 8px 0px #8b849e, 1px 3px 12px 2px rgba(0, 0, 0, 0.07);

            .rs-default-card__hover {
                display: flex;
                flex-direction: column;
                justify-content: center;

            }
        }

        & .card-header {
            width: 100%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            height: 30px;
            align-items: center;
            font-family: $font-family-base;
            font-weight: 600;
            color: $color-secondary;
            line-height: 1;
            text-transform: uppercase;
            letter-spacing: 0.6px;
            font-size: 12px;

            .rs-default-card__header-left {
                height: inherit;
                display: flex;
                align-items: center;
                width: 100%;

                .rs-icon {

                    font-size: 10px;
                    font-weight: 600;
                    color: $color-secondary;
                    padding-right: 2px;
                }
            }

            .rs-default-card__header-right {
                width: 42%;
                text-align: end;
                align-self: center;
            }
        } 

        & .rs-default-card__img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-position: 50% 50%;
        }

         & .rs-default-card__footer {

            background-color: #fbfbfb;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            position: relative;

            font-weight: 600;

            .rs-default-card__footer-titles {

                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

                  & > p:last-child, 
                  & > div:last-child,
                  & > span:last-child {

                    margin-bottom: 0 !important;
                  }

            }

            .text-s {
                font-size: 11px;
            }

            & .btn-group {
                position: absolute;
                top: -14px;
                right: 10px;
            }
        } 
    }

    & .card-header {
        background-color: $color-white;
    }

    & .card-img, 
    & .card-icon {
        background-color: $color-light;
    }

    & .card-body .rs-card-top {
        background-color: $color-light;
        padding: 8px;
    }

    & .card-body .rs-card-top p,
    & .card-footer p {
        margin-bottom: 3px;
    }

    & .card-body .rs-card-top p:last-child,
    & .card-footer p:last-child {
        margin-bottom: 0px;
    }

    & .card-footer {
        background-color: $color-lighter;
        padding: 8px;
        width: 100%;
    }
}

// card in clipboard and DradAndDropInput
.set-management--grid-view,
.DragAndDropInput--holder {

    .card.rs-object-card.rs-default-card {
        border: 0.5px solid $color-card-border;
        cursor: grab;
  
        &:hover,
        &:focus {
          border: 0.5px solid $color-card-border;
          cursor: grab;
        }
  
        &:hover {
          box-shadow: none;
        }
  
         .card-header {
          font-size: 8px;
          height: 20px;
  
          .rs-default-card__header-left {
            width: 100%;
          }
  
          .rs-default-card__header-right {
            width: 42%;
          }
        } 
  
         .rs-default-card__hover .rs-default-card__hover-options {
  
          .rs-default-card__hover-icon {
            padding: 9px 0px;
          }
        } 
  
         .rs-default-card__img-container  
        .rs-default-card__hover {
          padding: 0px 8px;
  
          .rs-default-card__hover-institute {
            font-size: 10px !important;
            line-height: 1.2;
            max-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 7px 0px 5px 0px;
          }
        } 

    }
}*/

// common Css for cards in the system (Clipboard card , search results, panel system)

/* .rs-card-img {
    height: 115px;
    width: 99%;
    min-width: 50px;
    max-width: 100%;
    object-fit: contain;
} */

// Card Actions: show or hide depending on the current page 
// If we are in thinking frames page we show action that opens KM in the new frame,
// but when we are somewhere else we show link to thinking frames page.
// we apply the same logic to other KM related actions

/* .thinking-frames-page {
    .set-action {
        &__km-navigate {
            display: none;
        }
    }

    .rs-default-card-action {

        &__km-navigate, 
        &__image-navigate {
            display: none;
        }
    }
} 

.page:not(.thinking-frames-page) {

    .set-action__km-add-frame {
        display: none;
    }

    .rs-default-card-action {
        &__km-add-frame, 
        &__image-add-frame {
            display: none;
        }
    }
}

 .rs-card-container {
    margin-top: 8px;
    border: 1px solid transparent;
  
    &:hover {
      border: 1px dashed $color-secondary;
      border-radius: 5px;
      cursor: grab;
    }
} 

 // Card in Frames sidebar area 
.thinking-frames__frames-sidebar {

     .card.rs-object-card.rs-default-card {
      &:hover .rs-default-card__hover {
        padding: 0px 6px;
        height: 100%;
  
        &.rs-default-card__hover-additional {
          height: 0;
        }
      } 
  
      & .rs-default-card__hover .rs-default-card__hover-options .rs-default-card__hover-icon {
  
        .rs-default-card__hover-icon {
          padding: 8px 0px;
        }
      }
  
      .card-header {
        font-size: 8px;
        height: 20px;
  
        .icon-template {
          height: 8px;
          width: 8px;
          margin-bottom: 0px;
        }
      } 
  
       .rs-default-card__hover {
        padding: 0px 8px;
  
        .rs-default-card__hover-institute {
          font-size: 10px;
          line-height: 1.2;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-bottom: 5px;
        }
  
        .rs-default-card__hover-DigImg {
  
          justify-content: center;
        }
      } 
}*/


/*  Knowledge Map card */
.ontodia .ontodia-paper-area {

    /*  Knowledge Map card */
    .rs-KM-card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
    }
  
    .rs-card-note-container {
      width: 100%;
      background-color: $color-light;
      border-radius: 5px;
      border: 0.5px solid $color-lighter;
      box-shadow: 0px 0px 3px 0px $color-card-boxShadow;
      padding: 10px;
      margin: 5px;
  
      font-size: 14px;
      line-height: 1.3;
      text-align: justify;
  
      span:first-child {
        font-weight: 600;
      }
    }
  
/*    .card.rs-object-card.rs-default-card {
       .rs-default-card__hover .rs-default-card__hover-options {
        .rs-default-card__hover-icon {
          width: 33%;
        }
  
        .rs-button {
          height: 26px;
        }
      } 
    }*/
}
