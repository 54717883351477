/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.component {
  &.drag-whole .item {
    cursor: move;
    &[data-dragged] {
      opacity: 0;
    }
  }
  &.drag-whole .item-body {
    pointer-events: none;
  }
  &.drag-by-handle .item {
    &[data-dragged] {
      // show transparent item when dragging only handle because browser
      // will only render dragging tooltip for the handle itself
      opacity: 0.3;
    }
  }
  &.drag-by-handle .item-handle {
    cursor: move;
  }

  &:not(.dragging) .item:hover .item-handle {
    filter: brightness(0.75);
  }
  &:not(.dragging) .item:hover {
    background: transparent;
  }
}

.item {
  position: relative;
  &[data-dragged] {
    pointer-events: none;
  }
}
 .item-handle {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 7px;
  width: 5px;
  background: url('./handle.svg');
  background-repeat: repeat-y;
}

.item-body {
  margin-left: 15px;
}