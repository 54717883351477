/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
/* Mirador */

.mirador .researchspace-mirador.mirador-container {

    background-color: $mirador-main-color-bg !important;
    background-image: none !important;

    .menu-divider {
        color: #e5e5e5;
    }

    .modal-backdrop {
    z-index: 1040 !important;
    }

    .modal {
    z-index: 1050 !important;
    }

    .modal-dialog {
        margin-top: 100px !important;
    }

    .window {
        z-index: 0 !important;
        background-color: $mirador-main-color-bg;
    }

    .mirador-viewer,
    .mirador-main-menu-bar, 
    .mirador-status-bar,
    .ui-widget {

        font-family: $font-family-base;
    }

    .sidePanel {
        background-color: $color-lighter;
        border-top: 1px solid $color-border !important;
        color: $mirador-main-color;

        .tabContentArea {

            background-color: $color-lighter;
            border-color: $color-border;

            .h2 {
                color: $mirador-main-color;
            }
        }
    }

    .mirador-main-menu-bar {

        background-color: $color-frames-tabset-bg;
        border-bottom: none;
        height: 42px;

        display: flex;
        align-items: center;

        ul.mirador-main-menu {

            display: flex;
            justify-content: flex-end;
            gap: 10px;
            width: 100%;
            margin-right: 0;
            margin-left: 3px;
            padding: 9px 15px;;
            background-color: $color-miradorSlot-bg;
            border-radius: $border-radius;
            border: 1px solid $color-border;

            & > li {

                padding: 0;

                a {

                    display: flex;
                    align-items: center;
                    gap: 5px;

                    font-size: 12.5px;
                 //   border-radius: $border-radius;
                    line-height: 1.2;
                    padding: 0;

                    color: $color-btn-default;
                 //   background-color: $color-btn-default-bg;
                 //   border: 1px solid $color-btn-default-border;
                    opacity: 1;

                    .fa {
                        color: $color-btn-default;
                        font-size: 16px;
                    }

                    &:hover,
                    &:focus,
                    &:focus-within {
                        color: $color-btn-default-hover;
                        background-color: $color-btn-default-bg-hover;
                        border: none;
                    }

                    .open > &.dropdown-toggle,
                    .open > &.dropdown-toggle:hover,
                    .open > &.dropdown-toggle:focus,
                    .open > &.dropdown-toggle.focus {
                        color: $color-btn-default;
                        background-color: $color-btn-default-bg-active;
                        border: none;
                    }
                    
                }
            }
    

        }
    }

    .manifest-info {

        background: $mirador-topBar-color-bg;

        .mirador-btn {
            font-size: .85em;
        }
    }

    #manifest-search-form {
        display: flex;
        align-items: baseline;
        width: 100%;

        label {

            font-weight: normal;
        }

        input {

            flex: 1;
            height: $input-height;
            border: 1px solid $input-border-color;
            border-radius: $input-border-radius;
            color: $input-color;
            font-size: $input-font-size;
            padding: 0 12px;
        }
    }

/*     Add Item window */
    .layout-slot {

        background-color: $color-miradorSlot-bg;
    }

    .hud-control {
        opacity: .8;
        text-shadow: 0px 0px 2px #ffffffd9;
    }

    .mirador-osd,
    .book-view,
    .image-view,
    .scroll-view,
    .thumbnail-view {

        background-color: $mirador-window-color-bg;
        box-shadow: none;

        .material-icons,
        .fa {
            color: $color-imageIcons;
        }
    }

    .book-view,
    .image-view,
    .scroll-view,
    .thumbnail-view {
        border-top: 1px solid $color-border;
    }

    #workspace-select-menu {
        background-color: $color-tf-dashboard-bg;
        background-image: none !important;

        h1, h3 {

            color: $mirador-main-color;
        }

        h1 {
            margin-top: 5%;
        }

        h3 {
            margin-bottom: 35px;
        }

        #load-controls {

            letter-spacing: 0.1px;
        }
    }

    .mirador-viewer {

        background-color: $color-frames-tabset-bg;
        top: 38px;

        .workspace-container {
            margin: 0;
        }

        .window-manifest-title {

            margin: 10px 0 0;
            font-size: 15px;
            color: $mirador-main-color;
            letter-spacing: .3px;
            font-weight: 600;
        }
    
        & .dropdown {
            background-color: $color-white;
            border: 1px solid $color-border;
            border-radius: $border-radius;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            color: $dropdown-link-color;
            margin: 2px 0 0;
            padding: 8px 0;

            & > li {

                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: start;
                color: $dropdown-link-color;
                font-size: $dropdown-font-size;
                font-weight: normal;
                padding: 9px 25px 9px 20px;
                clear: both;
                line-height: 1.42857;
                white-space: nowrap;
                text-decoration: none;

                background: $color-white;
                border-left: none;

                .fa {
                    font-size: 17px;
                    color: $color-base;
                }

                &:hover {

                    border-left: none;
                    color: $dropdown-link-color;
                    font-size: $dropdown-font-size;
                    background-color: $color-lighter;
                    font-weight: normal;
                    padding: 9px 25px 9px 20px;
                }

            }

        }

        a.mirador-icon-view-type, 
        a.mirador-icon-window-menu {
            width: 40px;
        }

        a.mirador-close-window,
        a.mirador-icon-toc,
        a.mirador-icon-window-menu {
    
            color: $mirador-main-color;
        }

        a.mirador-close-window {

            margin-left: 10px;
            margin-top: 12px;
        }

        .mirador-btn {

            margin-top: 13px;
        }
    
        a.mirador-icon-window-menu {
    
            margin-left: 12px;
        }

        a.mirador-icon-toc {

            margin-top: 12px;
            margin-left: 0;
            margin-right: 5px;
        }
    
        a.mirador-icon-empty-slot:hover, 
        a.mirador-icon-metadata-view.selected, 
        a.mirador-icon-metadata-view:hover, 
        a.mirador-icon-toc.selected, 
        a.mirador-icon-toc:hover, 
        a.mirador-icon-view-type.selected, 
        a.mirador-icon-view-type:hover, 
        a.mirador-icon-window-menu:hover, 
        a.mirador-osd-fullscreen:hover,

        a.mirador-close-window:hover,
        a.mirador-icon-metadata-view:hover,
        a.mirador-icon-metadata-view:active,
        a.mirador-icon-metadata-view:focus,
        a.mirador-icon-toc:hover,
        a.mirador-icon-toc:active,
        a.mirador-icon-toc:focus,
    
        a.mirador-icon-view-type:hover,
        a.mirador-icon-view-type:active,
        a.mirador-icon-view-type:focus,
    
        a.mirador-osd-fullscreen:hover,
        
        .slot .remove-slot-option:hover,
        #manifest-select-menu .remove-object-option:hover {

            color: $mirador-main-color;
        }

        .content-container {

            margin-top: 41px;
        }

        .overlay {
            color: $mirador-main-color;
            background: #f9f9f9f7;
        }

        .sub-title {

            border-bottom: 1px solid $color-border;
            color: $mirador-main-color;
            font-size: 16px;
            text-transform: capitalize;
        }

        .metadata-label {

            color: $mirador-main-color;
            text-transform: capitalize;
            font-size: 14px;
            line-height: 1.5;
            font-style: normal;
        
        }
        
        .metadata-value {
            font-size: 14px;

            ul{
        
                padding-top: 6px;
                padding-left: 26px;
                font-weight: 600;
                letter-spacing: -0.1px;
                margin-bottom: 0px;
            }

            li{
        
                padding-bottom: 3px;
            }
        }

        hr {
            
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }

        .metadata-listing a {

            border-bottom: 1px dotted $mirador-main-color;
        }
        
        .metadata-listing a:hover,
        .metadata-listing a:active,
        .metadata-listing a:focus {
        
            color: $mirador-main-color;
        }

        ul.listing-thumbs {

            padding: 15px 20px;
        }

        ul.listing-thumbs li {

            font-size: 10px;
            padding: 20px 10px 10px 10px;

            display: flex;
            justify-content: center;
            flex-direction: column;

            img {
            
                max-height: 150px;
                max-width: 300px;
                width: auto;
            }

            img.thumbnail-image {
        
                border: 1px solid $color-medium;
                background-color: $color-base;
                box-shadow: none;
                margin: 0;
            }

            img.thumbnail-image.highlight {

                border: 2px solid $color-action;
            }

            img:hover {

                transform: scale(1.05);
                border: 2px solid $color-action;
            }

            .thumb-label {

                color: $mirador-main-color;
                white-space: normal;
                padding-top: 11px;
                font-size: 13px;
                max-width: 300px;
            
            }   
        }

        ul.scroll-listing-thumbs li {
            
            .thumb-label {

            //   max-width: 270px;
                color: $mirador-main-color;
                padding-top: 10px;
                font-size: 14px;
            }

        }

        a.mirador-icon-metadata-view, 
        a.mirador-icon-view-type, 
        a.mirador-osd-fullscreen {
        
            color: $mirador-main-color;
            margin-right: 10px;

            ul.dropdown {

                color: $mirador-main-color;
                background-color: $mirador-dropdown-color-bg;
            }
        }

        a.mirador-icon-view-type {
            margin-right: 10px;
        }

        .layout-slot {

          //  box-shadow: 0px 0px 3px $color-miradorSlot-shadow;
          box-shadow: none;
          border-radius: $border-radius;
          border: 1px solid $color-border;
        }

        .slot {

            box-shadow: none;
          
            &.draggedOver,
            &.focused,
            &:hover {
    
               box-shadow: none;

            }

            .remove-slot-option {
                z-index: 0;
                color: $mirador-main-color;
            }

            .addItemLink {
                // move the add link container a bit from the top so it doesn't overlap with close icon
                top: 40px;
            }

            h1 {

                color: $mirador-main-color;
            }

            h1:hover {
                color: $color-dark;
            }
        }

    }

    .grid-item .grid-icon {

        background-color: $color-secondary-light;
    }

    .grid-item.hovered .grid-icon {

        background: $color-imageIcons;
         //box-shadow: 0 0 5px $mirador-main-color;
        box-shadow: none;
        transform: scale(1.03) translateY(-2px) translateX(-2px);
        
    }

    #manifest-select-menu {

        background-color: $color-lighter;
        margin: 3px;
        box-shadow: none;


        & .container {
            padding: 10px;
            background-color: $mirador-window-color-bg;
            border: 1px solid $color-border;
            color: $mirador-main-color;

            .manifest-panel-controls {

                display: flex;
                align-items: center;
                width: 100%;
                flex-direction: row-reverse;

                .remove-object-option {

                    display: flex;
                    align-items: center;
                    gap: 2px;

                    background-color: $mirador-window-color-bg;
                    border: none;
                    color: $color-secondary;
                    font-weight: 500;
                    text-shadow: none;

                    font-size: 12.5px;
                    line-height: 1.2;
                    padding: 0;

                    &:hover,
                    &:focus {
                        background-color: $mirador-window-color-bg;
                        color: $color-secondary;
                    }
                
                    .fa {
                        color: $color-secondary;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }

        .manifest-panel-controls #load-controls {

            margin: 10px;

            #manifest-search-form {

                display: none;
            }
        }
    }

    #manifest-search-form input:focus,
    #url-load-form input:focus,
    #url-load-form input[type=submit]:hover {

        border-color: $color-border;
    }

    #load-controls input[type=submit] {

        color: $color-white;
        background-color: $color-secondary;
        border-color: $color-secondary;

    }

    .select-metadata {

        margin-right: 20px;
        margin-left: 10px;
    }

    .items-listing {

        padding: 0 !important;
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;
        background-color: $color-off-white;

        .repo-image {

            display: none;
        }

        & li {
            border-bottom: 1px solid $color-border;

            &:hover {
                background-color: $color-lighter;
            }
        }
    }

    .items-listing h3,
    .canvas-count,
    .repo-label {

        color: $mirador-main-color;
    }

    .items-listing h3 {

        font-weight: 600;
    }

    .select-results {
        border-top: 1px solid $color-border;
        overflow-y: auto;
        margin-top: 5px;
    }

    .preview-image {

        border: 1px solid $color-medium;
        background-color: $color-base;
        box-shadow: none;

        &:hover {
            box-shadow: none;
            border: 1px solid $color-medium;
         }
    }



    /* Bottom Panel */
    .mirador-osd-toggle-bottom-panel {

        bottom: -8px;
    }

    .bottomPanel {

        background-color: $color-miradorBottomPanel-bg;
        border-top: 1px solid $color-border;
        height: 120px;

        .panel-thumbnail-view {

            overflow-x: auto;

            & ul {

                height: 100%;
                display: flex;
                align-items: center;
                margin: 0 !important;
    
                &.panel-listing-thumbs li {
    
                    margin: 0;

                    .thumb-label {
            
                        color: $mirador-main-color;
                        padding-top: 3px;
                    }

                    img.thumbnail-image {
        
                        border: 1px solid $color-medium;
                        background-color: $color-base;
                        box-shadow: none;
                        margin: 0;

                        max-height: 70px;
                        width: auto;
                    }

                    img.thumbnail-image.highlight {
        
                        border: 2px solid $color-action;
                    }
        
                    img:hover {
        
                        transform: scale(1.05);
                        border: 2px solid $color-action;
                    } 
                }
            }
        }
    }

    .mirador-osd-previous .fa-chevron-left,
    .mirador-osd-next .fa-chevron-right {

        font-size: 2em;
    }

    /*    Filter sliders */
    .hud-control .ui-slider, .mirador .ui-slider-vertical {
        width: 6px;
        margin-left: 22px;
    }

    .qtip {

        min-width: 160px;

        /* add following only to style the popover */
        /* left: 0;
        top: 0;
        display: block;  */
    
    }

    /*     Annotation */
    /*    Annotation creation popup */
    .qtip-bootstrap {

        line-height: 1.3;
        color: $mirador-main-color;

        border: 1px solid $color-border;
        border-radius: $border-radius;
        padding: 2px;
        padding-right: 5px;
        background-color: $color-lighter;

        input.annotation-body-editor__title-field {

            height: 22px;
            width: 100%;
            color: $mirador-main-color;
            border-radius: 4px;
            border: 1px solid $color-border;

            &::placeholder {

                padding: 0 6px;
            }
        }

        .annotation-editor {

            .button-container {

                margin: 10px 0 0;
            } 
        }
    }

    /*     Annotation viewer */
    .qtip-viewer {
        
        max-width: 210px;

        line-height: 0;
        color: $mirador-main-color;
        border: 1px solid $color-border;

        padding: 12px;

        .qtip-content {

            height: 100%;
            min-width: auto;
        }

        .text-viewer {

            color: $mirador-main-color;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 5px;

        }

        .annotation-display {

            line-height: 1;
            padding: 7px 6px;
            border-bottom: none;

            &:hover {

            //    background-color: $color-light;
            //    border-radius: 2px;
            }

             /* .open-region {
                font-size: 12px;
                margin-left: 5px;
                text-decoration: underline;
              } */
        }

        .all-annotations > .annotation-display:not(:last-child) {

           // border-bottom: 1px solid $color-border;
        }

        .annotation-tooltip .button-container {

            margin-bottom: 0px;
            text-align: left;
            line-height: 1.3;
            display: flex;
            align-items: center;
            justify-content: space-between;

            i {

                font-size: 16px;
                padding: 0;
            }
            
            a {

                font-size: 12px;
                text-decoration: none;
                margin: 0;
                
                &:hover {
                    color: $mirador-main-color;
                }

                &:not(:last-child) {

                    padding-right: 8px;
                }
            }
        }

        mp-template-item {

            a {
                text-decoration: underline;
            }

            &:hover {
                color: $mirador-main-color;
            }

        }
    }
}

//tooltip in mirador
.mirador .researchspace-mirador .qtip-dark {
    background-color: $color-miradorTooltipDark-bg;
    border-color: $color-miradorTooltipDark-bg;
    color: $color-white;

    font-size: 13px;
    font-weight: 600;
    letter-spacing: .2px;
    line-height: 1.3;
    padding: 5px;
}


.TextEditor--editorContainer a:hover {
    background-color: transparent;
}
