.ontodia-paper-area--animated {
    .ontodia-overlayed-element {
        transition: transform 0.5s ease-in-out;
    }
    .ontodia-link-layer, .ontodia-paper-area__widgets {
        transition: none;
        opacity: 0;
    }
}

.ontodia-link-layer, .ontodia-paper-area__widgets {
    transition: opacity 0.5s ease-in-out;
}