/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
//Resources backgrounds 
.rs-entity-bg-thing {
  background-color: $color-entity-bg-thing;
}

.rs-entity-bg-actor {
  background-color: $color-entity-bg-actor;
}

.rs-entity-bg-type {
  background-color: $color-entity-bg-type;
}

.rs-entity-bg-resource {
  background-color: $color-entity-bg-resource;
}

//Resource icon color
.rs-icon.rs-entity-icon-thing {
  color: $color-entity-icon-thing;
}

.rs-icon.rs-entity-icon-actor {
  color: $color-entity-icon-actor;
}

.rs-icon.rs-entity-icon-type {
  color: $color-entity-icon-type;
}

.rs-icon.rs-entity-icon-resource {
  color: $color-entity-icon-resource;
}

// Resource page

.rs-resource-container {

  width: 100%;
  padding: 1em 0;
  background-color: $color-resourcePage-bg;

  display: grid;
  grid-template-columns: minmax(2px, 20px) 2fr minmax(2px, 20px);
  grid-template-rows: auto auto;

  grid-template-areas: '. header .'
                        '. tabs .';

  column-gap: 0;
  row-gap: 20px;
}

.rs-resource-header {
  grid-area: header;
}

.rs-resource-tabs {
  grid-area: tabs;
}

.rs-resource-tab-container {
  padding: 1.5em 1% ; 
}

.rs-resource-data-table {
  padding: 0 6%;

  .table > tbody > tr > td {
      padding: 17px 8px;
  }
}


.rs-resource-section--headerLine {
  width: 40px;
  display: inline-block;
  margin: 0;
  border-top: 1.5px solid $color-dark;
}

//.rs-section-header
.rs-resource-section--header {
  margin-left: 17px;

  font-size: $font-size-small;
}

//.rs-page-section
.rs-resource-page-section {
  padding-top: 32px;
}


//.rs-table-td-row--sectionTitle
.rs-resource--dataset-table-sectionTitle {

  width: max-content;
  padding-right: 5px;
}

//rs-mirador-container
.rs-resource-mirador-container {
  padding: 30px 10px 10px;
  height: calc(100vh - 104px - 149px);
}

//.rs-section-cards
.rs-resource-section-cards {
  padding: 24px 44px;

  @media (min-width: 1300px) {
      padding-right: 100px;
      padding-left: 100px;
  }

  @media (min-width: 1700px) {
      padding-right: 110px;
      padding-left: 110px;
  }
  
  @media (min-width: 2000px) {
      padding-right: 120px;
      padding-left: 120px;
  }
  
  @media (min-width: 2300px) {
      padding-right: 140px;
      padding-left: 140px;
  }
}

//.rs-section-showall
.rs-resource-section-showall {
  display: flex;
  justify-content: center;
  align-items: center;
}


// Hokusai specific classes
td .rs-table-td-acquisition:not(:last-child) {

  padding-bottom: 12px;
}

td .rs-table-td-dimension   {

  &:not(:last-child) {

    padding-bottom: 10px;
  }

  & span {
    text-transform:capitalize;
  }
}

.rs-inscription-container,
.rs-visualitem-container {
  display:flex;
  flex-direction:column; 
  padding-left: 18px;
}

.rs-inscription-container .rs-pd-inscription:not(:last-child), 
.rs-table-td--displayContainer .rs-table-td--display:not(:last-child) {
  border-bottom: 1px solid $color-light;
  margin-bottom: 12px;
  padding-bottom: 14px;
}

.rs-visualitem-container .rs-pd-visualitem:not(:last-child) {
   padding-bottom: 8px;
}

.rs-inscription-container .rs-pd-inscription div {
    padding-bottom: 6px;
}

.rs-table-td-info {
  color: #8981b7; 
  font-size: 13px !important;
  padding-left: 3px;
}

.rs-table-2col-flex {
  display: flex; 
  padding-top: 15px; 
  padding-bottom: 10px;
}

.rs-subfield-title {
  width: 75px;
  min-width: 75px;
}

//.table-responsive.rs-table.rs-object-dataset--table table td 
.table-responsive.rs-table.rs-resource--dataset-table table td {

  padding: 20px 8px;

  .rs-table-td-row, 
  .rs-printinfo-container {

    padding-bottom: 8px;
  }

  .rs-table-td-row:last-child, 
  .rs-printinfo-container:last-child {

    padding-bottom: 0px;

  }
}











