/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.root {
  > svg {
    overflow: hidden;
  }

  :global {
    .node-circle {
      fill: #ffffff;
      stroke: steelblue;
      stroke-width: 1.5px;
      opacity: 1;
    }
    .node-circle-children {
      fill: lightsteelblue;
      stroke: steelblue;
      stroke-width: 1.5px;
      opacity: 1;
    }
    .node-text {
      white-space: nowrap;
      font-size: 15px;
    }
    .node-cell {
      display: table-cell;
      vertical-align: middle;
      > * {
        background: white;
      }
    }
    .link {
      fill: none;
      stroke: #000;
      stroke-opacity: 0.2;
      stroke-width: 1.5px;
    }
    .links {
      fill: none;
      stroke: #000;
      stroke-opacity: 0.1;
    }
  }
}
