/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@import "~basic-styles.scss";

// Text Editor

//$editorWidth: 794px;
$editorWidth: 100%;

:global(.Dashboard--template) {
  height: 100%;
}

.narrativeHolder {
  height: 100%;
  width: 100%;
  background-color: $color-narrative-bg;
  overflow: hidden;

  display: grid;
  grid-template-columns: minmax(3px, .2fr) 2fr minmax(3px, .2fr);
  grid-template-rows: fit-content(100px) auto 15px;

  grid-template-areas: '. toolbar .'
                        '. editor .'
                        '. . .';

  // DropArea styling
  :global(.DropArea--dropArea) {
    height: 40px;
  }

  :global(.DropArea--dropArea.mp-droppable-enabled > .DropArea--children) {
    display: none;
  }

  :global(.DropArea--messageWrapper) {
    height: 100%;
    display: none;
  }

  :global(.DropArea--dropArea.mp-droppable-enabled .DropArea--messageWrapper) {
    display: block;
    background-color: transparent;
  }

  :global(.DropArea--dropMessage) {

    min-height: 100%;
    height: 100% !important;
    padding: 3px 0;

  }

  :global(.DropArea--dropArea.mp-droppable-enabled:not(.mp-droppable-hover) .DropArea--dropMessage) {

    background-color: transparent;
  }

  :global(.DropArea--dropArea.mp-droppable-enabled.mp-droppable-hover .DropArea--dropMessage) {
    background-color: $color-dark--op30 !important;
  }

}

.titleHolder {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}

.titleInput {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  :global(.form-control) {

    font-size: 18px;
    letter-spacing: .3px;
    font-weight: bold;
    height: 40px;
    padding: 0 15px;
  }
}

.draggableGripper {

  width: auto;
  margin-right: 5px;
  display: flex;
  justify-content: flex-start;

  cursor: grab;
  flex-shrink: 0;

  :global(.rs-icon.rs-icon-drag_points),
  :global(.material-icons-round),
  :global(.material-symbols-rounded) {

    font-size: 24px;
  }
}

.resourceBlock {
  display: flex;
  justify-content: center;
  border: 2px dashed $color-white;
  margin: 0 0 calc($line-height-computed / 2);

  // TODO
  & > div > :global(.resource-card) {
    margin: 0;
  }

  :global(.mirador) {

    border: 1px solid $color-border;
  }

  :global(.mirador-osd-annotation-controls) {
    display: none;
  }

  :global(.mirador .mirador-manipulation-controls) {
      top: 0;
  }

  :global(.mirador a:hover) {
    background-color: transparent;
  }

  :global(a.edit), :global(a.delete) {
  //  display: none;
  }

  & :global(.semantic-table-holder) {

    & :global(.griddle-custom-row) {

      gap: 10px;
    }
  }
}

.resourceBlockActive {
  border: 2px dashed $color-dark;
}

/*
 we need to set relative position for sidebar and editor holder, so we can
 get relative position of the editor and use it for sidebar positioning
*/
.sidebarAndEditorHolder {

  grid-area: editor;
  justify-self: center;

  width: 100%;
 // max-width: 1100px;

  overflow: hidden;

  background-color: $color-narrative-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  
  
}

.editorContainer {
  // need for text-align to work properly
  white-space: normal;
  overflow: auto;

  flex: 1;

  font-size: 15px;
  line-height: 1.3;

  border: 1px solid $input-border-color;
  background-color: rgb(255, 255, 255);
  width: 100%;
  box-shadow: 0 0 3px #c1c1c940;

  padding: 4% 10%;

  // for edit mode, later extract

  & > div {
  //  padding: 20px 70px;
  }
  & a {
    cursor: pointer;
    text-decoration: underline;
  }

  & a:hover {
 //   background-color:  #f2f3f5;
  }
}

.sidebarContainer {
    position: absolute;
}


// Sidebar

.sidebar {
    border-right: 3px solid $color-border;
    padding: 0 5px;
    position: absolute;
    transition: transform .15s;
    left: 141px;

    :global(.btn) {
      background-color: $color-narrative-bg;
    }
}


// Toolbar
.toolbar {

  grid-area: toolbar;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin: 7px 0;
  padding: 0;

  :global(.btn-toolbar > .btn-group) {
    margin-left: 0;
  }

  &:before, &:after {
    content: none;
  }

/*   :global(.btn) {

      &[disabled] {

          &:hover {
              box-shadow: none;
              background-color: $color-btn-toolbar-bg;
              color: $color-btn-toolbar;
          }
      }
  } */

/*   :global(.btn-default) {
      height: 34px;
      font-size: 13px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: $color-btn-toolbar;
      background-color: $color-btn-toolbar-bg;
      padding: 0 11px;
      margin: 5px 0;

      &:hover {
        box-shadow: inset 0px -2px 0px 0px $color-btn-toolbar;
      }

      &:global(.active) {
        color: $color-btn-toolbar;
        background-color: $color-dark;
    }
  } */
}

.toolbarBtnGroup {

  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.dropdown {}

.sidebarDropdown {
  border: 0;
}

.dropdownMenuItem {
    width: 130px;
    display: inline-block;
    text-align: start;
}

.dropdownMenuItemIcon {
    width: 25px;
    display: inline-block;
}

.toolbar .dropdownMenuItemIcon {
    margin-right: 10px;
    color: $color-base;

    :global(.fa) {

      font-size: 15px;
    }

}
.sidebar .dropdownMenuItemIcon {
    margin-right: 5px;
}

// links
.internalLink {
  /**/
}

.externalLink:before {
  font-family: FontAwesome;
  content: "\F08E ";
  font-size: 11px;
}

.externalLinkHolder {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-grow: 1;
}

.linkPopover {
  display: flex;

  width: 245px;
  height: 100%;
  padding: 3px;

  & :global(.DropArea--children) {
    align-items: center;
    justify-content: center;
    width: fit-content;
  }

  & :global(.DropArea--dropArea) {
    padding-right: 7px;
  }

  & :global(.DropArea--dropMessage) {
    min-height: 70px;

    font-size: 14px;
    line-height: 1.3;
    border: 1px dashed $color-secondary-light;
    background-color: transparent;
    color: $color-secondary;
  }

  & :global(.DropArea--dropArea.mp-droppable-hover .DropArea--dropMessage) {
    background-color: $color-dark--op30 !important;
  }

  & a {
    text-decoration: underline;
    color: $color-secondary;
  }

  :global(.form-control) {
    background-color: transparent;
    color: $color-secondary;
    border-color: $color-border;

    &::placeholder {
      color: $color-base;
    }

    &:focus {
      border-bottom: 1px solid $color-border;
    }
  }

  :global(.btn-default), :global(.btn-success) {

    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-border;
    border-radius: $border-radius;

    :global(.fa) {
      padding: 0;
    }
  }

  :global(.btn-success) {
    border: none;
    margin-right: 1px;

    i {
      color:$color-light;
    }
  }
}

:global {
  // link editor
  #external-link-popover,
  #internal-link-popover {

    background-color: $color-narrative-popover-bg;
    border-color: $color-border;
    color: $color-dark;

    &.popover.top > .arrow {
      border-top-color: $color-border;

        &:after {
        border-top-color: $color-narrative-popover-bg;
        }
    }

    .btn-group {
      display: flex;
      margin-left: 8px;
      height: 35px;
    }
  }

  // see https://github.com/ianstormtaylor/slate/issues/2302
  [data-slate-void='true'], [data-slate-spacer="true"]{
    position: relative!important
  }
}
