.ontodia-class-tree {
  flex: auto;
  display: flex;
  flex-direction: column;

  &__filter {
    flex-shrink: 0;
    margin: 0 0 3px 0;
  }

  &__filter-group {
    margin-left: 10px;
  }

  &__only-creatable {

    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 0px;
    padding-left: 10px;
    font-weight: 600;
    color: $color-secondary;

    input {
      margin: 0 5px;
    }
    
  }

  &__tree {
  //  border-top: 1px solid rgb(221, 221, 221);
    padding: 8px 14px 8px 18px;
  }

  &__spinner {
    align-self: center;
    /* center vertically in flexbox */
    margin: auto;
  }
}

.ontodia-class-leaf {
  margin: 1px 0px 5px 0px;

  &__row {
    margin: 3px 0;
    display: flex;
    align-items: center;
  //  white-space: nowrap;
    user-select: none;
  //  > * { flex-shrink: 0; }
  }

  &__body { 
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 2px 4px;

    &:hover {
      
      cursor: pointer;
    }
    &--selected {
      background-color: $color-light;
      border-color: $color-light;
    }
  }

  &__icon-container {
    height: 16px;
  }

  &__icon {
    display: block;
    height: 100%;
  }

  &__label {

    color: $color-secondary;
    font-size: $font-size-large;
    font-weight: 600;
  }

  &__highlighted-term {
    font-weight: bold;
    color:$color-action;
  }

  &__count {
    margin-left: 5px;
  }

  &__children {
    margin-left: 20px;
    padding: 1px 0 3px 0;

    .ontodia-class-leaf__label {
      font-size: 14.5px;
    }
  }

  &__no-toggle {
    display: inline-block;
    width: 22px;
    height: 22px;
  }

  &__toggle {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;

    .fa {
      cursor: pointer;
      color: $color-dark--op50;
      font-size: 15px;

      &:hover {
        color :$color-dark;
      }
    }

    
  }

  &__toggle-icon {

    display: flex;
    align-items: center;

    i {

      color: $color-secondary-light;
      font-size: 22px;
    }
  }

  &__create {
    margin-left: 5px;
    > button {
      cursor: pointer;
    }

    .btn-icon {

      padding: 0;
    }
  }
}
