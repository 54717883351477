/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.component {
  height: 100%;
}

.virtualizedList {

  margin-top: 5px;
  outline: none;
}

.expandToggle {
  margin-left: 5px;
  margin-right: 5px;
  width: 5px;
  cursor: pointer;
}

.item {
  white-space: nowrap;
}

.itemCollapsed > .expandToggle {
  @include icon(caret-right);
}

.itemExpanded > .expandToggle {
  @include icon(caret-down);
}

.itemExpanded > input,
.itemCollapsed > input {
  margin-left: 5px;
}

.itemContent {
  font-size: $font-size-small;
  display: inline;
  cursor: pointer;
  margin-left: 5px;
}

.spinner {
  margin-left: 20px;
  position: static;
  left: unset;
  top: unset;
}

:indeterminate {
  opacity: 0.5;
}
