.ontodia-btn {
  display: inline-block;
  padding: 8px 10px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border-radius: $border-radius;
  border: 1px solid transparent;
  text-transform: none;
  transition: background-color 0.3s;

  &[disabled] {
    cursor: not-allowed;
    opacity: .65;
  }
}

.ontodia-btn-default {
  color: $color-btn-default;
  background-color: $color-btn-default-bg;
  border-color: $color-btn-default-border;
  text-shadow: none;
  font-weight: 600;

  i {
      color: $color-btn-default;
  }

  &[disabled], &[disabled]:hover, &[disabled].active, &[disabled].active:hover {
    opacity: 0.7;
  }
}

.ontodia-btn-primary {
  color: $color-white;
  background-color: $color-dark;
  border-color: $color-dark;
  outline: none;

  &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active, &[disabled].active {
    opacity: 0.7;
  }
}

.ontodia-btn-action {
  color: $color-white;
  background-color: $color-action;
  border-color: $color-action;
  outline: none;
  font-weight: 600;

  &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active, &[disabled].active {
    opacity: 0.7;
  }
}

.ontodia-btn-success {
  background-color: $color-success;
  border-color: $color-success;
  color: $color-white;
  transition: 0.3s;

  &:hover {
    background: $color-success-darker;
  }
}

.ontodia-btn-danger {
  background-color: $color-danger-light;
  border-color: $color-danger-border;
  color: $color-white;
  transition: 0.3s;

  &:hover {
    background: $color-danger-darker;
  }
}
