/**
 * ResearchSpace
 * Copyright (C) 2024, PHAROS: The International Consortium of Photo Archives
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
 @import '~basic-styles.scss';

 .holder {
    overflow: auto;

    :global {
        .highlighted-uri {
            background-color: rgba(255, 229, 153, 0.4);
            border: 1px solid #FFDA99;
            cursor: pointer;
        }

        .highlighted-text {
            background-color: rgba(255, 229, 153, 0.4);
            cursor: pointer;
        }
    }
 }