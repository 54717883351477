/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@import '~basic-styles.scss';

.overlay-comparison {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__controls {
    width: 80%;
    margin-bottom: 10px;
    display: flex;
    align-self: center;
    align-items: center;
  }

  &__image_label {
    text-align: center;
  }

  &__slider {
    margin-left: 10px;
    margin-right: 10px;
  }
  &__submit {
    margin: 10px;
  }

  &__image-container {
    flex-grow: 1;
    height: 0px;
  }
}

.open-seadragon-overlay {
  width: 100%;
  height: 100%;
  position: relative;
}
