/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.component {
  display: flex;
  flex-direction: column;
}

.tabHeader {
  :global(.badge) {
    margin-left: 5px;
  }
}

.tabIcon {
  height: 20px;
  margin-right: 5px;
}

.annotationList {
  flex: auto;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  overflow-y: scroll;
}

$focusOffset: 5px;
$focusMargin: 20px;

.annotation {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: $focusMargin;
  margin-right: 0px;

  border: 1px solid #e2e2e2;
  box-shadow: 5px 6px 5px -1px rgba(173, 173, 173, 1);

  opacity: 0.5;
  transition: opacity 150ms ease-out, margin-left 150ms ease-in-out, margin-right 150ms ease-in-out;

  &.focused {
    margin-left: $focusOffset;
    margin-right: ($focusMargin - $focusOffset);
  }

  &.highlighted {
    opacity: 1;
  }

  &:last-child {
    margin-bottom: 100px;
  }
}

.newAnnotationPlaceholder {
  height: 80px;
  border: 2px dashed #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  color: #b9b9b9;
}
