/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.dialog {
  height: 100%;
 // overflow: auto;
}

/*  Knowledge Map - Card Form */
.content {
  padding: 15px;
  height: 100%;

    :global(.semantic-form) {

      height: calc(100% - 80px);
      width: 100%;

      :global(.composite-input) {

        overflow: hidden;
        display: flex;
        flex-direction: column;

        .inputs {

          flex: 1;
          overflow: auto;

          & > :global(.semantic-form-input-decorator.semantic-form-input-decorator--with-header:first-of-type .semantic-form-input-decorator__header) {

            margin-top: 0;
          }

          :global(.dragAndDrop-input-container .DragAndDropInput--itemArea .placeholder-item) {
            padding: 10px 5px;
            font-size: 13.5px;
          }

          :global(.dragAndDrop-input-container .DragAndDropInput--itemArea .placeholder-item .upload_icon.material-icons-round) {
            padding: 0;
            font-size: 23px;
          }
        }

        :global(.btn-form-actions) {

          margin-top: 10px;
        }
      }

      :global(.form-scroll-area.nested-form) {
        overflow: auto;
      }

      :global(.modal-header) {

        padding: 10px 15px;

        & :global(.modal-title) {
          font-size: 15px;
          font-weight: 700;
        }
      }

      & :global(.modal-body) {
        padding: 15px;
      }
    }

    :global(.semantic-form:first-child) {

      height: inherit;
    }

}
