/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

// @import '~flexlayout-react/style/light.css';

// ontodia-form-actions is used only in data model diagrams
// we want to hide it in exported svg,png
.ontodia .ontodia-form-actions {
  position: absolute;
  top: -5px;
  left: -3px;
  display: flex;
}

.ontodia-form-actions {
  display: none;
}

.ontodia-paper-area__watermark {
  display: none;
}


.ontodia .ontodia-paper-area {

  /*  Knowledge Map - Card Form */
  .EntityForm--content {

    .Select-clear-zone,
    .Select-arrow-zone {
      background-color: $color-white;
    }
  }

  .set-management__set-item .panel-body {
    padding: 0px !important;
  }

  .rs-grid-element.text-center.panel.panel-default {
    border: none;
  }

  .rs-grid-element.text-center.panel.panel-default {
    box-shadow: none !important;
  }

  .ontodia-edit-form__control-row .SemanticTreeInput--inputAndButtons {
    width: 100%;

    .SemanticTreeInput--browseButton.btn.btn-default {
      width: 20%;
    }

    .clearable-input.SemanticTreeInput--textInput {
      width: 80%;
      display: flex;
      align-items: center;
    }
  }

  .SemanticTreeInput--dropdown {
    padding-top: 5px;
  }
}

//////// Thinking Frames
/* Thinking Frame link editor */
.ontodia-edit-link-form {

  .SemanticTreeInput--tree {
    height: 150px;
  }

  .clearable-input > .RemovableBadge {
    margin: 5px !important;
  }

  .RemovableBadge + .clearable-input__input-with-clear {
    display: none;
  }

  .SemanticTreeInput--dropdownFooter {
    display: none;
  }
}

.mirador-noimage-container {

  height: 100%;
  padding: 15px 30px;
}

//Temporary disabled until we have a new Resource page
.ontodia-halo__folow {
  display: none;
}

//Temporary disabled until we fix the halo resize to be coherent with the card resize (currently they don't resize together
.ontodia-overlayed-element__resizable-handle {
  display: none;
}

//Temporary disabled until the action triggers the right sidebar to open
//The functionality is still available opening the sidebar and clicking on a card
.ontodia-halo__add-to-filter {
  display: none;
}