/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
 

// scrollbars
/* Only Chrome */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-box-shadow: inset 0 0 6px $scrollbar-track;
}

/* Handle */
::-webkit-scrollbar-thumb {

    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-color: $scrollbar-bg;
    -webkit-box-shadow: inset 0 0 6px rgba(190, 190, 202, 0.5); 
    
}
  
::-webkit-scrollbar-corner {
    background-color: transparent;
}

/* Only FireFox */

@-moz-document url-prefix() {
    * {
      scrollbar-color: $scrollbar-bg transparent;
      scrollbar-width: thin;
    }
}