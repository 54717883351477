/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @author Artem Kozlov <ak@metaphacts.com>
 */

@import '~basic-styles.scss';
@import '../commons/variables.scss';

.word {
  display: inline-block;
  margin: 0 2.5px;
}

.separator {
  font-style: italic;
}

.start {
  margin: 0 5px 0 0;
}

.domain,
.Resource {
  width: max-content;
  font-weight: 600;
  color: $color-info;
  font-size: 15px;
  line-height: 1.2;
}

.domain::first-letter {
  text-transform: uppercase;
}

.relation {
  text-transform: uppercase;
}

.Text {
  font-style: italic;
}
