/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@import '~basic-styles.scss';

.rs-udp-visibility {
  position: absolute;
  top: 16px;
  right: 25px;
  z-index: $zindex-popover;

  .share-component {
    padding: 0 $padding-base-horizontal * 2;
  }

  .dropdown-menu {
    min-width: 115px;
    right: 0;
    left: unset;
    font-family: $font-family-base;
    font-size: 13px;
    line-height: 1.2 !important;

    & fieldset {
        padding-top: 3px;
    }

    & .radio {
        margin-bottom: 7px;
    }

    & .share-component {
        padding: 0 17px !important;
    }

    & input[type="radio"] {
        margin: 2px -20px 0;
    }

    & .radio label {
        padding-left: 25px;
    }
  }
}

#visibility-input {
  div.radio {
    display: flex;
    align-items: center;
  }
}

.visibility-group-selector {
  width: 300px;
  margin-left: $padding-base-horizontal;
}

