/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/* Utilities */
.rs- {
  &offset-1 {
    margin-left: 8.333333%;
  }

  &offset-2 {
    margin-left: 16.666667%;
  }

  &offset-3 {
    margin-left: 25%;
  }

  &offset-4 {
    margin-left: 33.333333%;
  }

  &offset-5 {
    margin-left: 41.666667%;
  }

  &offset-6 {
    margin-left: 50%;
  }

  &offset-7 {
    margin-left: 58.333333%;
  }

  &offset-8 {
    margin-left: 66.666667%;
  }

  &offset-9 {
    margin-left: 75%;
  }

  &offset-10 {
    margin-left: 83.333333%;
  }

  &offset-11 {
    margin-left: 91.666667%;
  }
}

.row-m0 {
  margin-left: 0px;
  margin-right: 0px;
}

.col-p0 {
  padding-left: 0px;
  padding-right: 0px;
}

.align-middle {
  vertical-align: middle !important;
}

.rs-vertical-align {
  align-items: center;
  display: flex;
}

.ml-auto {
  margin-left: auto !important;
}

//Flexbox CSS that makes the auto-layout columns work in Bootstrap 4
/* <div class="container">
    <div class="row d-flex">
        <div class="col-sm-3 bg-success">col-sm-3</div>
        <div class="col-auto">col-auto</div>
        <div class="col-sm-9 bg-success">col-sm-3</div>
    </div>
</div> */

.d-flex {
  display: flex;
}

.d-flex > div {
  float: none;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.pb-2 {
  padding-bottom: 2px;
}

.pb-2-5 {
  padding-bottom: 2.5px;
}

.pb-3 {
  padding-bottom: 3px;
}

.pl-0 {
  padding-left: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-4 {
  padding-right: 4px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.m-0 {
  margin: 0 !important;
}

//Bootstrap 4 Hiding elements
.d-none {
  display: none !important;
}

.d-block {
  display: block;
}

.border-none {
  border: none;
}

.row-center {
  display: flex;
  justify-content: center;
}

.h100 {

  height: 100%;
}

.w100 {

  width: 100%;
}

.overflow-y-auto {
  overflow-y: auto;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-block {
    display: block;
  }
}

@media (min-width: 768px) {
  .rs-only-xs {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .rs-xs-none {
    display: none !important;
  }
}

/* High Resolution columns */
@media (min-width: 2000px) {
  .rs-col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .rs-col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .rs-col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .rs-col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .rs-col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .rs-col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .rs-col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .rs-col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .rs-col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .rs-col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .rs-col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .rs-col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}


/* spinner */
.system-spinner {
  position: relative;
  /* top: 50%; */
  /* left: 50%; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  color: $color-dark;
  text-decoration: none;

  &__icon {

    @include spin-icon();
  //  @include icon(cog);
    font-size: 17px;
    text-decoration: none !important;
    color: $color-dark;
  }

  &__message {
    font-size: 13.5px;
    text-decoration: none !important;
  }
}

.webvowl-import {

  #detailsArea {
    background-color: $color-dark;
    color: $color-white;
    width: 25% !important;

    h5 {
      color: $color-white;
    }

    h3 {
      background: $color-dark !important;
      color: $color-white !important;
    }

    .accordion-trigger.accordion-trigger-active::before,
    .accordion-trigger:not(.accordion-trigger-active)::before {
      font-size: 9px;
      padding-right: 9px;
    }

    .accordion-container p {
      margin: 5px 22px !important;
    }
  }

  nav#optionsArea {
    background-color: $color-dark;
  }

  ul#optionsMenu {
    width: 63% !important;
  }
}

.text-first-letter-uppercase {

  display: inline-block;
  text-transform: lowercase;

  &::first-letter {

   text-transform: uppercase;
  }
 }

.link-draggable {

  display: flex; 
  align-items: flex-start; 
  cursor: grab;
  gap: 4px;

  .material-icons-round,
  .material-symbols-rounded {
    font-size: 24px;     
    margin-left: -5px;
  //  color: $color-base;
  }
}

.draggable-container {
  
  border: 1px dashed transparent;
  padding: 2px 15px;
  border-radius: 2px;

  &:hover {
    cursor: grab;
    border: 1px solid $color-border;
    background-color: $color-off-white;
  }
}

.a-draggable {
  cursor: grab;
  display: block;
}

.resource-thumbnail-small-container {

  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 40px;

  background-color: $color-white;
  border: 1.5px solid $color-border;
  border-radius: 2px;
  cursor: pointer;

  svg {
    max-height: 40px;
  }
}

.resource-thumbnail-small,
i.resource-thumbnail-small-fallback-icon {

  height: 40px;
  width: 45px;
  object-fit: cover;
  object-position: 50% 50%;
  display: flex;
  justify-content: center;
}

.resource-thumbnail-small {
  
  border-radius: 2px;
}