/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.holder {
  background-color: $color-codeBlock-bg;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  padding: 32px;
  display: flex;
  flex-direction: column;
  margin: $padding-base-vertical * 2 0;

  :global {
    pre {
      border-radius: $border-radius;
      background-color: $color-codeBlockHolder-bg;
      padding: $padding-base-vertical * 2 $padding-base-vertical * 2;
      margin-top: 5px;
    }

    .btn-group {
      align-self: flex-end;
    }
  }
}

.modeLabel {
  background-color: $color-codeBlockMode-bg;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  align-self: flex-start;
  padding: 5px 20px;
  color: $color-secondary;
  font-size: $font-size-small;
  font-weight: 600;
}
