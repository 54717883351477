/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
 
 // font icons generated from .svg files with https://icomoon.io/app
$iconmoon-home: "\e900";
$iconmoon-home2: "\e901";
$iconmoon-home3: "\e902";
$iconmoon-office: "\e903";
$iconmoon-newspaper: "\e904";
$iconmoon-pencil: "\e905";
$iconmoon-pencil2: "\e906";
$iconmoon-quill: "\e907";
$iconmoon-pen: "\e908";
$iconmoon-blog: "\e909";
$iconmoon-eyedropper: "\e90a";
$iconmoon-droplet: "\e90b";
$iconmoon-paint-format: "\e90c";
$iconmoon-image: "\e90d";
$iconmoon-images: "\e90e";
$iconmoon-camera: "\e90f";
$iconmoon-headphones: "\e910";
$iconmoon-music: "\e911";
$iconmoon-play: "\e912";
$iconmoon-film: "\e913";
$iconmoon-video-camera: "\e914";
$iconmoon-dice: "\e915";
$iconmoon-pacman: "\e916";
$iconmoon-spades: "\e917";
$iconmoon-clubs: "\e918";
$iconmoon-diamonds: "\e919";
$iconmoon-bullhorn: "\e91a";
$iconmoon-connection: "\e91b";
$iconmoon-podcast: "\e91c";
$iconmoon-feed: "\e91d";
$iconmoon-mic: "\e91e";
$iconmoon-book: "\e91f";
$iconmoon-books: "\e920";
$iconmoon-library: "\e921";
$iconmoon-file-text: "\e922";
$iconmoon-profile: "\e923";
$iconmoon-file-empty: "\e924";
$iconmoon-files-empty: "\e925";
$iconmoon-file-text2: "\e926";
$iconmoon-file-picture: "\e927";
$iconmoon-file-music: "\e928";
$iconmoon-file-play: "\e929";
$iconmoon-file-video: "\e92a";
$iconmoon-file-zip: "\e92b";
$iconmoon-copy: "\e92c";
$iconmoon-paste: "\e92d";
$iconmoon-stack: "\e92e";
$iconmoon-folder: "\e92f";
$iconmoon-folder-open: "\e930";
$iconmoon-folder-plus: "\e931";
$iconmoon-folder-minus: "\e932";
$iconmoon-folder-download: "\e933";
$iconmoon-folder-upload: "\e934";
$iconmoon-price-tag: "\e935";
$iconmoon-price-tags: "\e936";
$iconmoon-barcode: "\e937";
$iconmoon-qrcode: "\e938";
$iconmoon-ticket: "\e939";
$iconmoon-cart: "\e93a";
$iconmoon-coin-dollar: "\e93b";
$iconmoon-coin-euro: "\e93c";
$iconmoon-coin-pound: "\e93d";
$iconmoon-coin-yen: "\e93e";
$iconmoon-credit-card: "\e93f";
$iconmoon-calculator: "\e940";
$iconmoon-lifebuoy: "\e941";
$iconmoon-phone: "\e942";
$iconmoon-phone-hang-up: "\e943";
$iconmoon-address-book: "\e944";
$iconmoon-envelop: "\e945";
$iconmoon-pushpin: "\e946";
$iconmoon-location: "\e947";
$iconmoon-location2: "\e948";
$iconmoon-compass: "\e949";
$iconmoon-compass2: "\e94a";
$iconmoon-map: "\e94b";
$iconmoon-map2: "\e94c";
$iconmoon-history: "\e94d";
$iconmoon-clock: "\e94e";
$iconmoon-clock2: "\e94f";
$iconmoon-alarm: "\e950";
$iconmoon-bell: "\e951";
$iconmoon-stopwatch: "\e952";
$iconmoon-calendar: "\e953";
$iconmoon-printer: "\e954";
$iconmoon-keyboard: "\e955";
$iconmoon-display: "\e956";
$iconmoon-laptop: "\e957";
$iconmoon-mobile: "\e958";
$iconmoon-mobile2: "\e959";
$iconmoon-tablet: "\e95a";
$iconmoon-tv: "\e95b";
$iconmoon-drawer: "\e95c";
$iconmoon-drawer2: "\e95d";
$iconmoon-box-add: "\e95e";
$iconmoon-box-remove: "\e95f";
$iconmoon-download: "\e960";
$iconmoon-upload: "\e961";
$iconmoon-floppy-disk: "\e962";
$iconmoon-drive: "\e963";
$iconmoon-database: "\e964";
$iconmoon-undo: "\e965";
$iconmoon-redo: "\e966";
$iconmoon-undo2: "\e967";
$iconmoon-redo2: "\e968";
$iconmoon-forward: "\e969";
$iconmoon-reply: "\e96a";
$iconmoon-bubble: "\e96b";
$iconmoon-bubbles: "\e96c";
$iconmoon-bubbles2: "\e96d";
$iconmoon-bubble2: "\e96e";
$iconmoon-bubbles3: "\e96f";
$iconmoon-bubbles4: "\e970";
$iconmoon-user: "\e971";
$iconmoon-users: "\e972";
$iconmoon-user-plus: "\e973";
$iconmoon-user-minus: "\e974";
$iconmoon-user-check: "\e975";
$iconmoon-user-tie: "\e976";
$iconmoon-quotes-left: "\e977";
$iconmoon-quotes-right: "\e978";
$iconmoon-hour-glass: "\e979";
$iconmoon-spinner: "\e97a";
$iconmoon-spinner2: "\e97b";
$iconmoon-spinner3: "\e97c";
$iconmoon-spinner4: "\e97d";
$iconmoon-spinner5: "\e97e";
$iconmoon-spinner6: "\e97f";
$iconmoon-spinner7: "\e980";
$iconmoon-spinner8: "\e981";
$iconmoon-spinner9: "\e982";
$iconmoon-spinner10: "\e983";
$iconmoon-spinner11: "\e984";
$iconmoon-binoculars: "\e985";
$iconmoon-search: "\e986";
$iconmoon-zoom-in: "\e987";
$iconmoon-zoom-out: "\e988";
$iconmoon-enlarge: "\e989";
$iconmoon-shrink: "\e98a";
$iconmoon-enlarge2: "\e98b";
$iconmoon-shrink2: "\e98c";
$iconmoon-key: "\e98d";
$iconmoon-key2: "\e98e";
$iconmoon-lock: "\e98f";
$iconmoon-unlocked: "\e990";
$iconmoon-wrench: "\e991";
$iconmoon-equalizer: "\e992";
$iconmoon-equalizer2: "\e993";
$iconmoon-cog: "\e994";
$iconmoon-cogs: "\e995";
$iconmoon-hammer: "\e996";
$iconmoon-magic-wand: "\e997";
$iconmoon-aid-kit: "\e998";
$iconmoon-bug: "\e999";
$iconmoon-pie-chart: "\e99a";
$iconmoon-stats-dots: "\e99b";
$iconmoon-stats-bars: "\e99c";
$iconmoon-stats-bars2: "\e99d";
$iconmoon-trophy: "\e99e";
$iconmoon-gift: "\e99f";
$iconmoon-glass: "\e9a0";
$iconmoon-glass2: "\e9a1";
$iconmoon-mug: "\e9a2";
$iconmoon-spoon-knife: "\e9a3";
$iconmoon-leaf: "\e9a4";
$iconmoon-rocket: "\e9a5";
$iconmoon-meter: "\e9a6";
$iconmoon-meter2: "\e9a7";
$iconmoon-hammer2: "\e9a8";
$iconmoon-fire: "\e9a9";
$iconmoon-lab: "\e9aa";
$iconmoon-magnet: "\e9ab";
$iconmoon-bin: "\e9ac";
$iconmoon-bin2: "\e9ad";
$iconmoon-briefcase: "\e9ae";
$iconmoon-airplane: "\e9af";
$iconmoon-truck: "\e9b0";
$iconmoon-road: "\e9b1";
$iconmoon-accessibility: "\e9b2";
$iconmoon-target: "\e9b3";
$iconmoon-shield: "\e9b4";
$iconmoon-power: "\e9b5";
$iconmoon-switch: "\e9b6";
$iconmoon-power-cord: "\e9b7";
$iconmoon-clipboard: "\e9b8";
$iconmoon-list-numbered: "\e9b9";
$iconmoon-list: "\e9ba";
$iconmoon-list2: "\e9bb";
$iconmoon-tree: "\e9bc";
$iconmoon-menu: "\e9bd";
$iconmoon-menu2: "\e9be";
$iconmoon-menu3: "\e9bf";
$iconmoon-menu4: "\e9c0";
$iconmoon-cloud: "\e9c1";
$iconmoon-cloud-download: "\e9c2";
$iconmoon-cloud-upload: "\e9c3";
$iconmoon-cloud-check: "\e9c4";
$iconmoon-download2: "\e9c5";
$iconmoon-upload2: "\e9c6";
$iconmoon-download3: "\e9c7";
$iconmoon-upload3: "\e9c8";
$iconmoon-sphere: "\e9c9";
$iconmoon-earth: "\e9ca";
$iconmoon-link: "\e9cb";
$iconmoon-flag: "\e9cc";
$iconmoon-attachment: "\e9cd";
$iconmoon-eye: "\e9ce";
$iconmoon-eye-plus: "\e9cf";
$iconmoon-eye-minus: "\e9d0";
$iconmoon-eye-blocked: "\e9d1";
$iconmoon-bookmark: "\e9d2";
$iconmoon-bookmarks: "\e9d3";
$iconmoon-sun: "\e9d4";
$iconmoon-contrast: "\e9d5";
$iconmoon-brightness-contrast: "\e9d6";
$iconmoon-star-empty: "\e9d7";
$iconmoon-star-half: "\e9d8";
$iconmoon-star-full: "\e9d9";
$iconmoon-heart: "\e9da";
$iconmoon-heart-broken: "\e9db";
$iconmoon-man: "\e9dc";
$iconmoon-woman: "\e9dd";
$iconmoon-man-woman: "\e9de";
$iconmoon-happy: "\e9df";
$iconmoon-happy2: "\e9e0";
$iconmoon-smile: "\e9e1";
$iconmoon-smile2: "\e9e2";
$iconmoon-tongue: "\e9e3";
$iconmoon-tongue2: "\e9e4";
$iconmoon-sad: "\e9e5";
$iconmoon-sad2: "\e9e6";
$iconmoon-wink: "\e9e7";
$iconmoon-wink2: "\e9e8";
$iconmoon-grin: "\e9e9";
$iconmoon-grin2: "\e9ea";
$iconmoon-cool: "\e9eb";
$iconmoon-cool2: "\e9ec";
$iconmoon-angry: "\e9ed";
$iconmoon-angry2: "\e9ee";
$iconmoon-evil: "\e9ef";
$iconmoon-evil2: "\e9f0";
$iconmoon-shocked: "\e9f1";
$iconmoon-shocked2: "\e9f2";
$iconmoon-baffled: "\e9f3";
$iconmoon-baffled2: "\e9f4";
$iconmoon-confused: "\e9f5";
$iconmoon-confused2: "\e9f6";
$iconmoon-neutral: "\e9f7";
$iconmoon-neutral2: "\e9f8";
$iconmoon-hipster: "\e9f9";
$iconmoon-hipster2: "\e9fa";
$iconmoon-wondering: "\e9fb";
$iconmoon-wondering2: "\e9fc";
$iconmoon-sleepy: "\e9fd";
$iconmoon-sleepy2: "\e9fe";
$iconmoon-frustrated: "\e9ff";
$iconmoon-frustrated2: "\ea00";
$iconmoon-crying: "\ea01";
$iconmoon-crying2: "\ea02";
$iconmoon-point-up: "\ea03";
$iconmoon-point-right: "\ea04";
$iconmoon-point-down: "\ea05";
$iconmoon-point-left: "\ea06";
$iconmoon-warning: "\ea07";
$iconmoon-notification: "\ea08";
$iconmoon-question: "\ea09";
$iconmoon-plus: "\ea0a";
$iconmoon-minus: "\ea0b";
$iconmoon-info: "\ea0c";
$iconmoon-cancel-circle: "\ea0d";
$iconmoon-blocked: "\ea0e";
$iconmoon-cross: "\ea0f";
$iconmoon-checkmark: "\ea10";
$iconmoon-checkmark2: "\ea11";
$iconmoon-spell-check: "\ea12";
$iconmoon-enter: "\ea13";
$iconmoon-exit: "\ea14";
$iconmoon-play2: "\ea15";
$iconmoon-pause: "\ea16";
$iconmoon-stop: "\ea17";
$iconmoon-previous: "\ea18";
$iconmoon-next: "\ea19";
$iconmoon-backward: "\ea1a";
$iconmoon-forward2: "\ea1b";
$iconmoon-play3: "\ea1c";
$iconmoon-pause2: "\ea1d";
$iconmoon-stop2: "\ea1e";
$iconmoon-backward2: "\ea1f";
$iconmoon-forward3: "\ea20";
$iconmoon-first: "\ea21";
$iconmoon-last: "\ea22";
$iconmoon-previous2: "\ea23";
$iconmoon-next2: "\ea24";
$iconmoon-eject: "\ea25";
$iconmoon-volume-high: "\ea26";
$iconmoon-volume-medium: "\ea27";
$iconmoon-volume-low: "\ea28";
$iconmoon-volume-mute: "\ea29";
$iconmoon-volume-mute2: "\ea2a";
$iconmoon-volume-increase: "\ea2b";
$iconmoon-volume-decrease: "\ea2c";
$iconmoon-loop: "\ea2d";
$iconmoon-loop2: "\ea2e";
$iconmoon-infinite: "\ea2f";
$iconmoon-shuffle: "\ea30";
$iconmoon-arrow-up-left: "\ea31";
$iconmoon-arrow-up: "\ea32";
$iconmoon-arrow-up-right: "\ea33";
$iconmoon-arrow-right: "\ea34";
$iconmoon-arrow-down-right: "\ea35";
$iconmoon-arrow-down: "\ea36";
$iconmoon-arrow-down-left: "\ea37";
$iconmoon-arrow-left: "\ea38";
$iconmoon-arrow-up-left2: "\ea39";
$iconmoon-arrow-up2: "\ea3a";
$iconmoon-arrow-up-right2: "\ea3b";
$iconmoon-arrow-right2: "\ea3c";
$iconmoon-arrow-down-right2: "\ea3d";
$iconmoon-arrow-down2: "\ea3e";
$iconmoon-arrow-down-left2: "\ea3f";
$iconmoon-arrow-left2: "\ea40";
$iconmoon-circle-up: "\ea41";
$iconmoon-circle-right: "\ea42";
$iconmoon-circle-down: "\ea43";
$iconmoon-circle-left: "\ea44";
$iconmoon-tab: "\ea45";
$iconmoon-move-up: "\ea46";
$iconmoon-move-down: "\ea47";
$iconmoon-sort-alpha-asc: "\ea48";
$iconmoon-sort-alpha-desc: "\ea49";
$iconmoon-sort-numeric-asc: "\ea4a";
$iconmoon-sort-numberic-desc: "\ea4b";
$iconmoon-sort-amount-asc: "\ea4c";
$iconmoon-sort-amount-desc: "\ea4d";
$iconmoon-command: "\ea4e";
$iconmoon-shift: "\ea4f";
$iconmoon-ctrl: "\ea50";
$iconmoon-opt: "\ea51";
$iconmoon-checkbox-checked: "\ea52";
$iconmoon-checkbox-unchecked: "\ea53";
$iconmoon-radio-checked: "\ea54";
$iconmoon-radio-checked2: "\ea55";
$iconmoon-radio-unchecked: "\ea56";
$iconmoon-crop: "\ea57";
$iconmoon-make-group: "\ea58";
$iconmoon-ungroup: "\ea59";
$iconmoon-scissors: "\ea5a";
$iconmoon-filter: "\ea5b";
$iconmoon-font: "\ea5c";
$iconmoon-ligature: "\ea5d";
$iconmoon-ligature2: "\ea5e";
$iconmoon-text-height: "\ea5f";
$iconmoon-text-width: "\ea60";
$iconmoon-font-size: "\ea61";
$iconmoon-bold: "\ea62";
$iconmoon-underline: "\ea63";
$iconmoon-italic: "\ea64";
$iconmoon-strikethrough: "\ea65";
$iconmoon-omega: "\ea66";
$iconmoon-sigma: "\ea67";
$iconmoon-page-break: "\ea68";
$iconmoon-superscript: "\ea69";
$iconmoon-subscript: "\ea6a";
$iconmoon-superscript2: "\ea6b";
$iconmoon-subscript2: "\ea6c";
$iconmoon-text-color: "\ea6d";
$iconmoon-pagebreak: "\ea6e";
$iconmoon-clear-formatting: "\ea6f";
$iconmoon-table: "\ea70";
$iconmoon-table2: "\ea71";
$iconmoon-insert-template: "\ea72";
$iconmoon-pilcrow: "\ea73";
$iconmoon-ltr: "\ea74";
$iconmoon-rtl: "\ea75";
$iconmoon-section: "\ea76";
$iconmoon-paragraph-left: "\ea77";
$iconmoon-paragraph-center: "\ea78";
$iconmoon-paragraph-right: "\ea79";
$iconmoon-paragraph-justify: "\ea7a";
$iconmoon-indent-increase: "\ea7b";
$iconmoon-indent-decrease: "\ea7c";
$iconmoon-share: "\ea7d";
$iconmoon-new-tab: "\ea7e";
$iconmoon-embed: "\ea7f";
$iconmoon-embed2: "\ea80";
$iconmoon-terminal: "\ea81";
$iconmoon-share2: "\ea82";
$iconmoon-mail: "\ea83";
$iconmoon-mail2: "\ea84";
$iconmoon-mail3: "\ea85";
$iconmoon-mail4: "\ea86";
$iconmoon-amazon: "\ea87";
$iconmoon-google: "\ea88";
$iconmoon-google2: "\ea89";
$iconmoon-google3: "\ea8a";
$iconmoon-google-plus: "\ea8b";
$iconmoon-google-plus2: "\ea8c";
$iconmoon-google-plus3: "\ea8d";
$iconmoon-hangouts: "\ea8e";
$iconmoon-google-drive: "\ea8f";
$iconmoon-facebook: "\ea90";
$iconmoon-facebook2: "\ea91";
$iconmoon-instagram: "\ea92";
$iconmoon-whatsapp: "\ea93";
$iconmoon-spotify: "\ea94";
$iconmoon-telegram: "\ea95";
$iconmoon-twitter: "\ea96";
$iconmoon-vine: "\ea97";
$iconmoon-vk: "\ea98";
$iconmoon-renren: "\ea99";
$iconmoon-sina-weibo: "\ea9a";
$iconmoon-rss: "\ea9b";
$iconmoon-rss2: "\ea9c";
$iconmoon-youtube: "\ea9d";
$iconmoon-youtube2: "\ea9e";
$iconmoon-twitch: "\ea9f";
$iconmoon-vimeo: "\eaa0";
$iconmoon-vimeo2: "\eaa1";
$iconmoon-lanyrd: "\eaa2";
$iconmoon-flickr: "\eaa3";
$iconmoon-flickr2: "\eaa4";
$iconmoon-flickr3: "\eaa5";
$iconmoon-flickr4: "\eaa6";
$iconmoon-dribbble: "\eaa7";
$iconmoon-behance: "\eaa8";
$iconmoon-behance2: "\eaa9";
$iconmoon-deviantart: "\eaaa";
$iconmoon-500px: "\eaab";
$iconmoon-steam: "\eaac";
$iconmoon-steam2: "\eaad";
$iconmoon-dropbox: "\eaae";
$iconmoon-onedrive: "\eaaf";
$iconmoon-github: "\eab0";
$iconmoon-npm: "\eab1";
$iconmoon-basecamp: "\eab2";
$iconmoon-trello: "\eab3";
$iconmoon-wordpress: "\eab4";
$iconmoon-joomla: "\eab5";
$iconmoon-ello: "\eab6";
$iconmoon-blogger: "\eab7";
$iconmoon-blogger2: "\eab8";
$iconmoon-tumblr: "\eab9";
$iconmoon-tumblr2: "\eaba";
$iconmoon-yahoo: "\eabb";
$iconmoon-yahoo2: "\eabc";
$iconmoon-tux: "\eabd";
$iconmoon-appleinc: "\eabe";
$iconmoon-finder: "\eabf";
$iconmoon-android: "\eac0";
$iconmoon-windows: "\eac1";
$iconmoon-windows8: "\eac2";
$iconmoon-soundcloud: "\eac3";
$iconmoon-soundcloud2: "\eac4";
$iconmoon-skype: "\eac5";
$iconmoon-reddit: "\eac6";
$iconmoon-hackernews: "\eac7";
$iconmoon-wikipedia: "\eac8";
$iconmoon-linkedin: "\eac9";
$iconmoon-linkedin2: "\eaca";
$iconmoon-lastfm: "\eacb";
$iconmoon-lastfm2: "\eacc";
$iconmoon-delicious: "\eacd";
$iconmoon-stumbleupon: "\eace";
$iconmoon-stumbleupon2: "\eacf";
$iconmoon-stackoverflow: "\ead0";
$iconmoon-pinterest: "\ead1";
$iconmoon-pinterest2: "\ead2";
$iconmoon-xing: "\ead3";
$iconmoon-xing2: "\ead4";
$iconmoon-flattr: "\ead5";
$iconmoon-foursquare: "\ead6";
$iconmoon-yelp: "\ead7";
$iconmoon-paypal: "\ead8";
$iconmoon-chrome: "\ead9";
$iconmoon-firefox: "\eada";
$iconmoon-IE: "\eadb";
$iconmoon-edge: "\eadc";
$iconmoon-safari: "\eadd";
$iconmoon-opera: "\eade";
$iconmoon-file-pdf: "\eadf";
$iconmoon-file-openoffice: "\eae0";
$iconmoon-file-word: "\eae1";
$iconmoon-file-excel: "\eae2";
$iconmoon-libreoffice: "\eae3";
$iconmoon-html-five: "\eae4";
$iconmoon-html-five2: "\eae5";
$iconmoon-css3: "\eae6";
$iconmoon-git: "\eae7";
$iconmoon-codepen: "\eae8";
$iconmoon-svg: "\eae9";
$iconmoon-IcoMoon: "\eaea";

.iconmoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconmoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconmoon-home {
  &:before {
    content: $iconmoon-home; 
  }
}
.iconmoon-home2 {
  &:before {
    content: $iconmoon-home2; 
  }
}
.iconmoon-home3 {
  &:before {
    content: $iconmoon-home3; 
  }
}
.iconmoon-office {
  &:before {
    content: $iconmoon-office; 
  }
}
.iconmoon-newspaper {
  &:before {
    content: $iconmoon-newspaper; 
  }
}
.iconmoon-pencil {
  &:before {
    content: $iconmoon-pencil; 
  }
}
.iconmoon-pencil2 {
  &:before {
    content: $iconmoon-pencil2; 
  }
}
.iconmoon-quill {
  &:before {
    content: $iconmoon-quill; 
  }
}
.iconmoon-pen {
  &:before {
    content: $iconmoon-pen; 
  }
}
.iconmoon-blog {
  &:before {
    content: $iconmoon-blog; 
  }
}
.iconmoon-eyedropper {
  &:before {
    content: $iconmoon-eyedropper; 
  }
}
.iconmoon-droplet {
  &:before {
    content: $iconmoon-droplet; 
  }
}
.iconmoon-paint-format {
  &:before {
    content: $iconmoon-paint-format; 
  }
}
.iconmoon-image {
  &:before {
    content: $iconmoon-image; 
  }
}
.iconmoon-images {
  &:before {
    content: $iconmoon-images; 
  }
}
.iconmoon-camera {
  &:before {
    content: $iconmoon-camera; 
  }
}
.iconmoon-headphones {
  &:before {
    content: $iconmoon-headphones; 
  }
}
.iconmoon-music {
  &:before {
    content: $iconmoon-music; 
  }
}
.iconmoon-play {
  &:before {
    content: $iconmoon-play; 
  }
}
.iconmoon-film {
  &:before {
    content: $iconmoon-film; 
  }
}
.iconmoon-video-camera {
  &:before {
    content: $iconmoon-video-camera; 
  }
}
.iconmoon-dice {
  &:before {
    content: $iconmoon-dice; 
  }
}
.iconmoon-pacman {
  &:before {
    content: $iconmoon-pacman; 
  }
}
.iconmoon-spades {
  &:before {
    content: $iconmoon-spades; 
  }
}
.iconmoon-clubs {
  &:before {
    content: $iconmoon-clubs; 
  }
}
.iconmoon-diamonds {
  &:before {
    content: $iconmoon-diamonds; 
  }
}
.iconmoon-bullhorn {
  &:before {
    content: $iconmoon-bullhorn; 
  }
}
.iconmoon-connection {
  &:before {
    content: $iconmoon-connection; 
  }
}
.iconmoon-podcast {
  &:before {
    content: $iconmoon-podcast; 
  }
}
.iconmoon-feed {
  &:before {
    content: $iconmoon-feed; 
  }
}
.iconmoon-mic {
  &:before {
    content: $iconmoon-mic; 
  }
}
.iconmoon-book {
  &:before {
    content: $iconmoon-book; 
  }
}
.iconmoon-books {
  &:before {
    content: $iconmoon-books; 
  }
}
.iconmoon-library {
  &:before {
    content: $iconmoon-library; 
  }
}
.iconmoon-file-text {
  &:before {
    content: $iconmoon-file-text; 
  }
}
.iconmoon-profile {
  &:before {
    content: $iconmoon-profile; 
  }
}
.iconmoon-file-empty {
  &:before {
    content: $iconmoon-file-empty; 
  }
}
.iconmoon-files-empty {
  &:before {
    content: $iconmoon-files-empty; 
  }
}
.iconmoon-file-text2 {
  &:before {
    content: $iconmoon-file-text2; 
  }
}
.iconmoon-file-picture {
  &:before {
    content: $iconmoon-file-picture; 
  }
}
.iconmoon-file-music {
  &:before {
    content: $iconmoon-file-music; 
  }
}
.iconmoon-file-play {
  &:before {
    content: $iconmoon-file-play; 
  }
}
.iconmoon-file-video {
  &:before {
    content: $iconmoon-file-video; 
  }
}
.iconmoon-file-zip {
  &:before {
    content: $iconmoon-file-zip; 
  }
}
.iconmoon-copy {
  &:before {
    content: $iconmoon-copy; 
  }
}
.iconmoon-paste {
  &:before {
    content: $iconmoon-paste; 
  }
}
.iconmoon-stack {
  &:before {
    content: $iconmoon-stack; 
  }
}
.iconmoon-folder {
  &:before {
    content: $iconmoon-folder; 
  }
}
.iconmoon-folder-open {
  &:before {
    content: $iconmoon-folder-open; 
  }
}
.iconmoon-folder-plus {
  &:before {
    content: $iconmoon-folder-plus; 
  }
}
.iconmoon-folder-minus {
  &:before {
    content: $iconmoon-folder-minus; 
  }
}
.iconmoon-folder-download {
  &:before {
    content: $iconmoon-folder-download; 
  }
}
.iconmoon-folder-upload {
  &:before {
    content: $iconmoon-folder-upload; 
  }
}
.iconmoon-price-tag {
  &:before {
    content: $iconmoon-price-tag; 
  }
}
.iconmoon-price-tags {
  &:before {
    content: $iconmoon-price-tags; 
  }
}
.iconmoon-barcode {
  &:before {
    content: $iconmoon-barcode; 
  }
}
.iconmoon-qrcode {
  &:before {
    content: $iconmoon-qrcode; 
  }
}
.iconmoon-ticket {
  &:before {
    content: $iconmoon-ticket; 
  }
}
.iconmoon-cart {
  &:before {
    content: $iconmoon-cart; 
  }
}
.iconmoon-coin-dollar {
  &:before {
    content: $iconmoon-coin-dollar; 
  }
}
.iconmoon-coin-euro {
  &:before {
    content: $iconmoon-coin-euro; 
  }
}
.iconmoon-coin-pound {
  &:before {
    content: $iconmoon-coin-pound; 
  }
}
.iconmoon-coin-yen {
  &:before {
    content: $iconmoon-coin-yen; 
  }
}
.iconmoon-credit-card {
  &:before {
    content: $iconmoon-credit-card; 
  }
}
.iconmoon-calculator {
  &:before {
    content: $iconmoon-calculator; 
  }
}
.iconmoon-lifebuoy {
  &:before {
    content: $iconmoon-lifebuoy; 
  }
}
.iconmoon-phone {
  &:before {
    content: $iconmoon-phone; 
  }
}
.iconmoon-phone-hang-up {
  &:before {
    content: $iconmoon-phone-hang-up; 
  }
}
.iconmoon-address-book {
  &:before {
    content: $iconmoon-address-book; 
  }
}
.iconmoon-envelop {
  &:before {
    content: $iconmoon-envelop; 
  }
}
.iconmoon-pushpin {
  &:before {
    content: $iconmoon-pushpin; 
  }
}
.iconmoon-location {
  &:before {
    content: $iconmoon-location; 
  }
}
.iconmoon-location2 {
  &:before {
    content: $iconmoon-location2; 
  }
}
.iconmoon-compass {
  &:before {
    content: $iconmoon-compass; 
  }
}
.iconmoon-compass2 {
  &:before {
    content: $iconmoon-compass2; 
  }
}
.iconmoon-map {
  &:before {
    content: $iconmoon-map; 
  }
}
.iconmoon-map2 {
  &:before {
    content: $iconmoon-map2; 
  }
}
.iconmoon-history {
  &:before {
    content: $iconmoon-history; 
  }
}
.iconmoon-clock {
  &:before {
    content: $iconmoon-clock; 
  }
}
.iconmoon-clock2 {
  &:before {
    content: $iconmoon-clock2; 
  }
}
.iconmoon-alarm {
  &:before {
    content: $iconmoon-alarm; 
  }
}
.iconmoon-bell {
  &:before {
    content: $iconmoon-bell; 
  }
}
.iconmoon-stopwatch {
  &:before {
    content: $iconmoon-stopwatch; 
  }
}
.iconmoon-calendar {
  &:before {
    content: $iconmoon-calendar; 
  }
}
.iconmoon-printer {
  &:before {
    content: $iconmoon-printer; 
  }
}
.iconmoon-keyboard {
  &:before {
    content: $iconmoon-keyboard; 
  }
}
.iconmoon-display {
  &:before {
    content: $iconmoon-display; 
  }
}
.iconmoon-laptop {
  &:before {
    content: $iconmoon-laptop; 
  }
}
.iconmoon-mobile {
  &:before {
    content: $iconmoon-mobile; 
  }
}
.iconmoon-mobile2 {
  &:before {
    content: $iconmoon-mobile2; 
  }
}
.iconmoon-tablet {
  &:before {
    content: $iconmoon-tablet; 
  }
}
.iconmoon-tv {
  &:before {
    content: $iconmoon-tv; 
  }
}
.iconmoon-drawer {
  &:before {
    content: $iconmoon-drawer; 
  }
}
.iconmoon-drawer2 {
  &:before {
    content: $iconmoon-drawer2; 
  }
}
.iconmoon-box-add {
  &:before {
    content: $iconmoon-box-add; 
  }
}
.iconmoon-box-remove {
  &:before {
    content: $iconmoon-box-remove; 
  }
}
.iconmoon-download {
  &:before {
    content: $iconmoon-download; 
  }
}
.iconmoon-upload {
  &:before {
    content: $iconmoon-upload; 
  }
}
.iconmoon-floppy-disk {
  &:before {
    content: $iconmoon-floppy-disk; 
  }
}
.iconmoon-drive {
  &:before {
    content: $iconmoon-drive; 
  }
}
.iconmoon-database {
  &:before {
    content: $iconmoon-database; 
  }
}
.iconmoon-undo {
  &:before {
    content: $iconmoon-undo; 
  }
}
.iconmoon-redo {
  &:before {
    content: $iconmoon-redo; 
  }
}
.iconmoon-undo2 {
  &:before {
    content: $iconmoon-undo2; 
  }
}
.iconmoon-redo2 {
  &:before {
    content: $iconmoon-redo2; 
  }
}
.iconmoon-forward {
  &:before {
    content: $iconmoon-forward; 
  }
}
.iconmoon-reply {
  &:before {
    content: $iconmoon-reply; 
  }
}
.iconmoon-bubble {
  &:before {
    content: $iconmoon-bubble; 
  }
}
.iconmoon-bubbles {
  &:before {
    content: $iconmoon-bubbles; 
  }
}
.iconmoon-bubbles2 {
  &:before {
    content: $iconmoon-bubbles2; 
  }
}
.iconmoon-bubble2 {
  &:before {
    content: $iconmoon-bubble2; 
  }
}
.iconmoon-bubbles3 {
  &:before {
    content: $iconmoon-bubbles3; 
  }
}
.iconmoon-bubbles4 {
  &:before {
    content: $iconmoon-bubbles4; 
  }
}
.iconmoon-user {
  &:before {
    content: $iconmoon-user; 
  }
}
.iconmoon-users {
  &:before {
    content: $iconmoon-users; 
  }
}
.iconmoon-user-plus {
  &:before {
    content: $iconmoon-user-plus; 
  }
}
.iconmoon-user-minus {
  &:before {
    content: $iconmoon-user-minus; 
  }
}
.iconmoon-user-check {
  &:before {
    content: $iconmoon-user-check; 
  }
}
.iconmoon-user-tie {
  &:before {
    content: $iconmoon-user-tie; 
  }
}
.iconmoon-quotes-left {
  &:before {
    content: $iconmoon-quotes-left; 
  }
}
.iconmoon-quotes-right {
  &:before {
    content: $iconmoon-quotes-right; 
  }
}
.iconmoon-hour-glass {
  &:before {
    content: $iconmoon-hour-glass; 
  }
}
.iconmoon-spinner {
  &:before {
    content: $iconmoon-spinner; 
  }
}
.iconmoon-spinner2 {
  &:before {
    content: $iconmoon-spinner2; 
  }
}
.iconmoon-spinner3 {
  &:before {
    content: $iconmoon-spinner3; 
  }
}
.iconmoon-spinner4 {
  &:before {
    content: $iconmoon-spinner4; 
  }
}
.iconmoon-spinner5 {
  &:before {
    content: $iconmoon-spinner5; 
  }
}
.iconmoon-spinner6 {
  &:before {
    content: $iconmoon-spinner6; 
  }
}
.iconmoon-spinner7 {
  &:before {
    content: $iconmoon-spinner7; 
  }
}
.iconmoon-spinner8 {
  &:before {
    content: $iconmoon-spinner8; 
  }
}
.iconmoon-spinner9 {
  &:before {
    content: $iconmoon-spinner9; 
  }
}
.iconmoon-spinner10 {
  &:before {
    content: $iconmoon-spinner10; 
  }
}
.iconmoon-spinner11 {
  &:before {
    content: $iconmoon-spinner11; 
  }
}
.iconmoon-binoculars {
  &:before {
    content: $iconmoon-binoculars; 
  }
}
.iconmoon-search {
  &:before {
    content: $iconmoon-search; 
  }
}
.iconmoon-zoom-in {
  &:before {
    content: $iconmoon-zoom-in; 
  }
}
.iconmoon-zoom-out {
  &:before {
    content: $iconmoon-zoom-out; 
  }
}
.iconmoon-enlarge {
  &:before {
    content: $iconmoon-enlarge; 
  }
}
.iconmoon-shrink {
  &:before {
    content: $iconmoon-shrink; 
  }
}
.iconmoon-enlarge2 {
  &:before {
    content: $iconmoon-enlarge2; 
  }
}
.iconmoon-shrink2 {
  &:before {
    content: $iconmoon-shrink2; 
  }
}
.iconmoon-key {
  &:before {
    content: $iconmoon-key; 
  }
}
.iconmoon-key2 {
  &:before {
    content: $iconmoon-key2; 
  }
}
.iconmoon-lock {
  &:before {
    content: $iconmoon-lock; 
  }
}
.iconmoon-unlocked {
  &:before {
    content: $iconmoon-unlocked; 
  }
}
.iconmoon-wrench {
  &:before {
    content: $iconmoon-wrench; 
  }
}
.iconmoon-equalizer {
  &:before {
    content: $iconmoon-equalizer; 
  }
}
.iconmoon-equalizer2 {
  &:before {
    content: $iconmoon-equalizer2; 
  }
}
.iconmoon-cog {
  &:before {
    content: $iconmoon-cog; 
  }
}
.iconmoon-cogs {
  &:before {
    content: $iconmoon-cogs; 
  }
}
.iconmoon-hammer {
  &:before {
    content: $iconmoon-hammer; 
  }
}
.iconmoon-magic-wand {
  &:before {
    content: $iconmoon-magic-wand; 
  }
}
.iconmoon-aid-kit {
  &:before {
    content: $iconmoon-aid-kit; 
  }
}
.iconmoon-bug {
  &:before {
    content: $iconmoon-bug; 
  }
}
.iconmoon-pie-chart {
  &:before {
    content: $iconmoon-pie-chart; 
  }
}
.iconmoon-stats-dots {
  &:before {
    content: $iconmoon-stats-dots; 
  }
}
.iconmoon-stats-bars {
  &:before {
    content: $iconmoon-stats-bars; 
  }
}
.iconmoon-stats-bars2 {
  &:before {
    content: $iconmoon-stats-bars2; 
  }
}
.iconmoon-trophy {
  &:before {
    content: $iconmoon-trophy; 
  }
}
.iconmoon-gift {
  &:before {
    content: $iconmoon-gift; 
  }
}
.iconmoon-glass {
  &:before {
    content: $iconmoon-glass; 
  }
}
.iconmoon-glass2 {
  &:before {
    content: $iconmoon-glass2; 
  }
}
.iconmoon-mug {
  &:before {
    content: $iconmoon-mug; 
  }
}
.iconmoon-spoon-knife {
  &:before {
    content: $iconmoon-spoon-knife; 
  }
}
.iconmoon-leaf {
  &:before {
    content: $iconmoon-leaf; 
  }
}
.iconmoon-rocket {
  &:before {
    content: $iconmoon-rocket; 
  }
}
.iconmoon-meter {
  &:before {
    content: $iconmoon-meter; 
  }
}
.iconmoon-meter2 {
  &:before {
    content: $iconmoon-meter2; 
  }
}
.iconmoon-hammer2 {
  &:before {
    content: $iconmoon-hammer2; 
  }
}
.iconmoon-fire {
  &:before {
    content: $iconmoon-fire; 
  }
}
.iconmoon-lab {
  &:before {
    content: $iconmoon-lab; 
  }
}
.iconmoon-magnet {
  &:before {
    content: $iconmoon-magnet; 
  }
}
.iconmoon-bin {
  &:before {
    content: $iconmoon-bin; 
  }
}
.iconmoon-bin2 {
  &:before {
    content: $iconmoon-bin2; 
  }
}
.iconmoon-briefcase {
  &:before {
    content: $iconmoon-briefcase; 
  }
}
.iconmoon-airplane {
  &:before {
    content: $iconmoon-airplane; 
  }
}
.iconmoon-truck {
  &:before {
    content: $iconmoon-truck; 
  }
}
.iconmoon-road {
  &:before {
    content: $iconmoon-road; 
  }
}
.iconmoon-accessibility {
  &:before {
    content: $iconmoon-accessibility; 
  }
}
.iconmoon-target {
  &:before {
    content: $iconmoon-target; 
  }
}
.iconmoon-shield {
  &:before {
    content: $iconmoon-shield; 
  }
}
.iconmoon-power {
  &:before {
    content: $iconmoon-power; 
  }
}
.iconmoon-switch {
  &:before {
    content: $iconmoon-switch; 
  }
}
.iconmoon-power-cord {
  &:before {
    content: $iconmoon-power-cord; 
  }
}
.iconmoon-clipboard {
  &:before {
    content: $iconmoon-clipboard; 
  }
}
.iconmoon-list-numbered {
  &:before {
    content: $iconmoon-list-numbered; 
  }
}
.iconmoon-list {
  &:before {
    content: $iconmoon-list; 
  }
}
.iconmoon-list2 {
  &:before {
    content: $iconmoon-list2; 
  }
}
.iconmoon-tree {
  &:before {
    content: $iconmoon-tree; 
  }
}
.iconmoon-menu {
  &:before {
    content: $iconmoon-menu; 
  }
}
.iconmoon-menu2 {
  &:before {
    content: $iconmoon-menu2; 
  }
}
.iconmoon-menu3 {
  &:before {
    content: $iconmoon-menu3; 
  }
}
.iconmoon-menu4 {
  &:before {
    content: $iconmoon-menu4; 
  }
}
.iconmoon-cloud {
  &:before {
    content: $iconmoon-cloud; 
  }
}
.iconmoon-cloud-download {
  &:before {
    content: $iconmoon-cloud-download; 
  }
}
.iconmoon-cloud-upload {
  &:before {
    content: $iconmoon-cloud-upload; 
  }
}
.iconmoon-cloud-check {
  &:before {
    content: $iconmoon-cloud-check; 
  }
}
.iconmoon-download2 {
  &:before {
    content: $iconmoon-download2; 
  }
}
.iconmoon-upload2 {
  &:before {
    content: $iconmoon-upload2; 
  }
}
.iconmoon-download3 {
  &:before {
    content: $iconmoon-download3; 
  }
}
.iconmoon-upload3 {
  &:before {
    content: $iconmoon-upload3; 
  }
}
.iconmoon-sphere {
  &:before {
    content: $iconmoon-sphere; 
  }
}
.iconmoon-earth {
  &:before {
    content: $iconmoon-earth; 
  }
}
.iconmoon-link {
  &:before {
    content: $iconmoon-link; 
  }
}
.iconmoon-flag {
  &:before {
    content: $iconmoon-flag; 
  }
}
.iconmoon-attachment {
  &:before {
    content: $iconmoon-attachment; 
  }
}
.iconmoon-eye {
  &:before {
    content: $iconmoon-eye; 
  }
}
.iconmoon-eye-plus {
  &:before {
    content: $iconmoon-eye-plus; 
  }
}
.iconmoon-eye-minus {
  &:before {
    content: $iconmoon-eye-minus; 
  }
}
.iconmoon-eye-blocked {
  &:before {
    content: $iconmoon-eye-blocked; 
  }
}
.iconmoon-bookmark {
  &:before {
    content: $iconmoon-bookmark; 
  }
}
.iconmoon-bookmarks {
  &:before {
    content: $iconmoon-bookmarks; 
  }
}
.iconmoon-sun {
  &:before {
    content: $iconmoon-sun; 
  }
}
.iconmoon-contrast {
  &:before {
    content: $iconmoon-contrast; 
  }
}
.iconmoon-brightness-contrast {
  &:before {
    content: $iconmoon-brightness-contrast; 
  }
}
.iconmoon-star-empty {
  &:before {
    content: $iconmoon-star-empty; 
  }
}
.iconmoon-star-half {
  &:before {
    content: $iconmoon-star-half; 
  }
}
.iconmoon-star-full {
  &:before {
    content: $iconmoon-star-full; 
  }
}
.iconmoon-heart {
  &:before {
    content: $iconmoon-heart; 
  }
}
.iconmoon-heart-broken {
  &:before {
    content: $iconmoon-heart-broken; 
  }
}
.iconmoon-man {
  &:before {
    content: $iconmoon-man; 
  }
}
.iconmoon-woman {
  &:before {
    content: $iconmoon-woman; 
  }
}
.iconmoon-man-woman {
  &:before {
    content: $iconmoon-man-woman; 
  }
}
.iconmoon-happy {
  &:before {
    content: $iconmoon-happy; 
  }
}
.iconmoon-happy2 {
  &:before {
    content: $iconmoon-happy2; 
  }
}
.iconmoon-smile {
  &:before {
    content: $iconmoon-smile; 
  }
}
.iconmoon-smile2 {
  &:before {
    content: $iconmoon-smile2; 
  }
}
.iconmoon-tongue {
  &:before {
    content: $iconmoon-tongue; 
  }
}
.iconmoon-tongue2 {
  &:before {
    content: $iconmoon-tongue2; 
  }
}
.iconmoon-sad {
  &:before {
    content: $iconmoon-sad; 
  }
}
.iconmoon-sad2 {
  &:before {
    content: $iconmoon-sad2; 
  }
}
.iconmoon-wink {
  &:before {
    content: $iconmoon-wink; 
  }
}
.iconmoon-wink2 {
  &:before {
    content: $iconmoon-wink2; 
  }
}
.iconmoon-grin {
  &:before {
    content: $iconmoon-grin; 
  }
}
.iconmoon-grin2 {
  &:before {
    content: $iconmoon-grin2; 
  }
}
.iconmoon-cool {
  &:before {
    content: $iconmoon-cool; 
  }
}
.iconmoon-cool2 {
  &:before {
    content: $iconmoon-cool2; 
  }
}
.iconmoon-angry {
  &:before {
    content: $iconmoon-angry; 
  }
}
.iconmoon-angry2 {
  &:before {
    content: $iconmoon-angry2; 
  }
}
.iconmoon-evil {
  &:before {
    content: $iconmoon-evil; 
  }
}
.iconmoon-evil2 {
  &:before {
    content: $iconmoon-evil2; 
  }
}
.iconmoon-shocked {
  &:before {
    content: $iconmoon-shocked; 
  }
}
.iconmoon-shocked2 {
  &:before {
    content: $iconmoon-shocked2; 
  }
}
.iconmoon-baffled {
  &:before {
    content: $iconmoon-baffled; 
  }
}
.iconmoon-baffled2 {
  &:before {
    content: $iconmoon-baffled2; 
  }
}
.iconmoon-confused {
  &:before {
    content: $iconmoon-confused; 
  }
}
.iconmoon-confused2 {
  &:before {
    content: $iconmoon-confused2; 
  }
}
.iconmoon-neutral {
  &:before {
    content: $iconmoon-neutral; 
  }
}
.iconmoon-neutral2 {
  &:before {
    content: $iconmoon-neutral2; 
  }
}
.iconmoon-hipster {
  &:before {
    content: $iconmoon-hipster; 
  }
}
.iconmoon-hipster2 {
  &:before {
    content: $iconmoon-hipster2; 
  }
}
.iconmoon-wondering {
  &:before {
    content: $iconmoon-wondering; 
  }
}
.iconmoon-wondering2 {
  &:before {
    content: $iconmoon-wondering2; 
  }
}
.iconmoon-sleepy {
  &:before {
    content: $iconmoon-sleepy; 
  }
}
.iconmoon-sleepy2 {
  &:before {
    content: $iconmoon-sleepy2; 
  }
}
.iconmoon-frustrated {
  &:before {
    content: $iconmoon-frustrated; 
  }
}
.iconmoon-frustrated2 {
  &:before {
    content: $iconmoon-frustrated2; 
  }
}
.iconmoon-crying {
  &:before {
    content: $iconmoon-crying; 
  }
}
.iconmoon-crying2 {
  &:before {
    content: $iconmoon-crying2; 
  }
}
.iconmoon-point-up {
  &:before {
    content: $iconmoon-point-up; 
  }
}
.iconmoon-point-right {
  &:before {
    content: $iconmoon-point-right; 
  }
}
.iconmoon-point-down {
  &:before {
    content: $iconmoon-point-down; 
  }
}
.iconmoon-point-left {
  &:before {
    content: $iconmoon-point-left; 
  }
}
.iconmoon-warning {
  &:before {
    content: $iconmoon-warning; 
  }
}
.iconmoon-notification {
  &:before {
    content: $iconmoon-notification; 
  }
}
.iconmoon-question {
  &:before {
    content: $iconmoon-question; 
  }
}
.iconmoon-plus {
  &:before {
    content: $iconmoon-plus; 
  }
}
.iconmoon-minus {
  &:before {
    content: $iconmoon-minus; 
  }
}
.iconmoon-info {
  &:before {
    content: $iconmoon-info; 
  }
}
.iconmoon-cancel-circle {
  &:before {
    content: $iconmoon-cancel-circle; 
  }
}
.iconmoon-blocked {
  &:before {
    content: $iconmoon-blocked; 
  }
}
.iconmoon-cross {
  &:before {
    content: $iconmoon-cross; 
  }
}
.iconmoon-checkmark {
  &:before {
    content: $iconmoon-checkmark; 
  }
}
.iconmoon-checkmark2 {
  &:before {
    content: $iconmoon-checkmark2; 
  }
}
.iconmoon-spell-check {
  &:before {
    content: $iconmoon-spell-check; 
  }
}
.iconmoon-enter {
  &:before {
    content: $iconmoon-enter; 
  }
}
.iconmoon-exit {
  &:before {
    content: $iconmoon-exit; 
  }
}
.iconmoon-play2 {
  &:before {
    content: $iconmoon-play2; 
  }
}
.iconmoon-pause {
  &:before {
    content: $iconmoon-pause; 
  }
}
.iconmoon-stop {
  &:before {
    content: $iconmoon-stop; 
  }
}
.iconmoon-previous {
  &:before {
    content: $iconmoon-previous; 
  }
}
.iconmoon-next {
  &:before {
    content: $iconmoon-next; 
  }
}
.iconmoon-backward {
  &:before {
    content: $iconmoon-backward; 
  }
}
.iconmoon-forward2 {
  &:before {
    content: $iconmoon-forward2; 
  }
}
.iconmoon-play3 {
  &:before {
    content: $iconmoon-play3; 
  }
}
.iconmoon-pause2 {
  &:before {
    content: $iconmoon-pause2; 
  }
}
.iconmoon-stop2 {
  &:before {
    content: $iconmoon-stop2; 
  }
}
.iconmoon-backward2 {
  &:before {
    content: $iconmoon-backward2; 
  }
}
.iconmoon-forward3 {
  &:before {
    content: $iconmoon-forward3; 
  }
}
.iconmoon-first {
  &:before {
    content: $iconmoon-first; 
  }
}
.iconmoon-last {
  &:before {
    content: $iconmoon-last; 
  }
}
.iconmoon-previous2 {
  &:before {
    content: $iconmoon-previous2; 
  }
}
.iconmoon-next2 {
  &:before {
    content: $iconmoon-next2; 
  }
}
.iconmoon-eject {
  &:before {
    content: $iconmoon-eject; 
  }
}
.iconmoon-volume-high {
  &:before {
    content: $iconmoon-volume-high; 
  }
}
.iconmoon-volume-medium {
  &:before {
    content: $iconmoon-volume-medium; 
  }
}
.iconmoon-volume-low {
  &:before {
    content: $iconmoon-volume-low; 
  }
}
.iconmoon-volume-mute {
  &:before {
    content: $iconmoon-volume-mute; 
  }
}
.iconmoon-volume-mute2 {
  &:before {
    content: $iconmoon-volume-mute2; 
  }
}
.iconmoon-volume-increase {
  &:before {
    content: $iconmoon-volume-increase; 
  }
}
.iconmoon-volume-decrease {
  &:before {
    content: $iconmoon-volume-decrease; 
  }
}
.iconmoon-loop {
  &:before {
    content: $iconmoon-loop; 
  }
}
.iconmoon-loop2 {
  &:before {
    content: $iconmoon-loop2; 
  }
}
.iconmoon-infinite {
  &:before {
    content: $iconmoon-infinite; 
  }
}
.iconmoon-shuffle {
  &:before {
    content: $iconmoon-shuffle; 
  }
}
.iconmoon-arrow-up-left {
  &:before {
    content: $iconmoon-arrow-up-left; 
  }
}
.iconmoon-arrow-up {
  &:before {
    content: $iconmoon-arrow-up; 
  }
}
.iconmoon-arrow-up-right {
  &:before {
    content: $iconmoon-arrow-up-right; 
  }
}
.iconmoon-arrow-right {
  &:before {
    content: $iconmoon-arrow-right; 
  }
}
.iconmoon-arrow-down-right {
  &:before {
    content: $iconmoon-arrow-down-right; 
  }
}
.iconmoon-arrow-down {
  &:before {
    content: $iconmoon-arrow-down; 
  }
}
.iconmoon-arrow-down-left {
  &:before {
    content: $iconmoon-arrow-down-left; 
  }
}
.iconmoon-arrow-left {
  &:before {
    content: $iconmoon-arrow-left; 
  }
}
.iconmoon-arrow-up-left2 {
  &:before {
    content: $iconmoon-arrow-up-left2; 
  }
}
.iconmoon-arrow-up2 {
  &:before {
    content: $iconmoon-arrow-up2; 
  }
}
.iconmoon-arrow-up-right2 {
  &:before {
    content: $iconmoon-arrow-up-right2; 
  }
}
.iconmoon-arrow-right2 {
  &:before {
    content: $iconmoon-arrow-right2; 
  }
}
.iconmoon-arrow-down-right2 {
  &:before {
    content: $iconmoon-arrow-down-right2; 
  }
}
.iconmoon-arrow-down2 {
  &:before {
    content: $iconmoon-arrow-down2; 
  }
}
.iconmoon-arrow-down-left2 {
  &:before {
    content: $iconmoon-arrow-down-left2; 
  }
}
.iconmoon-arrow-left2 {
  &:before {
    content: $iconmoon-arrow-left2; 
  }
}
.iconmoon-circle-up {
  &:before {
    content: $iconmoon-circle-up; 
  }
}
.iconmoon-circle-right {
  &:before {
    content: $iconmoon-circle-right; 
  }
}
.iconmoon-circle-down {
  &:before {
    content: $iconmoon-circle-down; 
  }
}
.iconmoon-circle-left {
  &:before {
    content: $iconmoon-circle-left; 
  }
}
.iconmoon-tab {
  &:before {
    content: $iconmoon-tab; 
  }
}
.iconmoon-move-up {
  &:before {
    content: $iconmoon-move-up; 
  }
}
.iconmoon-move-down {
  &:before {
    content: $iconmoon-move-down; 
  }
}
.iconmoon-sort-alpha-asc {
  &:before {
    content: $iconmoon-sort-alpha-asc; 
  }
}
.iconmoon-sort-alpha-desc {
  &:before {
    content: $iconmoon-sort-alpha-desc; 
  }
}
.iconmoon-sort-numeric-asc {
  &:before {
    content: $iconmoon-sort-numeric-asc; 
  }
}
.iconmoon-sort-numberic-desc {
  &:before {
    content: $iconmoon-sort-numberic-desc; 
  }
}
.iconmoon-sort-amount-asc {
  &:before {
    content: $iconmoon-sort-amount-asc; 
  }
}
.iconmoon-sort-amount-desc {
  &:before {
    content: $iconmoon-sort-amount-desc; 
  }
}
.iconmoon-command {
  &:before {
    content: $iconmoon-command; 
  }
}
.iconmoon-shift {
  &:before {
    content: $iconmoon-shift; 
  }
}
.iconmoon-ctrl {
  &:before {
    content: $iconmoon-ctrl; 
  }
}
.iconmoon-opt {
  &:before {
    content: $iconmoon-opt; 
  }
}
.iconmoon-checkbox-checked {
  &:before {
    content: $iconmoon-checkbox-checked; 
  }
}
.iconmoon-checkbox-unchecked {
  &:before {
    content: $iconmoon-checkbox-unchecked; 
  }
}
.iconmoon-radio-checked {
  &:before {
    content: $iconmoon-radio-checked; 
  }
}
.iconmoon-radio-checked2 {
  &:before {
    content: $iconmoon-radio-checked2; 
  }
}
.iconmoon-radio-unchecked {
  &:before {
    content: $iconmoon-radio-unchecked; 
  }
}
.iconmoon-crop {
  &:before {
    content: $iconmoon-crop; 
  }
}
.iconmoon-make-group {
  &:before {
    content: $iconmoon-make-group; 
  }
}
.iconmoon-ungroup {
  &:before {
    content: $iconmoon-ungroup; 
  }
}
.iconmoon-scissors {
  &:before {
    content: $iconmoon-scissors; 
  }
}
.iconmoon-filter {
  &:before {
    content: $iconmoon-filter; 
  }
}
.iconmoon-font {
  &:before {
    content: $iconmoon-font; 
  }
}
.iconmoon-ligature {
  &:before {
    content: $iconmoon-ligature; 
  }
}
.iconmoon-ligature2 {
  &:before {
    content: $iconmoon-ligature2; 
  }
}
.iconmoon-text-height {
  &:before {
    content: $iconmoon-text-height; 
  }
}
.iconmoon-text-width {
  &:before {
    content: $iconmoon-text-width; 
  }
}
.iconmoon-font-size {
  &:before {
    content: $iconmoon-font-size; 
  }
}
.iconmoon-bold {
  &:before {
    content: $iconmoon-bold; 
  }
}
.iconmoon-underline {
  &:before {
    content: $iconmoon-underline; 
  }
}
.iconmoon-italic {
  &:before {
    content: $iconmoon-italic; 
  }
}
.iconmoon-strikethrough {
  &:before {
    content: $iconmoon-strikethrough; 
  }
}
.iconmoon-omega {
  &:before {
    content: $iconmoon-omega; 
  }
}
.iconmoon-sigma {
  &:before {
    content: $iconmoon-sigma; 
  }
}
.iconmoon-page-break {
  &:before {
    content: $iconmoon-page-break; 
  }
}
.iconmoon-superscript {
  &:before {
    content: $iconmoon-superscript; 
  }
}
.iconmoon-subscript {
  &:before {
    content: $iconmoon-subscript; 
  }
}
.iconmoon-superscript2 {
  &:before {
    content: $iconmoon-superscript2; 
  }
}
.iconmoon-subscript2 {
  &:before {
    content: $iconmoon-subscript2; 
  }
}
.iconmoon-text-color {
  &:before {
    content: $iconmoon-text-color; 
  }
}
.iconmoon-pagebreak {
  &:before {
    content: $iconmoon-pagebreak; 
  }
}
.iconmoon-clear-formatting {
  &:before {
    content: $iconmoon-clear-formatting; 
  }
}
.iconmoon-table {
  &:before {
    content: $iconmoon-table; 
  }
}
.iconmoon-table2 {
  &:before {
    content: $iconmoon-table2; 
  }
}
.iconmoon-insert-template {
  &:before {
    content: $iconmoon-insert-template; 
  }
}
.iconmoon-pilcrow {
  &:before {
    content: $iconmoon-pilcrow; 
  }
}
.iconmoon-ltr {
  &:before {
    content: $iconmoon-ltr; 
  }
}
.iconmoon-rtl {
  &:before {
    content: $iconmoon-rtl; 
  }
}
.iconmoon-section {
  &:before {
    content: $iconmoon-section; 
  }
}
.iconmoon-paragraph-left {
  &:before {
    content: $iconmoon-paragraph-left; 
  }
}
.iconmoon-paragraph-center {
  &:before {
    content: $iconmoon-paragraph-center; 
  }
}
.iconmoon-paragraph-right {
  &:before {
    content: $iconmoon-paragraph-right; 
  }
}
.iconmoon-paragraph-justify {
  &:before {
    content: $iconmoon-paragraph-justify; 
  }
}
.iconmoon-indent-increase {
  &:before {
    content: $iconmoon-indent-increase; 
  }
}
.iconmoon-indent-decrease {
  &:before {
    content: $iconmoon-indent-decrease; 
  }
}
.iconmoon-share {
  &:before {
    content: $iconmoon-share; 
  }
}
.iconmoon-new-tab {
  &:before {
    content: $iconmoon-new-tab; 
  }
}
.iconmoon-embed {
  &:before {
    content: $iconmoon-embed; 
  }
}
.iconmoon-embed2 {
  &:before {
    content: $iconmoon-embed2; 
  }
}
.iconmoon-terminal {
  &:before {
    content: $iconmoon-terminal; 
  }
}
.iconmoon-share2 {
  &:before {
    content: $iconmoon-share2; 
  }
}
.iconmoon-mail {
  &:before {
    content: $iconmoon-mail; 
  }
}
.iconmoon-mail2 {
  &:before {
    content: $iconmoon-mail2; 
  }
}
.iconmoon-mail3 {
  &:before {
    content: $iconmoon-mail3; 
  }
}
.iconmoon-mail4 {
  &:before {
    content: $iconmoon-mail4; 
  }
}
.iconmoon-amazon {
  &:before {
    content: $iconmoon-amazon; 
  }
}
.iconmoon-google {
  &:before {
    content: $iconmoon-google; 
  }
}
.iconmoon-google2 {
  &:before {
    content: $iconmoon-google2; 
  }
}
.iconmoon-google3 {
  &:before {
    content: $iconmoon-google3; 
  }
}
.iconmoon-google-plus {
  &:before {
    content: $iconmoon-google-plus; 
  }
}
.iconmoon-google-plus2 {
  &:before {
    content: $iconmoon-google-plus2; 
  }
}
.iconmoon-google-plus3 {
  &:before {
    content: $iconmoon-google-plus3; 
  }
}
.iconmoon-hangouts {
  &:before {
    content: $iconmoon-hangouts; 
  }
}
.iconmoon-google-drive {
  &:before {
    content: $iconmoon-google-drive; 
  }
}
.iconmoon-facebook {
  &:before {
    content: $iconmoon-facebook; 
  }
}
.iconmoon-facebook2 {
  &:before {
    content: $iconmoon-facebook2; 
  }
}
.iconmoon-instagram {
  &:before {
    content: $iconmoon-instagram; 
  }
}
.iconmoon-whatsapp {
  &:before {
    content: $iconmoon-whatsapp; 
  }
}
.iconmoon-spotify {
  &:before {
    content: $iconmoon-spotify; 
  }
}
.iconmoon-telegram {
  &:before {
    content: $iconmoon-telegram; 
  }
}
.iconmoon-twitter {
  &:before {
    content: $iconmoon-twitter; 
  }
}
.iconmoon-vine {
  &:before {
    content: $iconmoon-vine; 
  }
}
.iconmoon-vk {
  &:before {
    content: $iconmoon-vk; 
  }
}
.iconmoon-renren {
  &:before {
    content: $iconmoon-renren; 
  }
}
.iconmoon-sina-weibo {
  &:before {
    content: $iconmoon-sina-weibo; 
  }
}
.iconmoon-rss {
  &:before {
    content: $iconmoon-rss; 
  }
}
.iconmoon-rss2 {
  &:before {
    content: $iconmoon-rss2; 
  }
}
.iconmoon-youtube {
  &:before {
    content: $iconmoon-youtube; 
  }
}
.iconmoon-youtube2 {
  &:before {
    content: $iconmoon-youtube2; 
  }
}
.iconmoon-twitch {
  &:before {
    content: $iconmoon-twitch; 
  }
}
.iconmoon-vimeo {
  &:before {
    content: $iconmoon-vimeo; 
  }
}
.iconmoon-vimeo2 {
  &:before {
    content: $iconmoon-vimeo2; 
  }
}
.iconmoon-lanyrd {
  &:before {
    content: $iconmoon-lanyrd; 
  }
}
.iconmoon-flickr {
  &:before {
    content: $iconmoon-flickr; 
  }
}
.iconmoon-flickr2 {
  &:before {
    content: $iconmoon-flickr2; 
  }
}
.iconmoon-flickr3 {
  &:before {
    content: $iconmoon-flickr3; 
  }
}
.iconmoon-flickr4 {
  &:before {
    content: $iconmoon-flickr4; 
  }
}
.iconmoon-dribbble {
  &:before {
    content: $iconmoon-dribbble; 
  }
}
.iconmoon-behance {
  &:before {
    content: $iconmoon-behance; 
  }
}
.iconmoon-behance2 {
  &:before {
    content: $iconmoon-behance2; 
  }
}
.iconmoon-deviantart {
  &:before {
    content: $iconmoon-deviantart; 
  }
}
.iconmoon-500px {
  &:before {
    content: $iconmoon-500px; 
  }
}
.iconmoon-steam {
  &:before {
    content: $iconmoon-steam; 
  }
}
.iconmoon-steam2 {
  &:before {
    content: $iconmoon-steam2; 
  }
}
.iconmoon-dropbox {
  &:before {
    content: $iconmoon-dropbox; 
  }
}
.iconmoon-onedrive {
  &:before {
    content: $iconmoon-onedrive; 
  }
}
.iconmoon-github {
  &:before {
    content: $iconmoon-github; 
  }
}
.iconmoon-npm {
  &:before {
    content: $iconmoon-npm; 
  }
}
.iconmoon-basecamp {
  &:before {
    content: $iconmoon-basecamp; 
  }
}
.iconmoon-trello {
  &:before {
    content: $iconmoon-trello; 
  }
}
.iconmoon-wordpress {
  &:before {
    content: $iconmoon-wordpress; 
  }
}
.iconmoon-joomla {
  &:before {
    content: $iconmoon-joomla; 
  }
}
.iconmoon-ello {
  &:before {
    content: $iconmoon-ello; 
  }
}
.iconmoon-blogger {
  &:before {
    content: $iconmoon-blogger; 
  }
}
.iconmoon-blogger2 {
  &:before {
    content: $iconmoon-blogger2; 
  }
}
.iconmoon-tumblr {
  &:before {
    content: $iconmoon-tumblr; 
  }
}
.iconmoon-tumblr2 {
  &:before {
    content: $iconmoon-tumblr2; 
  }
}
.iconmoon-yahoo {
  &:before {
    content: $iconmoon-yahoo; 
  }
}
.iconmoon-yahoo2 {
  &:before {
    content: $iconmoon-yahoo2; 
  }
}
.iconmoon-tux {
  &:before {
    content: $iconmoon-tux; 
  }
}
.iconmoon-appleinc {
  &:before {
    content: $iconmoon-appleinc; 
  }
}
.iconmoon-finder {
  &:before {
    content: $iconmoon-finder; 
  }
}
.iconmoon-android {
  &:before {
    content: $iconmoon-android; 
  }
}
.iconmoon-windows {
  &:before {
    content: $iconmoon-windows; 
  }
}
.iconmoon-windows8 {
  &:before {
    content: $iconmoon-windows8; 
  }
}
.iconmoon-soundcloud {
  &:before {
    content: $iconmoon-soundcloud; 
  }
}
.iconmoon-soundcloud2 {
  &:before {
    content: $iconmoon-soundcloud2; 
  }
}
.iconmoon-skype {
  &:before {
    content: $iconmoon-skype; 
  }
}
.iconmoon-reddit {
  &:before {
    content: $iconmoon-reddit; 
  }
}
.iconmoon-hackernews {
  &:before {
    content: $iconmoon-hackernews; 
  }
}
.iconmoon-wikipedia {
  &:before {
    content: $iconmoon-wikipedia; 
  }
}
.iconmoon-linkedin {
  &:before {
    content: $iconmoon-linkedin; 
  }
}
.iconmoon-linkedin2 {
  &:before {
    content: $iconmoon-linkedin2; 
  }
}
.iconmoon-lastfm {
  &:before {
    content: $iconmoon-lastfm; 
  }
}
.iconmoon-lastfm2 {
  &:before {
    content: $iconmoon-lastfm2; 
  }
}
.iconmoon-delicious {
  &:before {
    content: $iconmoon-delicious; 
  }
}
.iconmoon-stumbleupon {
  &:before {
    content: $iconmoon-stumbleupon; 
  }
}
.iconmoon-stumbleupon2 {
  &:before {
    content: $iconmoon-stumbleupon2; 
  }
}
.iconmoon-stackoverflow {
  &:before {
    content: $iconmoon-stackoverflow; 
  }
}
.iconmoon-pinterest {
  &:before {
    content: $iconmoon-pinterest; 
  }
}
.iconmoon-pinterest2 {
  &:before {
    content: $iconmoon-pinterest2; 
  }
}
.iconmoon-xing {
  &:before {
    content: $iconmoon-xing; 
  }
}
.iconmoon-xing2 {
  &:before {
    content: $iconmoon-xing2; 
  }
}
.iconmoon-flattr {
  &:before {
    content: $iconmoon-flattr; 
  }
}
.iconmoon-foursquare {
  &:before {
    content: $iconmoon-foursquare; 
  }
}
.iconmoon-yelp {
  &:before {
    content: $iconmoon-yelp; 
  }
}
.iconmoon-paypal {
  &:before {
    content: $iconmoon-paypal; 
  }
}
.iconmoon-chrome {
  &:before {
    content: $iconmoon-chrome; 
  }
}
.iconmoon-firefox {
  &:before {
    content: $iconmoon-firefox; 
  }
}
.iconmoon-IE {
  &:before {
    content: $iconmoon-IE; 
  }
}
.iconmoon-edge {
  &:before {
    content: $iconmoon-edge; 
  }
}
.iconmoon-safari {
  &:before {
    content: $iconmoon-safari; 
  }
}
.iconmoon-opera {
  &:before {
    content: $iconmoon-opera; 
  }
}
.iconmoon-file-pdf {
  &:before {
    content: $iconmoon-file-pdf; 
  }
}
.iconmoon-file-openoffice {
  &:before {
    content: $iconmoon-file-openoffice; 
  }
}
.iconmoon-file-word {
  &:before {
    content: $iconmoon-file-word; 
  }
}
.iconmoon-file-excel {
  &:before {
    content: $iconmoon-file-excel; 
  }
}
.iconmoon-libreoffice {
  &:before {
    content: $iconmoon-libreoffice; 
  }
}
.iconmoon-html-five {
  &:before {
    content: $iconmoon-html-five; 
  }
}
.iconmoon-html-five2 {
  &:before {
    content: $iconmoon-html-five2; 
  }
}
.iconmoon-css3 {
  &:before {
    content: $iconmoon-css3; 
  }
}
.iconmoon-git {
  &:before {
    content: $iconmoon-git; 
  }
}
.iconmoon-codepen {
  &:before {
    content: $iconmoon-codepen; 
  }
}
.iconmoon-svg {
  &:before {
    content: $iconmoon-svg; 
  }
}
.iconmoon-IcoMoon {
  &:before {
    content: $iconmoon-IcoMoon; 
  }
}

