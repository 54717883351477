/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
.navigation-sidebar {

  height: 100%;
  overflow-y: auto;
  
  border-right: 1px solid $color-border;
  font-size: 10.5px;

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;

  padding: 0 4px 4px;

  a, i {

    color: inherit;
  }

  .navigation-sidebar-section1,
  .navigation-sidebar-section2 {

    display: flex;
    flex-direction: column;
    gap: 6px;
  }


  .navigation-sidebar-item {

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 7px 4px;
    text-align: center;

    i {
        font-size: 21px;
        background-color: $color-white;
        padding: 8px;
        border-radius: 2px;
        box-shadow: -1px 1px 3px 0px #82828b;
        border: 0.5px solid #fefeff;

        &.rs-icon-diagram {
          font-size: 22px;
        }
    }
  }

  .navigation-sidebar-section2 .navigation-sidebar-item {

    gap: 6px;

    i {
      background-color: transparent;
      padding: 0;
      box-shadow: none;
      border: none;

    }
  }

  .navigation-sidebar-item-inline {

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 10px 0 15px 0;
    font-size: 12px;

  }

  .navigation-sidebar-item {

    border-radius: 3px;

        &:hover,
        &:focus,
        &:focus-within {

            background-color: #ffffff24;
        }
    }
 }