.ontodia-btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  > .ontodia-btn, > .ontodia-btn-group {
    position: relative;
    float: left;
  }

  > .ontodia-btn:hover,
  > .ontodia-btn:focus,
  > .ontodia-btn.active,
  > .ontodia-btn.active:hover {
    z-index: 2;
  }

  .ontodia-btn + .ontodia-btn,
  .ontodia-btn + .ontodia-btn-group,
  .ontodia-btn-group + .ontodia-btn,
  .ontodia-btn-group + .ontodia-btn-group {
    margin-left: -1px;
  }

  > .ontodia-btn:first-child {
    margin-left: 0;
  }

  &-sm > .ontodia-btn {
    font-size: 13px;
    line-height: 1.7;
    padding: 5px 10px;
  }

  &-xs > .ontodia-btn {
    font-size: 13px;
    line-height: 1.7;
    padding: 0px 7px;
  }
}