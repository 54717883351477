// KM right sidebar connections content
.link-types-toolbox {
  flex: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  &__heading {
    padding: 0 10px 10px;
  }

  &__searching-box {
    display: flex;
    align-items: center;
  }

  &__clearSearch {
    margin-left: -25px;
    appearance: none;
    -webkit-appearance: none; 
    -moz-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
  }

  &__switch-all {
    margin-right: 10px;
    margin-top: 10px;

    display: flex;
    align-items: center;
    gap: 2px;

/*     & .ontodia-btn-group {
      margin-right: 6px;
    } */

/*     & .ontodia-btn-default {
      background-color: $color-secondary;
      color: $color-white;
      border-color: $color-white;
    } */
  }

  &__rest {
    padding: 5px;
    flex: auto;
    display: flex;
    flex-direction: column;
  }
}

.link-types-toolbox .panel-heading {
  flex-shrink: 0;
  -webkit-flex-shrink: 0; /* safari 8 */
}

.link-types-toolbox ul {
  margin-bottom: 0;
}
.link-types-toolbox .links-heading {
  margin-left: .5em;
}
.link-types-toolbox .links-heading span {

  background: $color-action--op30;
  color: $color-dark;
  border: 1px solid #96a1be;
  border-radius: $border-radius;
  font-weight: 600;
  padding: 2px .5em;
  word-wrap: break-word;
  line-height: 1.3;
  margin-left: 5px;
}

.link-types-toolbox__heading .link-types-toolbox-controls {
  padding: 5px 15px;
  font-size: 14px;
  font-style: italic;
}
.link-types-toolbox__heading .link-types-toolbox-controls > span {
  padding-left: .3em;
}

.ontodia-list-group .linkInToolBox {
  padding: 5px 10px;
  word-break: break-word;
  justify-content: space-between;

  &::before, &::after {
    display: none;
  }
}

.linkInToolBox {
  & > div { 
    display: flex; 
    align-items: center;
  }

  &__new-tag {
    margin-left: .5em;
    white-space: normal;
    word-wrap: normal;
    border-radius: $border-radius;
    padding: 0 5px;
    background: orange;
  }

  .ontodia-badge {
    display: none;
    margin-left: .5em;
    white-space: normal;
    word-wrap: normal;
  }

  .link-title {

    color: $color-dark;
    display: inline;
  }

  .ontodia-btn-group {
    display: flex;
    margin-right: 10px;
  }

  .ontodia-btn.ontodia-btn-default {
    border-color: $color-btn-toolbar-border;
    color: $color-btn-toolbar;
    background-color: transparent;

    &.active {

      background-color: $color-secondary;
      color: $color-white;
    }
  }

  &__filter-button {
    display: none;
    float: right;
//    background-image: url("../images/add-to-filter.png");
//    background-size: 20px 20px;
 //   width: 20px;
//    height: 20px;
    margin: 3px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 200ms 0ms;

  }
  &:hover &__filter-button {
    opacity: 1.0;
    transition: opacity 200ms 0ms;
  }
}

.connected-links .linkInToolBox .ontodia-badge {
  /* show connection count badge only in "Connected to Element" list */
  display: inline;
}
.connected-links .linkInToolBox__filter-button {
  /* show filter button only in "Connected to Element" list */
  display: flex;
}
