/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.page-help-tutorial {
  .inline-code {
    padding: 2px 4px;
    color: #d14;
    white-space: nowrap;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
  }

  .feature-row {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .feature-box {
    min-height: 200px;
    margin: 25px;
    border: 2px solid #aaa;
  }

/*   .feature-box-invisible {
    min-height: 200px;
    margin: 25px;
    border: none;
  } */

  .feature-box-image {
    margin-top: -40px;
    margin-bottom: 15px;
  }

  .feature-box-title {
    margin: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .feature-box-list {
    margin: 25px;
    line-height: 1.8em;
  }
}

.documentation {
  h3 {
    margin-top: $padding-base-vertical * 3;
    margin-bottom: $padding-base-vertical * 2;
  }

  &__example,
  &__intro {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1600px) {
      flex-direction: column;
    }

    &__description,
    &__demo {
      width: 49%;

      @media (max-width: 1600px) {
        width: auto;
      }
    }
  }
}



//used only in help pages
.rs-help-search {
  padding: $padding-base-vertical * 2 10px 20px $padding-base-horizontal * 2;
}
