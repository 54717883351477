/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @author Artem Kozlov <ak@metaphacts.com>
 * @author Alexey Morozov
 */

@import '~basic-styles.scss';
@import '../commons/variables.scss';

.itemSelector {
  border: 1px solid #ddd;
  list-style: none;
  padding: 0;
  display: inline-block;
  @include box-shadow(0px 4px 10px rgba(0, 0, 0, 0.17));
  z-index: 1;

  &--active,
  &--dropdown-toggle,
  &--focus {
    background-color: $color-light;
  }
}

.dropdown {
  width: 175px;
}

.itemHolder {
  @include itemHolder();
  justify-content: flex-start;
  border: none;
  color: $color-dark;

  &:hover,
  &:focus {
    border-color: $rs-button-border-color;
    background-color: $color-light;
  }

  &:active {

    background-color: $color-light;
  }

  img {
    height: 40px;
    width: 40px;
  }
}
