/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
/* Sparql page */
// src/main/resources/org/researchspace/apps/system/data/templates/http%3A%2F%2Fwww.researchspace.org%2Fresource%2Fsystem%2FSparqlEndpoint.html

.rs-sparqlPage {

  // SparqlEndpoint page sidebar
  .sparqlSidebar {

    background: $color-sparlSidebar-bg;

    .split-pane__sparqlSidebar-open {

      flex: 1 1 auto;
      overflow: hidden;

      .open-sidebar__tabs {

        display: flex;
        flex-direction: column;
        height: 100%;

        .tab-content,
        .tab-pane {

          height: 100%;
        }

        .tab-content {

          overflow-y: auto;
        }
    
        .nav.nav-tabs > li:first-of-type {
    
          padding-left: 20px;
        }
      }

    }

  }

  .set-management__sets,
  .set-management__set-items {
    background-color: $color-sparlSidebar-bg;
  }

  .set-management__sets,
  .set-management__items-view,
  .set-management__set-items {
    height: 100%;
  }

  .set-management__items-view {

    padding-top: 0;
  }

  .set-management--list-view .set-management__set-item {
    padding: 2.5px 10px;
  }
  
  .set-management__footer {
    display:none;
  }

  .set-management .Select-menu-outer {
    opacity: 0.9;
    filter: alpha(opacity=90);
  }

  /* Categories */
  .query_category {

    background-color: $color-info;
    color: $color-white;
    border-radius: $border-radius;
    margin-right: 5px;
    cursor: pointer;
    padding: 1px 3px;
    font-size: 90%;
    white-space: nowrap;
    margin-bottom: 5px;
    float: left;
  }
}

//Sparql Editor
.yasqe .CodeMirror-fullscreen {
  z-index: $zindex-yasqe-fullscreen !important;
}

// Results area
.SparqlQueryEditor--sparqlQueryEditor .yasr {

  font-family: $font-family-base;
  font-size: 13px;
  line-height: 1.2;
  color: $color-secondary;
  padding-top: 15px;
  background-color: transparent;

  .yasr_btn {

    color: $color-secondary;
    border-color: $color-secondary;

    &:hover,
    &:focus {

      background-color: $color-white;
    }
  }

  .yasr_btn.selected, 
  .yasr_btn:focus {

      background-color: $color-secondary;
      border-color: $color-secondary;
      color: $color-white;

  }

  .yasr_downloadIcon.yasr_btn.btn_icon {

    padding-top: 5px;
    padding-bottom: 6px;

    &:hover,
    &:focus {

      background-color: $color-white;
    }

    #Your_Icon path {

      fill: $color-secondary !important;
    }
  }

  table {

    td a {
      color: $color-dark;
    }
  }

  .yasr_results {

    .btn {
      height: $input-height;
    }

    .sparql-endpoint-result-table {

      margin-top: -30px;

      .Select {
        margin-bottom: 10px;
      }
    }

    .researchspace-table-widget-holder .griddle-filter .filter-container {

      margin-bottom: 0px;
    }
  }
}