/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
/* Detail sidebar */


/* .details-sidebar {

    flex-direction: column; 
    flex: 1 1 auto !important; 
    padding: 0px 24px 24px 24px; 
    height: 100%;

    overflow: auto;

    #template-content,
    .page.page-local-overwrite {

        height: inherit;
    }

    & > div {

        height: inherit;
        overflow: unset !important;
    }
} */

.details-container {

    height: 100%;
    padding: 1.5em 2em;

    #template-content,
    .page.page-local-overwrite {
        height: inherit;
        min-height: inherit;
    }
}

.details-content {

    height: 100%;
    display: flex;
    flex-direction: column;

    a {
        text-decoration: underline;
    }

    .rs-resource-header--title-margin {
        margin-bottom: 18px;
    }

    .detail-sidebar__hok-resource-content {

        margin: 20px 0 20px 0;
    }

    
}

.details-header {
    display: flex; 
    margin-bottom: 18px;
}

.detail-img {

    max-width: 25%;
    margin-right: 20px;
    object-fit: cover;
}

.detail-icon-noimg {

    font-size: 5em;
    padding: 12px 20px;
    margin-right: 20px;
}

.detail-type {

    overflow-wrap: break-word;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 7px;
    font-weight: 700;
    letter-spacing: 0.2px;
}

.detail-label {

    overflow-wrap: break-word;
    font-size: 15px;
    letter-spacing: -0.3px;
    text-transform: capitalize;
}

.details-tabs {

    display: flex;
    flex-direction: column;
    height: 0;
    min-height: 30px;
    flex: 1 1 auto;

    .nav.nav-tabs a {
        text-decoration: none;
    }

    .tab-content {
        overflow-y: auto;
    }

    .rawdata-tab {
        
        thead {
            display: none;
        }
    }

    .tab-pane {
        padding-top: 0;
    }

    .table-responsive.rs-table {
        margin-top: 0px;
    }

    & .table > tbody > tr > td {
        padding: 16px 10px 12px 10px;
    }
}
