/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.alert-component {
/*   margin: 0px;
  padding: $padding-base-vertical $padding-base-horizontal;
  margin-top: $padding-base-horizontal;
  margin-bottom: $padding-base-horizontal;
  border-radius: 3px; */

  &__success {
    background: $color-success-light;
    color: $alert-success-text;
    border: 1px solid $alert-success-border;
    margin-top: 10px;
  }
  &__info {
    background: $color-info-light;
    color: $alert-info-text;
    border: 1px solid $alert-info-border;
  }
  &__warning {
    background: $color-warning-light;
    color: $alert-warning-text;
    border: 1px solid $alert-warning-border;
  }
  &__danger {
    background: $color-danger-light;
    color: $alert-danger-text;
    border: 1px solid $color-danger-border;
  }

  &__none {
    display: none !important;
  }
}
