.ontodia-connections-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 15px;

  &__sort-switch {
    display: none;
  }

  &__sort-switch:checked + &__sort-switch-label {
    color: $color-action;
    opacity: 1;
  }

  &__sort-switch-label {
    cursor: pointer;
    display: block;
    margin: 0;
    opacity: 0.4;
    text-align: center;

    &:hover {
      opacity: 1;
    }
  }

  &__search-line-input {
    flex: 1 1 100%;
  }
}

.ontodia-connections-menu_bread-crumbs {
  padding: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 0;
}

.ontodia-connections-menu__title-label {

  background-color: $color-white;
  border: 1px solid $color-border;
  border-bottom: none;
  font-size: 15px;
  font-weight: 700;
  left: -1px;
  padding: 12px 15px;
  position: absolute;
  top: -44px;
  width: 100.5%;
  max-width: 100.5%;
}

.ontodia-connections-menu_search-line {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-bottom: 8px;
}

.ontodia-connections-menu_search-line-sort-switches {
  flex: 0 0 20px;
  margin-left: 10px;
}

.ontodia-connections-menu_links-list {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  margin: 0;
}

.ocm_links-list-empty {
  display: flex;
  align-items: center;
}

.ontodia-connections-menu_links-list__empty {
  width: 100%;
  position: relative;
  font-size: x-large;
  color: $color-base;
  text-align: center;
}

.ontodia-connections-menu__loading,
.ontodia-connections-menu__error {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: x-large;
  display: flex;
  color: $color-base;
  align-items: center;
  justify-content: center;
}
.ontodia-connections-menu__loading-objects {
  border: 1px solid $color-border;
  border-radius: $border-radius;
  flex-grow: 1;
  box-shadow: inset 0px 0px 20px -8px $color-box-shadow;
  background-color: $color-white;
  position: relative;
  font-size: $font-size-large;
  display: flex;
  color: $color-base;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.ontodia-connections-menu__move-to-filter {
  display: flex;
  padding: 5px 10px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #e6e6e6;
  }
}

.link-in-popup-menu {
  display: flex;
  gap: 8px;
  overflow: hidden;
  padding: 0 5px;
  align-items: center;
  margin-bottom: 4px;

  background-color: $color-off-white;
  padding: 3px 7px;
  border: 1px solid $color-border;

  &__link-title {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  //  margin: 5px 0px 5px 5px;
  }

  &__count {
    flex-shrink: 0;
  //  margin-left: 10px;
  }

  &__navigate-button {
    float: right;
  //  background-image: url("../images/expand-link.png");
  //  width: 16px;
  //  height: 16px;
    opacity: 0.5;
  //  margin-left: 10px;
  //  min-width: 16px;
   // background-size: cover;
    &:hover { opacity: 1; }

    &::after {
      @include materialIcon ('\e5cc', 22px);
    }
  }

  &__filter-button {
  //  background-image: url("../images/add-to-filter.png");
  //  background-size: 20px 20px;
  //  width: 20px;
  //  height: 20px;
    opacity: 0.6;
    &:hover { opacity: 1; }

    &::after {
      @include materialIcon ('\ef4f', 22px);
    }
  }

  &:hover {
    background-color: $color-lighter;
  }
}

.link-in-popup-menu_direction {

    display: flex;
    align-items: center;

    background: transparent;
    min-height: 31px;
}

.link-in-popup-menu_direction__in-direction {
//  background: url("../images/direction-in.png") 0 0 no-repeat;
  flex-shrink: 0;
//  width: 22px;
//  height: 15px;
//  margin: 0 14px 0 0;
//  background-size: contain;
  opacity: 0.5;

  display: flex;
  align-items: center;

  &::before {
    @include materialIcon ('\e890', 18px);
    transform: rotate(180deg);
   }
}

.link-in-popup-menu_direction__out-direction {
//  background: url("../images/direction-out.png") 0 0 no-repeat;
  flex-shrink: 0;
/*   width: 22px;
  height: 15px;
  margin: 0 8px 0 6px;
  background-size: contain; */
  opacity: 0.5;

  display: flex;
  align-items: center;

  &::before {
    @include materialIcon ('\e890', 18px);
   }

}

.ontodia-connections-menu_links-list__loading {
  text-align: center;
  width: 100%;
  position: relative;
  font-size: x-large;
  color: $color-base;
}

.ontodia-connections-menu_links-list__hr {

  border: none !important;
  margin-bottom: 5px;
  margin-top: 5px;
}

.ontodia-connections-menu_objects-panel {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
}

.ontodia-connections-menu_objects-panel__select-all {
    white-space: nowrap;
    display: flex;
    margin-top: 7px;

    label {

      display: flex;
      align-items: center;
      font-weight: 600;
    }

    input, input[type="checkbox"] {
      margin-right: 5px;
      &:disabled { opacity: 0.3 }
    }
}

.ontodia-connections-menu_objects-panel_bottom-panel {
  height: 25px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}

.ontodia-connections-menu_objects-panel_bottom-panel__count-label {
  height: 25px;
  float: left;
  padding: 0px 5px;
  font-size: 15px;
  display: flex;
  align-items: center;
  max-width: 60%;
}

.ontodia-connections-menu_objects-panel_bottom-panel__extra-elements {
  color: gray;
  cursor: help;
}

.ontodia-btn.ontodia-connections-menu_objects-panel_bottom-panel__add-button {
  height: 25px;
  padding-top: 1px;
  padding-bottom: 2px;
}

.ontodia-connections-menu_objects-panel_objects-list {
  border: 1px solid $input-border-color;
  height: 0;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 5px;
  margin-bottom: 5px;
}

.ontodia-connections-menu__link {
  color: $color-dark;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
