.ontodia-authoring-state {
  &__item-error {
    align-self: flex-end;
    margin: 0px 5px;
    display: flex;
    align-items: center;
    gap: 3px;
   // cursor: help;
  }

  &__item-error-icon {
    background: url("../images/font-awesome/exclamation-triangle.svg");
    height: 15px;
    width: 17px;
  
  }

  &__state-label {
 //   font-weight: bold;
//    margin-right: 5px;
  }

  &__state-cancel {

    cursor: pointer;
    padding: 0;

  }

  &__state-indicator {
    position: absolute;
  }
  &__state-indicator-container {
    position: relative;
  }
  &__state-indicator-body {
    position: absolute;
    white-space: nowrap;
    display: flex;
    align-items: center;
    bottom: 5px;
    background: transparent;
    border-radius: 5px;
  }
}
