/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
/* Tabs */ 
.nav.nav-tabs {

    margin-left: 0;
    border-bottom: 1px solid $color-border;

    & > li {
        padding: 9px 15px;

        &:first-of-type {

            padding-left: 0;
        }

        &:hover {
            box-shadow: inset 0px -3px 0px 0px $color-light;
        }

        &.active {
            box-shadow: inset 0px -3px 0px 0px $color-action;

            & > a {
                background-color: transparent;
                font-weight: bold;
                color: $color-action;
                border: none;
            }

        }

        &.disabled {

            opacity: 0.4;
            cursor: not-allowed;
        }

        & > a {
            color: $color-secondary;
            font-size: $font-size-base;
            border: none;
            padding: 0;

            &:hover, 
            &:focus {
                background-color: transparent;
            }
        }
    }

    & > li.tab-right {

        float: right;
    }
}

.nav.icon-tabs {

    display: flex;
    justify-content: end;

    & > li {

        color: $color-secondary-light;
     //   background-color: $color-lighter;
        background-color: $color-white;
        border-radius: $border-radius;
        border: 1px solid $color-border;

        padding: 4px;
        margin-left: 3px;

        &:hover,
        &:focus,
        &:focus-within {
            color: $color-secondary-light;
            background-color: $color-white;
        }
        
        &:active,
        &.active,
        &:active:hover, 
        &:active:focus, 
        &:active.focus, 
        &.active:hover, 
        &.active:focus, 
        &.active.focus {
            background-color: $color-secondary-light;

            i {
                color: $color-white;
            }
        }

        & > a {
   
            padding: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover, 
            &:focus {
                background-color: transparent;
            }

            i, span {
                color: $color-secondary;
                font-size: 20px;
            }
        }
    }


}

/* Object record tabs */
.rs-resource-tabs {

    .tab-content {
      background-color: $color-resourceTabs-bg;
    }
}

.rs-resource-tabs .nav.nav-tabs {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    border: none;

    & > li {
        padding: 9px 3%;
        text-align: center;
    }

    & > li > a {
        line-height: 1.95;
        font-weight: 600;
        border: none;
    }

    & > li.disabled {

        cursor: not-allowed;
        opacity: 0.5;
    }
}

/* right aligned tabs */
.tabs-right {
    li {
        float: right;
    }
    // make sure that pagination is still left aligned
    .pagination li {
        float: left;
    }
}

.tab-pane {
    padding-top: $padding-base-vertical;
}

.bs-left-vertical-tabs {

    ul.nav.nav-tabs {
      border-bottom: initial;
      border-right: 1px solid $color-border;
  
      li {
        margin-bottom: initial;
      }
    }
}