/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
/* Dropdowns */
.dropdown {

    &.btn-group button {

        display: flex;
        align-items: center;
        gap: 8px;

        &:nth-child(2) {

            height: auto;
        }

    }

    .dropdown-toggle {

        height: 100%;
    }
}

.dropdown-menu,
table .dropdown-menu {

    color: $dropdown-link-color;
    min-width: -moz-available;
    min-width: -webkit-fill-available;
    min-width: fill-available;
    max-height: 40vh;
    overflow: auto;
    border-radius: $border-radius;
    border-color: $color-border;
    margin: 2px 0 0;
    padding: 4px;
    background-clip: unset;

    & > li {
        width: 100%;
    }
    
    & > li > a,
    & > div > li > a {

        display: flex;
        align-items: center;
        justify-content: start;
        color: $dropdown-link-color;
        font-size: $dropdown-font-size;
        padding: 10px 15px;
        clear: both;
        line-height: 1.42857;
        white-space: nowrap;
        text-decoration: none;

        & > a {

            color: $dropdown-link-color;
        }

        &:hover, 
        &:focus {
            background-color: $color-lighter;
        }

        i {
            font-size: 21px;
            color: $color-base;
        }

        .icon-right {
            padding-left: 10px;
        }
        
        .icon-left {
            padding-right: 10px;
        }
    }

    &> .active > a, 
    & > .active > a:hover, 
    & > .active > a:focus {
        color: $color-white;
        background-color: $color-base;
        font-weight: 600;
    }

    & > .disabled > a, 
    & > .disabled > a:hover, 
    & > .disabled > a:focus {

        color: $color-dark--op50;
    }

    hr {
        margin: 0;
    }

    .dropdown-menu .divider {
        padding: 0 !important;
        height: 1px;
        margin: 0;
        overflow: hidden;
        background-color: $color-border;
        
    }

    li.divider {

        margin: 0;
        background-color: $color-border;
    }
}

.dropdown-menu.resource-actions__dropdown-menu {

    min-width: 165px;

    & > li > a {
        align-items: flex-start;
        font-size: 13.5px;

        span {
            white-space: pre-wrap;
            word-break: break-word;
        }

        i {
            font-size: 18px;
        }
    }
}

.btn-group.open .dropdown-toggle, 
.btn:active {

    box-shadow: none !important;
}

.SemanticTreeInput--dropdown {

    .LazyTreeSelector--item {

        height: 100%;
        padding: 0 5px;
        display: flex;
        align-items: center;
    }
}

.text-dropdown {

    border: none;
    background: transparent;
    font-size: $font-size-body;
    font-weight: 600;
    text-shadow: none;
    padding: 0;

    &:hover,
    &:focus,
    &:focus-within,
    &:active,
    &:active:hover,
    &.active,
    .open > &.dropdown-toggle,
    &:active:hover,
    &:active:focus,
    &:active.focus,
    &.active:hover,
    &.active:focus,
    &.active.focus,
    .open > &.dropdown-toggle:hover,
    .open > &.dropdown-toggle:focus,
    .open > &.dropdown-toggle.focus {

        background: transparent;
    }

    &.link-dropdown {
        color: $color-action;

        &:hover,
        &:focus,
        &:focus-within,
        &:active,
        &:active:hover,
        &.active,
        .open > &.dropdown-toggle,
        &:active:hover,
        &:active:focus,
        &:active.focus,
        &.active:hover,
        &.active:focus,
        &.active.focus,
        .open > &.dropdown-toggle:hover,
        .open > &.dropdown-toggle:focus,
        .open > &.dropdown-toggle.focus {
    
            color: $color-action;
        }
    }
}

.dropdown-no-caret {

    .caret {
        display: none;
    }
}

.dropdown-menu > li.dropdown-item-action-selection-container {

    & div,
    & li {
        width: 100%;
    }

    & > a {
       width: 100%;
       padding: 0; 
       

       .selection-action-choice a {

        width: 100%;
        display: flex;
        align-items: center;
        padding: 9px 25px 9px 20px;

         
            color: $dropdown-link-color;
            font-size: $dropdown-font-size;

            &:hover, 
            &:focus {
                color: $color-dark;
            }
        
       }
    }
    
}
