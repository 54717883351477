/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@import '~basic-styles.scss';

.component {
  display: flex;
  flex-direction: column;
}

.reordered-item {
  margin: 4px 0;
}

.removable-item {
  display: flex;
  align-items: start;
}

.multiline-editor {
  flex: auto;
  min-height: $input-height;
  padding: 4px 8px;
  font-size: 14px;
  border-color: #ddd;
  resize: vertical;
  // prevent line wrap and support pressing Enter in Firefox, see:
  // "textarea with "white-space: nowrap; " no more allows to
  // type a new line since FF 36.0" at
  // https://bugzilla.mozilla.org/show_bug.cgi?id=1137650
  white-space: pre;
  overflow: auto;
  word-wrap: normal;
}

.remove-item-button {
  margin-left: 5px;
  color: $color-dark;
}

.storage-selector {
  margin-top: 10px;
}

.footer {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.submit-controls {
  display: flex;
  align-items: baseline;
}

.save {
  margin-right: 10px;
}
