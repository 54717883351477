/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

$total-padding: 5px;

.clearable-input {

  min-height: $input-height;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  background-color: $input-color-bg;

  border-radius: $input-border-radius;
  border: 1px solid $input-border-color;

  color: $input-color;

  &--focused {
//    border: none;
  }

  &__input-with-clear {
    flex: auto;
    display: flex;
    height: 100%;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 0 0 10px;
    
    i {
      font-size: 20px;
      color: $color-base;
    }
  }

  // use more specific selector to override Bootstrap's attributes
  &__input.form-control {
    width: 100%;
    flex: auto;
    min-width: 0;
    box-shadow: none;
    background-color:transparent;
    border: none;
    border-radius: $input-border-radius;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1; /* Firefox */
    }

    &:focus {
      outline: none;
      border: none;
  
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
  
        opacity: 0.7; /* Firefox */
      }
    }

  }

  &__clear {
    height: 100%;
    display: flex;
    align-items: center;

    padding: 0 10px;
    align-self: center;
    pointer-events: auto;
    text-decoration: none;
    cursor: pointer;

    i {
      color: $color-secondary;
    }
  }

  &__input[value=''] ~ &__clear {
    display: none;
  }

}


