.ontodia-list-group {
  margin: 0;
  padding: 0;
}

.ontodia-list-group-item:last-child {
  margin-bottom: 0;
}

.ontodia-list-group-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid $color-border;
}
