.ontodia-navigator {
  display: block;
  position: absolute;
  bottom: 10px;
  right: 10px;
  overflow: hidden;

  // increase specificity to override default box-sizing,
  // so border size won't be included into total size
  .ontodia & {
    box-sizing: content-box;
  }

  background: $color-white;
  border: 1px solid $color-border;
  box-shadow: 2px 2px 3px 0px #95959f;

  $transition: 0.3s;
  transition: width $transition, height $transition;

  &--collapsed {
    width: 30px;
    height: 30px;
    border-radius: $border-radius;
    background-color: $color-secondary-light;
    border-color: $color-secondary-light;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  > canvas {
    transition: opacity $transition;
  }
  &--expanded > canvas { opacity: 1; }
  &--collapsed > canvas { opacity: 0; }

  &__toggle {
    position: absolute;
    background: transparent;
    border: none;
    outline: none;

    opacity: 0.6;
    transition: opacity 0.3s;

    &:hover {
      // background: lightgray;
      opacity: 1;
    }
  }
  &--expanded &__toggle {
    top: 5px;
    left: 5px;
    padding: 4px;
  }
  &--collapsed &__toggle {

    opacity: 1;
    color: $color-white;
  }

  &__toggle-icon {
  //  width: 18px;
  //  height: 18px;
  //  background-size: 18px 18px;
  //  background-repeat: no-repeat;
  }
  &--expanded &__toggle-icon::before {
    @include materialIcon('\f1cf', 18px);

 //   background-image: url('/assets/images/icons/minus_dark.svg');
  }
  &--collapsed &__toggle-icon::before {
    
    @include materialIcon('\f1ce', 18px);
 //   background-image: url('/assets/images/icons/plus_white.svg');
  }
}
