/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

@mixin status-icon($color) {
  @include fa-icon();
  &:before {
    content: $fa-var-circle;
  }
  color: $color;
}

.online {
  @include status-icon(green);
}

.offline {
  @include status-icon(red);
}

.EditorArea {
  padding: 20px 30px 0px 30px;
}

.RepositorySelectionArea {
  padding-top: 20px;
  padding-right: 10px;
}

.RepositoryRow {
  &:hover {
    cursor: pointer;
    background-color: $color-medium !important;
  }
}

.RepositoryRowActive {
  composes: RepositoryRow;
  background-color: $color-medium !important;
}

.RepositoryButton {
  margin-top: 15px;
}

.holder {
  :global {

    h4 {
      font-size: 14px;
    }
    
    table th {
      font-weight: bold;
    }
  }
}
