/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
 
 /* Popovers */
.popover {

    color: $color-secondary;
    
    font-size: $font-size-small;
  //  font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.3px;

    background-color: $color-popover-bg;

    border-color: $color-border;
    border-radius: $border-radius;

    .popover-title {
        padding: 8px 15px;
        font-size: 13.5px;
        font-weight: 700;
        border: none;
        background-color: $color-lighter;
    }

    .popover-content {
    
        padding: 11px 15px;
        overflow-wrap: break-word;
        hyphens: manual;
    }

    &.right > .arrow {
    
        border-right-color: $color-border;

        &:after {
            left: 4px;
        }
    }

    &.left > .arrow {
    
        border-left-color: $color-border;
    }

    &.top > .arrow {
        border-top-color: $color-border;

        &:after {
            border-top-color: $color-border;
        }
    }

    &.bottom > .arrow {

        border-bottom-color: $color-border;

        &::after {
            border-bottom-color: $color-popover-bg;
        }
    }
}

/*  Clipboard list view popover */
/* .clipboard-popover-trigger {

    width: 18px;
    text-align: center;
    border: 1px solid transparent;
}

.clipboard-popover-trigger[aria-describedby="mp-popover"] {

    border: 1px solid $color-border;
    border-radius: $border-radius;
    background: transparent;
    padding-left: 2px;
}

.clipboard-listview-popover {

    &.popover {

        font-size: $font-size-small;
        letter-spacing: 0px;
        line-height: 1.2;
    
    
        .popover-content {
            padding: 2px 0px;
        }

        &.right {
            margin-left: 8px;
        }
    
        &.right > .arrow {
    
            border-right: none;
            top: 42% !important; //overwrite inline style
    
            &:after {
                border-right-color: $color-white;
                left: 3px;
            }
        }
    
        .set-management__item-popover {
            font-size: 12px;
            font-weight: 500;
        }
    
        .set-management__item-popover-item {
    
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 4px 11px;
    
            i {
                font-size: 1.5em;
            }
    
            span {
                padding-left: 10px;
            }
    
            &:hover {
                background-color: $color-lighter;
            }
        }
    }
} */

/* Resource header popover */
.rs-resource-header-popover {

    &.popover {

        font-style: italic;
        color: $color-white;
        background-color: $color-resource-popover-bg;
        border-color: $color-resource-popover-bg;
    
        .popover-content {
            padding: 5px 10px;
        }

        &.right {
            margin-left: 16px;
        }

        &.right > .arrow {
    
            border-right: none;
    
            &:after {
    
                left: 4px;
                border-right-color: $color-resource-popover-bg;
            }
        }
    }
}


/* Tooltip */
.tooltip {
    @include font-common($color-white, 11px, $line-height-body);
    font-weight: 600;
}

.tooltip-inner {
    padding: 4px 8px;
    background-color: $color-secondary;
    border-radius: $border-radius;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: $color-secondary;
}

