//connections dialog area
.ontodia-dialog {
  background: $color-white;
  border: 1px solid $color-border;
  border-radius: $border-radius;
//  box-shadow: 0 0 5px 3px $color-box-shadow;
  position: absolute;

  &__caption {

    font-size: 15px;
    font-weight: 700;
    padding: 12px 15px;
    position: absolute;
    border: 1px solid $color-border;
    background-color: $color-white;
    border-bottom: none;
    width: 100.5%;
    top: -44px;
    left: -1px;
/*     font-weight: bold;
    position: absolute;
    top: -11px;
    left: 10px;
    background: $color-km-bg;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 6px; */
  }

/*   & .DragAndDropInput--itemArea {

    min-height: 80px;
    border-style: dashed;
    border-width: 2px;
    border-color: $color-border;

    .placeholder-item {
      min-height: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%; 
      color:$input-placeholder-color; 
      padding: 40px 20px; 
      text-align: center;

      .upload_icon.material-icons-round,
      .upload_icon.material-symbols-rounded {
        font-size: 25px;
        padding-bottom: 10px;
        color: inherit;
      }
    }
  } */

  &__close-button {
  //  background: transparent url("../images/font-awesome/times-circle-regular.svg");
    background-size: contain;
    border: 0 none;
    cursor: pointer;
    display: block;
    outline: none;
    padding: 0;
    position: absolute;
    top: -34px;
    right: 12px;

    display: flex;
    align-items: center;

    opacity: 0.6;
    transition: 0.3s;
    z-index: 1;

    &::before {
      @include materialIcon ('\e5cd', 20px);
    }

    &:hover {
      opacity: 1;
    }
  }
}

//bottom right triangle to resize connections dialog
.ontodia-dialog__bottom-right-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10px 10px;
  border-color: transparent transparent $color-dark--op30 transparent;
  cursor: nwse-resize;

  &::before {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 5px 5px;
    border-color: transparent transparent $color-dark--op30 transparent;
  }

  &:hover {
    border-color: transparent transparent $color-dark--op50 transparent;
  }
}

.ontodia-dialog__bottom-handle, .ontodia-dialog__right-handle {
  position: absolute;
  opacity: 0;
  background-color: $color-dark;

  &:hover {
    opacity: 0.1;
  }
}

.ontodia-dialog__bottom-handle {
  bottom: 0;
  width: 100%;
  height: 5px;
  cursor: ns-resize;
}

.ontodia-dialog__right-handle {
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  cursor: ew-resize;
}
