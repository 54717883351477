/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
 
 // font icons generated from .svg files with https://icomoon.io/app

 // CURRENT NUMBER OF RS-ICON: 115
 // DON'T CREATE ICONS WITH PATHS - IF PATHS ARE IN THE SVG, CREATE A COMBINED SHAPE TO MERGE THEM IN AN UNIQUE SHAPE
 $rs-icon-publication_no-image: "\e986";
 $rs-icon-publication: "\e987";
 $rs-icon-text-citation_no-image: "\e985";
 $rs-icon-text-citation: "\e99e";
 $rs-icon-drop_resource_hand: "\e983";
 $rs-icon-drop_resource_item: "\e984";
 $rs-icon-actor_no-image: "\e900";
 $rs-icon-annotations_no-image: "\e901";
 $rs-icon-assertion_no-image: "\e902";
 $rs-icon-belief_no-image: "\e903";
 $rs-icon-class_no-image: "\e904";
 $rs-icon-concept_no-image: "\e905";
 $rs-icon-date_no-image: "\e906";
 $rs-icon-diagram_no-image: "\e907";
 $rs-icon-entity_no-image: "\e908";
 $rs-icon-event_no-image: "\e909";
 $rs-icon-individual_inf_no-image: "\e90a";
 $rs-icon-influence_no-image: "\e90b";
 $rs-icon-material_no-image: "\e90c";
 $rs-icon-narrative_no-image: "\e90d";
 $rs-icon-no-image: "\e90e";
 $rs-icon-place_no-image: "\e90f";
 $rs-icon-set_no-image: "\e910";
 $rs-icon-thing_no-image: "\e911";
 $rs-icon-type_no-image: "\e912";
 $rs-icon-actor: "\e913";
 $rs-icon-add: "\e914";
 $rs-icon-advanced_search: "\e915";
 $rs-icon-annotation: "\e916";
 $rs-icon-area_annotation: "\e917";
 $rs-icon-assertion: "\e918";
 $rs-icon-belief: "\e919";
 $rs-icon-caret_down: "\e91a";
 $rs-icon-chevron_down: "\e91b";
 $rs-icon-citation: "\e91c";
 $rs-icon-class: "\e91d";
 $rs-icon-close: "\e91e";
 $rs-icon-concept: "\e91f";
 $rs-icon-date: "\e920";
 $rs-icon-delete_round_2: "\e921";
 $rs-icon-delete_round: "\e922";
 $rs-icon-delete: "\e923";
 $rs-icon-dev_documentation: "\e924";
 $rs-icon-diagram: "\e925";
 $rs-icon-documentation: "\e926";
 $rs-icon-drag_arrow: "\e927";
 $rs-icon-drag_hand: "\e928";
 $rs-icon-drag_points: "\e929";
 $rs-icon-drop_resource: "\e92a";
 $rs-icon-edit: "\e92b";
 $rs-icon-entity: "\e92c";
 $rs-icon-event: "\e92d";
 $rs-icon-expand_media: "\e92e";
 $rs-icon-folder: "\e92f";
 $rs-icon-form_edit: "\e930";
 $rs-icon-form: "\e931";
 $rs-icon-gitter_round: "\e932";
 $rs-icon-gitter: "\e933";
 $rs-icon-image_annotation_grouped: "\e934";
 $rs-icon-image_annotation-path1: "\e935";
 $rs-icon-image_annotation-path2: "\e936";
 $rs-icon-image_media: "\e937";
 $rs-icon-image_upload: "\e938";
 $rs-icon-image: "\e939";
 $rs-icon-images: "\e93a";
 $rs-icon-individual_inf: "\e93b";
 $rs-icon-influence: "\e93c";
 $rs-icon-keyword: "\e93d";
 $rs-icon-line: "\e93e";
 $rs-icon-logout: "\e93f";
 $rs-icon-mail_send_light: "\e940";
 $rs-icon-mail_send: "\e941";
 $rs-icon-mail: "\e942";
 $rs-icon-map_location_grouped: "\e943";
 $rs-icon-map_location-path1: "\e944";
 $rs-icon-map_location-path2: "\e945";
 $rs-icon-map_location-path3: "\e946";
 $rs-icon-map_location-path4: "\e947";
 $rs-icon-map_location-path5: "\e948";
 $rs-icon-map_location-path6: "\e949";
 $rs-icon-map_location-path7: "\e94a";
 $rs-icon-map_location-path8: "\e94b";
 $rs-icon-map_location-path9: "\e94c";
 $rs-icon-map_location-path10: "\e94d";
 $rs-icon-map_location-path11: "\e94e";
 $rs-icon-map_location-path12: "\e94f";
 $rs-icon-map_location-path13: "\e950";
 $rs-icon-map_location-path14: "\e951";
 $rs-icon-map_location-path15: "\e952";
 $rs-icon-map_location-path16: "\e953";
 $rs-icon-map_location-path17: "\e954";
 $rs-icon-map_location-path18: "\e955";
 $rs-icon-map_location-path19: "\e956";
 $rs-icon-material: "\e957";
 $rs-icon-media: "\e958";
 $rs-icon-minus: "\e959";
 $rs-icon-narrative_edit: "\e95a";
 $rs-icon-notification: "\e95b";
 $rs-icon-page: "\e95c";
 $rs-icon-place: "\e95d";
 $rs-icon-placeholder_book: "\e95e";
 $rs-icon-placeholder_design: "\e95f";
 $rs-icon-placeholder_impression: "\e960";
 $rs-icon-placeholder_letter: "\e961";
 $rs-icon-placeholder_painting: "\e962";
 $rs-icon-placeholder_woodblock: "\e963";
 $rs-icon-plus: "\e964";
 $rs-icon-point: "\e965";
 $rs-icon-points: "\e966";
 $rs-icon-researchspace: "\e967";
 $rs-icon-resize_border: "\e968";
 $rs-icon-restart: "\e969";
 $rs-icon-save: "\e96a";
 $rs-icon-search: "\e96b";
 $rs-icon-semantic_search: "\e96c";
 $rs-icon-set: "\e96d";
 $rs-icon-sidebar_left: "\e96e";
 $rs-icon-sidebar_right: "\e96f";
 $rs-icon-sparql: "\e970";
 $rs-icon-template_concept: "\e971";
 $rs-icon-template_event: "\e972";
 $rs-icon-template_filter: "\e973";
 $rs-icon-template_object: "\e974";
 $rs-icon-template_people: "\e975";
 $rs-icon-template_place: "\e976";
 $rs-icon-terminal: "\e977";
 $rs-icon-thing: "\e978";
 $rs-icon-tutorial: "\e979";
 $rs-icon-twitter: "\e97a";
 $rs-icon-type: "\e97b";
 $rs-icon-upload: "\e97c";
 $rs-icon-user_profile-path1: "\e97d";
 $rs-icon-user_profile-path2: "\e97e";
 $rs-icon-user_round: "\e97f";
 $rs-icon-user: "\e980";
 $rs-icon-vertical_dots: "\e981";
 $rs-icon-youtube: "\e982";


.rs-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'rs-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rs-icon-publication_no-image {
  &:before {
    content: $rs-icon-publication_no-image; 
  }
}
.rs-icon-publication {
  &:before {
    content: $rs-icon-publication; 
  }
}
.rs-icon-text-citation_no-image {
  &:before {
    content: $rs-icon-text-citation_no-image; 
  }
}
.rs-icon-text-citation {
  &:before {
    content: $rs-icon-text-citation; 
  }
}
.rs-icon-drop_resource_hand {
  &:before {
    content: $rs-icon-drop_resource_hand; 
  }
}
.rs-icon-drop_resource_item {
  &:before {
    content: $rs-icon-drop_resource_item; 
  }
}
.rs-icon-actor_no-image {
  &:before {
    content: $rs-icon-actor_no-image; 
  }
}
.rs-icon-annotations_no-image {
  &:before {
    content: $rs-icon-annotations_no-image; 
  }
}
.rs-icon-assertion_no-image {
  &:before {
    content: $rs-icon-assertion_no-image; 
  }
}
.rs-icon-belief_no-image {
  &:before {
    content: $rs-icon-belief_no-image; 
  }
}
.rs-icon-class_no-image {
  &:before {
    content: $rs-icon-class_no-image; 
  }
}
.rs-icon-concept_no-image {
  &:before {
    content: $rs-icon-concept_no-image; 
  }
}
.rs-icon-date_no-image {
  &:before {
    content: $rs-icon-date_no-image; 
  }
}
.rs-icon-diagram_no-image {
  &:before {
    content: $rs-icon-diagram_no-image; 
  }
}
.rs-icon-entity_no-image {
  &:before {
    content: $rs-icon-entity_no-image; 
  }
}
.rs-icon-event_no-image {
  &:before {
    content: $rs-icon-event_no-image; 
  }
}
.rs-icon-individual_inf_no-image {
  &:before {
    content: $rs-icon-individual_inf_no-image; 
  }
}
.rs-icon-influence_no-image {
  &:before {
    content: $rs-icon-influence_no-image; 
  }
}
.rs-icon-material_no-image {
  &:before {
    content: $rs-icon-material_no-image; 
  }
}
.rs-icon-narrative_no-image {
  &:before {
    content: $rs-icon-narrative_no-image; 
  }
}
.rs-icon-no-image {
  &:before {
    content: $rs-icon-no-image; 
  }
}
.rs-icon-place_no-image {
  &:before {
    content: $rs-icon-place_no-image; 
  }
}
.rs-icon-set_no-image {
  &:before {
    content: $rs-icon-set_no-image; 
  }
}
.rs-icon-thing_no-image {
  &:before {
    content: $rs-icon-thing_no-image; 
  }
}
.rs-icon-type_no-image {
  &:before {
    content: $rs-icon-type_no-image; 
  }
}
.rs-icon-actor {
  &:before {
    content: $rs-icon-actor; 
  }
}
.rs-icon-add {
  &:before {
    content: $rs-icon-add; 
  }
}
.rs-icon-advanced_search {
  &:before {
    content: $rs-icon-advanced_search; 
  }
}
.rs-icon-annotation {
  &:before {
    content: $rs-icon-annotation; 
  }
}
.rs-icon-area_annotation {
  &:before {
    content: $rs-icon-area_annotation; 
  }
}
.rs-icon-assertion {
  &:before {
    content: $rs-icon-assertion; 
  }
}
.rs-icon-belief {
  &:before {
    content: $rs-icon-belief; 
  }
}
.rs-icon-caret_down {
  &:before {
    content: $rs-icon-caret_down; 
  }
}
.rs-icon-chevron_down {
  &:before {
    content: $rs-icon-chevron_down; 
  }
}
.rs-icon-citation {
  &:before {
    content: $rs-icon-citation; 
  }
}
.rs-icon-class {
  &:before {
    content: $rs-icon-class; 
  }
}
.rs-icon-close {
  &:before {
    content: $rs-icon-close; 
  }
}
.rs-icon-concept {
  &:before {
    content: $rs-icon-concept; 
  }
}
.rs-icon-date {
  &:before {
    content: $rs-icon-date; 
  }
}
.rs-icon-delete_round_2 {
  &:before {
    content: $rs-icon-delete_round_2; 
  }
}
.rs-icon-delete_round {
  &:before {
    content: $rs-icon-delete_round; 
  }
}
.rs-icon-delete {
  &:before {
    content: $rs-icon-delete; 
  }
}
.rs-icon-dev_documentation {
  &:before {
    content: $rs-icon-dev_documentation; 
  }
}
.rs-icon-diagram {
  &:before {
    content: $rs-icon-diagram; 
  }
}
.rs-icon-documentation {
  &:before {
    content: $rs-icon-documentation; 
  }
}
.rs-icon-drag_arrow {
  &:before {
    content: $rs-icon-drag_arrow; 
  }
}
.rs-icon-drag_hand {
  &:before {
    content: $rs-icon-drag_hand; 
  }
}
.rs-icon-drag_points {
  &:before {
    content: $rs-icon-drag_points; 
  }
}
.rs-icon-drop_resource {
  &:before {
    content: $rs-icon-drop_resource; 
  }
}
.rs-icon-edit {
  &:before {
    content: $rs-icon-edit; 
  }
}
.rs-icon-entity {
  &:before {
    content: $rs-icon-entity; 
  }
}
.rs-icon-event {
  &:before {
    content: $rs-icon-event; 
  }
}
.rs-icon-expand_media {
  &:before {
    content: $rs-icon-expand_media; 
  }
}
.rs-icon-folder {
  &:before {
    content: $rs-icon-folder; 
  }
}
.rs-icon-form_edit {
  &:before {
    content: $rs-icon-form_edit; 
  }
}
.rs-icon-form {
  &:before {
    content: $rs-icon-form; 
  }
}
.rs-icon-gitter_round {
  &:before {
    content: $rs-icon-gitter_round; 
  }
}
.rs-icon-gitter {
  &:before {
    content: $rs-icon-gitter; 
  }
}
.rs-icon-image_annotation_grouped {
  &:before {
    content: $rs-icon-image_annotation_grouped; 
  }
}

.rs-icon-image_media {
  &:before {
    content: $rs-icon-image_media; 
  }
}
.rs-icon-image_upload {
  &:before {
    content: $rs-icon-image_upload; 
  }
}
.rs-icon-image {
  &:before {
    content: $rs-icon-image; 
  }
}
.rs-icon-images {
  &:before {
    content: $rs-icon-images; 
  }
}
.rs-icon-individual_inf {
  &:before {
    content: $rs-icon-individual_inf; 
  }
}
.rs-icon-influence {
  &:before {
    content: $rs-icon-influence; 
  }
}
.rs-icon-keyword {
  &:before {
    content: $rs-icon-keyword; 
  }
}
.rs-icon-line {
  &:before {
    content: $rs-icon-line; 
  }
}
.rs-icon-logout {
  &:before {
    content: $rs-icon-logout; 
  }
}
.rs-icon-mail_send_light {
  &:before {
    content: $rs-icon-mail_send_light; 
  }
}
.rs-icon-mail_send {
  &:before {
    content: $rs-icon-mail_send; 
  }
}
.rs-icon-mail {
  &:before {
    content: $rs-icon-mail; 
  }
}
.rs-icon-map_location_grouped {
  &:before {
    content: $rs-icon-map_location_grouped; 
  }
}

.rs-icon-material {
  &:before {
    content: $rs-icon-material; 
  }
}
.rs-icon-media {
  &:before {
    content: $rs-icon-media;
  }
}
.rs-icon-minus {
  &:before {
    content: $rs-icon-minus;
  }
}
.rs-icon-narrative_edit {
  &:before {
    content: $rs-icon-narrative_edit;
  }
}
.rs-icon-notification {
  &:before {
    content: $rs-icon-notification;
  }
}
.rs-icon-page {
  &:before {
    content: $rs-icon-page; 
  }
}
.rs-icon-place {
  &:before {
    content: $rs-icon-place; 
  }
}
.rs-icon-placeholder_book {
  &:before {
    content: $rs-icon-placeholder_book; 
  }
}
.rs-icon-placeholder_design {
  &:before {
    content: $rs-icon-placeholder_design; 
  }
}
.rs-icon-placeholder_impression {
  &:before {
    content: $rs-icon-placeholder_impression; 
  }
}
.rs-icon-placeholder_letter {
  &:before {
    content: $rs-icon-placeholder_letter; 
  }
}
.rs-icon-placeholder_painting {
  &:before {
    content: $rs-icon-placeholder_painting; 
  }
}
.rs-icon-placeholder_woodblock {
  &:before {
    content: $rs-icon-placeholder_woodblock; 
  }
}
.rs-icon-plus {
  &:before {
    content: $rs-icon-plus; 
  }
}
.rs-icon-point {
  &:before {
    content: $rs-icon-point; 
  }
}
.rs-icon-points {
  &:before {
    content: $rs-icon-points; 
  }
}
.rs-icon-researchspace {
  &:before {
    content: $rs-icon-researchspace; 
  }
}
.rs-icon-resize_border {
  &:before {
    content: $rs-icon-resize_border; 
  }
}
.rs-icon-restart {
  &:before {
    content: $rs-icon-restart; 
  }
}
.rs-icon-save {
  &:before {
    content: $rs-icon-save; 
  }
}
.rs-icon-search {
  &:before {
    content: $rs-icon-search; 
  }
}
.rs-icon-semantic_search {
  &:before {
    content: $rs-icon-semantic_search; 
  }
}
.rs-icon-set {
  &:before {
    content: $rs-icon-set; 
  }
}
.rs-icon-sidebar_left {
  &:before {
    content: $rs-icon-sidebar_left; 
  }
}
.rs-icon-sidebar_right {
  &:before {
    content: $rs-icon-sidebar_right; 
  }
}
.rs-icon-sparql {
  &:before {
    content: $rs-icon-sparql; 
  }
}
.rs-icon-template_concept {
  &:before {
    content: $rs-icon-template_concept; 
  }
}
.rs-icon-template_event {
  &:before {
    content: $rs-icon-template_event; 
  }
}
.rs-icon-template_filter {
  &:before {
    content: $rs-icon-template_filter; 
  }
}
.rs-icon-template_object {
  &:before {
    content: $rs-icon-template_object; 
  }
}
.rs-icon-template_people {
  &:before {
    content: $rs-icon-template_people; 
  }
}
.rs-icon-template_place {
  &:before {
    content: $rs-icon-template_place; 
  }
}
.rs-icon-terminal {
  &:before {
    content: $rs-icon-terminal; 
  }
}
.rs-icon-thing {
  &:before {
    content: $rs-icon-thing; 
  }
}
.rs-icon-tutorial {
  &:before {
    content: $rs-icon-tutorial; 
  }
}
.rs-icon-twitter {
  &:before {
    content: $rs-icon-twitter; 
  }
}
.rs-icon-type {
  &:before {
    content: $rs-icon-type; 
  }
}
.rs-icon-upload {
  &:before {
    content: $rs-icon-upload; 
  }
}
.rs-icon-user_profile .path1 {
  &:before {
    content: $rs-icon-user_profile-path1;  
    color: $color-white;
  }
}
.rs-icon-user_profile .path2 {
  &:before {
    content: $rs-icon-user_profile-path2;  
    margin-left: -1em;
  }
}
.rs-icon-user_round {
  &:before {
    content: $rs-icon-user_round; 
  }
}
.rs-icon-user {
  &:before {
    content: $rs-icon-user; 
  }
}
.rs-icon-vertical_dots {
  &:before {
    content: $rs-icon-vertical_dots; 
  }
}
.rs-icon-youtube {
  &:before {
    content: $rs-icon-youtube; 
  }
}