/**
 * ResearchSpace
 * Copyright (C) 2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.holder {
  position: relative;
}

.textInput {
  display: flex;
  flex: auto;
  align-items: center;
  border-right: 0;
  min-height: $input-height;

  :global(.RemovableBadge) {
    margin: 3px 4px;
  }
}

.input {
  height: 30px;
}

.searchSpinner {
  margin: 10px 0;
}

.error::after {
  content: 'error';
  font-size: smaller;
  color: $color-white;
  background: $color-danger;
  padding: 0 5px;
  margin: 0 3px;
}

.searchMessage {
  text-align: center;
  display: block;
  margin: 10px;
  color: $color-dark;
}