/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@import '~basic-styles.scss';

.object-representations {

  &__images {
    display: flex;
    flex-wrap: wrap;

    padding-top: 10px;

    &.fade-in-appear {
      opacity: 0.01;
    }

    &.fade-in-appear.fade-in-appear-active {
      opacity: 1;
      transition: opacity 0.3s ease-in;
    }
  }

  &__image--rep {
    max-width: 50px;
    max-height: 45px;
    height: auto;
    margin: 10px 8px;
    cursor: pointer;
    float: left;
    object-fit: contain;
  }

  &__image--active {
    outline: solid $color-secondary;
    padding: 1px;
  }

  &__image--focused {
    min-width: 100px;
    max-width: 100%;
    width: 100%;
    
    background: $color-light;
    border-radius: $border-radius;
    background-size: contain;
    background-repeat: no-repeat;
    transition: background-image 0.5s ease-out;
    cursor: pointer;

    //to decide
    //height: 280px !important;

    .rs-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      font-size: 7em;
    }

    & div:first-of-type {

      //min-height: 190px;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: baseline;
    }
    
    .image--incoming {
      max-width: 300px;
      position: relative;
    }

    .image--focused {

      position: relative;

      min-height: 130px;
      max-height: 280px;
      max-width: 100%;
      object-fit: contain;

      .rs-icon {
        font-size: 8em;
      }
    }

    .cross-fade-exit {
      opacity: 1;
    }

    .cross-fade-exit.cross-fade-exit-active {
      opacity: 0;
      transition: opacity 0.3s ease-in;
    }

    .cross-fade-enter {
      opacity: 0;
      position: absolute;
    }

    .cross-fade-enter.cross-fade-enter-active {
      opacity: 1;
      transition: opacity 0.3s ease-in;
    }
  }
}

.object-representations-modal {
  width: auto !important;

  .modal-body {
    padding: 0;
    user-select: none;

    height: 550px;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      height: 100% !important;
      width: auto !important;
      object-fit: contain;
    }
  }

  &__body {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100% !important;
    width: 100% !important;
    
  }

  &__nav {
    padding: 0 25px;
    font-size: 28px;
    color: $color-white;
    opacity: 0.8;
    cursor: pointer;

    text-shadow: 0 0 5px black;

    &.fade-in-enter {
      opacity: 0.01;
      transition: opacity 0.3s ease-in;
    }

    &.fade-in-enter.fade-in-enter-active {
      opacity: 0.4;
    }

    &.fade-in-exit {
      opacity: 0.4;
      transition: opacity 0.3s ease-in;
    }

    &.fade-in-exit.fade-in-exit-active {
      opacity: 0.01;
      transition: opacity 0.3s ease-in;
    }

    &.fade-in-appear {
      opacity: 0.01;
      transition: opacity 0.3s ease-in;
    }

    &.fade-in-appear.fade-in-appear-active {
      opacity: 0.4;
    }

    &:hover {
      opacity: 1;
    }
  }
}
