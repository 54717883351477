/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
 @import '~basic-styles.scss';

.FileVisualizer {
  width: 100%;
  height: 100%;
}

.DirectFileUploader {
/*   display: flex;
  flex-direction: column;
  width: 500px; */

  .row {
    display: flex;

    .rightInputBar {
      display: flex;
      padding-left: 35px;
      flex-direction: column;
      flex-grow: 1;

      label {
        margin-top: 10px;
        margin-bottom: 2px;
      }

      .storageInput {
        flex-grow: 0;
      }
    }
  }
}

.FileUploader {
  min-width: 200px;
  width: 40%;

  .progress {
    height: 15px;
    box-shadow: none;
    margin-bottom: 5px;
  }

  :global(.placeholder-item) { 

    text-align: center;
  
    :global(.upload_icon.material-icons-round),
    :global(.upload_icon.material-symbols-rounded)
     {
      font-size: 25px;
      padding-bottom: 10px;
      color: inherit;
    }
  }
  
}

:global(.semantic-form) {

  .FileUploader {

    width: 100%;

    :global(.dropzone-area) { 

      border-style: dashed;
      border-width: 1.5px;
      border-radius: $border-radius;
      border-color: $color-border;
      padding: 5px;
    }
  }
}

.FileManager,
.FileVisualizer {
  .alertComponent {
/*     position: absolute;
    width: calc(100% - 4px);
    font-size: 12px;
    color: $color-dark;
    height: 60px;
    left: 2px;
    bottom: 2px;
    opacity: 0.8;
    overflow: auto;
    border-top: 2px dashed $color-border;
    background-color: #fdfae8; */

    :global(.alert-component) {
      margin: 0;
    }
  }
}

.FileManager,
.FileVisualizer,
.FileUploader {
  position: relative;
  //cursor: pointer;

  .uploadedImageDepiction {

    border-radius: $input-border-radius;
    border: 1px solid $input-border-color;
    background-color: $input-color-bg;

    padding: 15px;
    height: 100%;
    text-align: center;
    display: block;

    img {
      max-height: 200px;
      min-height: 100px;
      max-width: 400px;
      object-fit: contain;
      //box-shadow: 0px 0px 7px 0px #585858; 
    }
  }
}

.FileManager {

}


.FileManagerUploaded {

/*   display: flex;
  position: relative;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%; */
  background: $color-body-bg;

  .fileContainer {

    display: flex;
    align-items: stretch;
    width: 100%;
    gap: 3px;
  }

  .fileNameContent {

    height: 40px;
    color: $input-color;
    font-size: $input-font-size;
    
    background-color: $input-color-bg;

    border-radius: $input-border-radius;
    border: 1px solid $input-border-color;

    padding: 0px 12px;

   // height: 100%;
    opacity: 0.7;
    cursor: not-allowed;
    word-break:break-all;
  }
}

.FileManagerContainer {

  width: 100%;

  .header {
    height: fit-content;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: $color-tf-dashboard-bg;

    .uploadedImageIri {

      display: flex;
      align-items: flex-start;
      gap: 5px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 10px 0;
      white-space: nowrap;

      border-radius: $border-radius;
      cursor: auto;

      :global(.material-icons-round),
      :global(.material-symbols-rounded) {
        color: inherit;
      }

      :global(.alert-component) {
        padding: 10px;
      }

    }
  }
}

.emptyBody,
.fileIcon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;

  .spinner {
    position: relative;
    display: flex;
    font-size: 26px;
    align-items: center;
    flex-direction: column;
    top: -10px;
    left: 0;
  }

  :global(.material-icons-round),
  :global(.material-symbols-rounded) {
    color:inherit;
    font-size: 80px;
  }
}

.uploadCompletedPlaceholder {
  background-color: #ebffeb;
}

.selectorHolder {
  width: 100%;
}

.selectorContainer {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: $border-radius;
  background-color: $color-lighter;
}

.urlInputHolder {
  display: flex;
  gap: 3px;
  width: 100%;

  :global(button.btn) {
    margin-top: 0 !important;
    flex: 1;
  }

  :global(.form-control) {
    border: 1px solid $input-border-color;
  }
}

.storageInput {

  :global(.btn) {

   line-height: 0.9;
  }
}