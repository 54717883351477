/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @author Artem Kozlov <ak@metaphacts.com>
 * @author Alexey Morozov
 */

@import '~basic-styles.scss';
@import '../commons/variables.scss';

.body {
  flex: 1 1 100%;
  margin-bottom: 20px;
  position: relative;
}

.tools {
  position: relative;
}

.map {
  height: 500px;
  width: 100%;
  margin-bottom: 20px;
}

.btnsWrap {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 1;
}

.toolsBtn {
  display: block;
  margin: 1px;
  padding: 0;
  color: #000;
  font-size: 1.14em;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: 0.4em;
  background-color: #7b98bc;
  background-color: rgba(0, 60, 136, 0.5);
  border: none;
  border-radius: 2px;
}

.btnActive {
  background-color: rgba(44, 128, 27, 0.75);
}

.toolsBtnSquare {
  composes: toolsBtn;
  @include icon(square-o);
  border-radius: 2px 2px 0 0;
}

.toolsBtnCircle {
  composes: toolsBtn;
  @include icon(circle-o);
  border-radius: 0 0 2px 2px;
}
