/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.component {
  /**/
}

.textEditor {
  display: flex;
}

.addAnnotationSideline {
  position: relative;
  min-width: 30px;
  margin: 0 5px;
}

.addAnnotationButton {
  position: absolute;
  margin: -15px 0 0 0;

  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-content: center;
  justify-content: center;
}

.addAnnotationIcon {
  background-image: url('./add-annotation.png');
  background-repeat: no-repeat;
  background-size: 22px 22px;
  width: 22px;
  height: 22px;
}

.pointAnnotation {
  display: inline-block;
  width: 15px;
  margin: 0 2px;
  border-radius: 10px;
}
