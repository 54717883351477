// KM left sidebar - instances area - search

.ontodia-instances-search {
  flex: auto;
  display: flex;
  flex-direction: column;

  &__criteria {
    flex-shrink: 0;
    margin: 10px 0 10px 0;
  }

  &__criterions {
    padding-left: 15px;
    margin-bottom: 10px;
    &:empty { margin-bottom: 0; }
  }

  &__criterion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__criterion-row {

    flex: 1;
    border-bottom: 1px solid $input-border-color;
    padding: 8px;
  }

  &__criterion-class {
    border: $color-dark 1px dashed;
    background: $color-action--op30;
    padding: 1px 0.5em;
    word-wrap: break-word;
    font-weight: 600;
    margin-left: 3px;
  }

  &__criterion-element {
    border: $color-dark 1px dashed;
    background: rgb(255, 131, 92);
    padding: 0 .5em 0 .5em;
    word-wrap: break-word;
  }

  &__criterion-link-type {
    border: $color-dark 1px dashed;
    background: rgb(202, 255, 206);
    padding: 0 .5em 0 .5em;
    word-wrap: break-word;
  }

  &__link-direction {
    height: 1em;
    opacity: 0.5;
  }

  &__criterion-remove {
    float: right;

    &.ontodia-btn-group.ontodia-btn-group-xs .ontodia-btn-default {
      color: $color-dark;
      background-color: transparent;
      border: none;
    }
  }

  &__text-criteria { 
    margin-left: 10px; 

    .ontodia-btn-default {
      background-color: transparent;
      border: none;
      padding: 0 7px;

    //  border: 1px solid $input-border-color;
    }
  }

  &__rest {
    padding: 5px 10px 0 10px;
  //  border-top: 1px solid rgb(221, 221, 221);
  }

  &__results {
    padding-left: 0;
    padding-top: 10px;
    margin: 0 10px 0 10px;
    outline: none;
  }

  &[data-state='finished'] &__results:empty:before {
    content: 'No items correspond to the specified criteria.';
    font-style: italic;
  }

  &__rest-end { margin: 5px 0 10px 0; }
  
  &__load-more {
    width: 100%;
    justify-content: center;
  }
}
