/**
 * ResearchSpace
 * Copyright (C) 2024, PHAROS: The International Consortium of Photo Archives
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';



.holder {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;

  height: 100%;
}

.column {
  padding: 10px; /* Spacing inside each column */
  border: 1px solid #ccc; /* Border for visual separation */

  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.fileInputHolder {
  display: flex;
  margin-bottom: $padding-base-vertical;

  :global {
    /* To make buttons the same height as inputs */
    .form-control, .btn, div.Select {
        height: 38px;
    }

    div.Select {
      flex: 1;

      .Select-value-label {
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 20px;
      }
    }
  }
}

.actionButton, .inputButton  {
  margin-left: $padding-base-horizontal;
  display: flex;
  align-items: center;
}

.inputButton {
  // we want buttons to be aligned with the input field
  margin-left: 0;
}

.button {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;

  /**
  :global {
    i {
      color: #ffffff;
    }
  }
  */
}

.rdfEditorAreaHolder {
  display: flex; /* Enable flex layout */
  flex-direction: column; /* Stack children vertically */
  height: 100%; /* Keeps existing height */

  flex-grow: 1;
  overflow: hidden;
}

.mergeView {
  :global {
    .CodeMirror-merge-r-chunk {
      background-color: rgba(215, 212, 240, 0.2);
    }

    .CodeMirror-merge-r-deleted, .CodeMirror-merge-l-deleted {
      background-color: rgba(217, 83, 79, 0.7);
    }

    .CodeMirror-merge-r-inserted, .CodeMirror-merge-l-inserted {
      background-color: rgba(92, 184, 92, 0.7);
    }
  }
}

.associationTableView {
  :global {
    table {
      margin: 0;
    }

    .table-responsive {
      max-height: 300px;
    }
  }
}

.associationTableResultHeader {
  font-weight: bold;
  font-style: italic;
}

.associationTableViewItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
