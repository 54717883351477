/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

:global {
  .docson {
    font-family: $font-family-base;
    font-size: $font-size-base;

    .required::after {
      font-weight: bold;
      content: '*';
      vertical-align: super;
      font-size: xx-small;
    }

    .property-name {
      min-width: 250px;
    }

    .desc {
      line-height: $line-height-base;
    }

    .box-body {

      padding: 0;
      > .signature:nth-of-type(odd) {
        background-color: $color-lighter;
      }
    }

    .box {
      border-radius: 0;
      box-shadow: none;
      float: initial;
      border: 1px solid $color-configDoc-border;
    }

    .box-1 {
      background: $color-white;
    }

    .box-2 {
      background: beige;
    }

    .box-3 {
      background: azure;
    }

    .box-4 {
      background: mistyrose;
    }

    .box-5 {
      background: #ddffee;
    }

    .box-6 {
      background: ghostwhite;
    }

    .box-7 {
      background: lavenderblush;
    }

    .box-8 {
      background: aliceblue;
    }

    .box-title {
      display: flex;
    }

    .box-name {
      padding: 0;
      float: initial;
      padding-right: $padding-base-horizontal;
    }

    .type-keyword {
      font-size: inherit;
      font-style: italic;
      margin-right: 2px;
    }

    .type-enum {
      padding: 4px;
    }

    .source-button {
      display: none !important;
    }

    .expand-button {
      margin-top: 0;
    }

    .box-header {

      border-color: $color-configDoc-border;
      background-color: $color-configDocHeader-bg;
    }

    .box-description {
      max-width: none;

      color: $color-dark;
    }

    .signature {

      padding: 20px;
    }

    .signature-type-ref {
      color: $color-dark;
    }

    .signature-type {
      max-width: initial;
      min-width: 200px;
      float: initial;
      max-width: initial;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .signature-description {
      max-width: none;

      color: $color-dark;

      p, & > div p {
        margin-block-start: 0 !important;
        margin: 0;
      }
    }

    .signature-header {
      padding-top: initial;
      height: initial;
      line-height: $line-height-base;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .signature-button {
      border-radius: 4px;
      padding: 8px 12px;
      border-color: $input-border-color;
      font-weight: 600;
    }

    .signature-box-container {
      display: flex;
      flex-direction: column;
    }

    .typingsRequiredLabel {
      font-style: italic;
      padding-left: 6px;
    }
  }
}
