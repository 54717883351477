.ontodia-element-layer {

  z-index: 0;
}


.ontodia-overlayed-element {
  cursor: move;
  outline: none;

  &:hover,
  &:focus,
  &:focus-within {
    .resource-card__button {
      opacity: 1;
    }
  }
}

.ontodia-overlayed-element,
.ontodia-exported-element {
  // set defaults for all inherited properties
  box-sizing: border-box;
  color: $color-dark;
  font-size: 14px;
  line-height: 1.2;

  // http://stackoverflow.com/questions/6664460/line-height-affects-images
  img { vertical-align: middle; }
}

.ontodia-exported-element {
  *, *:before, *:after {
    box-sizing: inherit;
  }
}

.ontodia-overlayed-element--blurred {
  filter: grayscale(100%);
  opacity: 0.5;
}


.ontodia-overlayed-element__resizable-handle {
  position: absolute;
 /*  background-image: url("../images/resizeHandle.svg");
  background-size: 9px auto;
  background-repeat: no-repeat;
  background-origin: content-box;
  width: 10px;
  height: 10px; */
  touch-action: none;
  cursor: se-resize;
  bottom: 5px;
  right: 5px;
  pointer-events: visible;
  border-style: solid;
  border-width: 0 0 12px 12px;
  border-color: transparent transparent $color-action--op60 transparent;
 
}


