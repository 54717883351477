/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
  
.modal-container {
    .modal,
    .modal-backdrop {
        position: absolute;
    }
}
  
 
 /* Modal */
.modal .close, 
.panel-default .close {

    color: $color-btn-default;
    opacity: 1;

    &:focus {

        outline: none;
    }
}

.modal-backdrop {
    background-color: $color-secondary;

    &.in {
        opacity: 0.8;
    }
}

.overlay-lightbox.fade.in.modal {

    background-color: $color-secondary;
}


.modal-xlSize {

    &.fade.in.modal {
  
        display: flex !important;
        padding: 0 !important;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        @media screen and (max-width: 1024px) {
            overflow-y: auto;
        }
    }
      
    & .modal-dialog {
        
    width: 95%;
    max-width: 95%;

    height: 95vh;
    max-height: 95vh;
    
    margin: 0 !important;

    @media screen and (max-width: 1024px) {
        
        height: auto;
        max-height: auto;
        margin: 20px 0;
    }
    }

    .modal-content {

        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .modal-body {
        overflow: auto;
    }

    &.preview_modal .modal-body {
        flex: 1;
        display: flex;
        gap: 20px;
        align-items: flex-start;
        padding-top: 10px;
        height: 100%;
        min-height: unset;

        .preview-media-noResults {

            height: 100%;
            width: 100%;

            .modal-resource-details {
                width: 100%;
                height: auto;
            }
        }

        .preview-media-tabs {
            flex: 1;
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;
            
            ul {
                margin: 0;
                margin-bottom: 10px;

                &:empty {
                    border: none;
                    margin: 0;
                }
            }

            .tab-content {
                flex: 1;
                height: 100%;

                .tab-pane {
                    height: 100%;
                    padding: 0;
                }
            }
        }

        .modal-preview-container {
            height: 100%;
            width: 100%;
            display: grid;
            grid-template-columns: 4fr minmax(0, 1fr);
            align-items: center;
            justify-content: center;
            gap: 5px;

            @media screen and (max-width: 1024px) {
                display: flex;
                flex-direction: column;
                align-items: stretch;
            }

            div:only-child {
                grid-column: span 2;
              }
        }

        .modal-image-thumbnail-container,
        .modal-resource-persisted-container {
            padding: 20px 30px;
            border: 1px solid $color-border;
            background-color: $color-white;
            height: 100%;

            & .semantic-chart,
            & .semantic-chart__renderer {
              height: 100% !important;
            }

            @media screen and (max-width: 1024px) {
                height: 50vh;
            }
        }

        .modal-image-thumbnail-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .modal-image-container {

            img {

                object-fit: contain;
                height: auto;
                max-height: 75vh;
            }

            .modal-iiif-container {

                height: 100%;
                width: 100%;
                max-width: 100%;
                overflow: hidden;

                @media screen and (max-width: 1024px) {

                    height: 50vh;
                }
            }
        }

        .modal-video-container {

            height: unset;
            align-items: stretch;

            &.modal-single-video-container {
                height: 100%;
            }

            .modal-videos {

                border: 1px solid $color-border;
                background-color: $color-white;

                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                padding: 20px 30px;
    
             //   height: 100%; 
                width: 100%;
                overflow: auto;

                @media screen and (max-width: 1024px) {
                    height: 50vh;
                }

                div {
                    height: 230px;
                    width: 358px;
                }
            }

            .modal-single-video {

                border: 1px solid $color-border;
                background-color: $color-white;

                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                padding: 20px 30px;
    
                height: 100%; 
                width: 100%;
                overflow: auto;

                @media screen and (max-width: 1024px) {
                    height: 50vh;
                }

                div {
                    height: 230px;
                    width: 358px;
                }
            }

            .modal-resource-details {
                align-self: stretch;
                height: auto;
            }
        }

        .modal-map-container {

            .modal-map {
                border: 1px solid $color-border;
                background-color: $color-white;
    
                height: 100%; 
                width: 100%;

                @media screen and (max-width: 1024px) {
                    height: 50vh;
                }
            }
        }

        .modal-resource-details {

            height: 100%;
            padding: 20px 30px;
            border: 1px solid $color-border;
            background-color: $color-white;

            @media screen and (max-width: 1024px) {
                padding: 20px;
            }
        }

        .btn-primary {

            white-space: break-spaces;
            text-align: justify;
            margin-top: 25px;
            border-radius: 0;
            font-weight: 600;
            letter-spacing: .5px;
            font-size: 14px;
            padding: 8px 14px;


            @media screen and (max-width: 1024px) {

                margin-top: 15px;
            }
        }
    }
}

.modal-centered__dialog {

    display: flex !important;
    align-items: center;
    min-height: calc(100% - 0rem);
    margin: 0 auto;

    .modal-content {

        flex: 1;
    }
}

.modal-dialog {
    overflow: auto;
}

.modal-content {

    background: $color-modal-bg;
    border-color: $color-medium;

    .modal-header {

        padding: 18px 25px 14px;
        border-bottom: 1px solid $color-border;
    }

    .modal-title {

        font-size: 18px;
        text-transform: lowercase;

        &::first-letter {
     
            text-transform: uppercase;
        }
    }
}

.overlay-lightbox__dialog .modal-content {

    background: $color-body-bg;
}

.modal-body {

    #template-content {

        min-height: 100%;
    }

    padding: 25px;

    & > p:last-child {

        margin-bottom: 0px;
    }

    .card-modal-tabs {

        width: 100%;
        height: inherit;

        .nav-tabs {
            border: none;
            display: flex;
            justify-content: center;
        }

        .nav-tabs > li {

            font-size: 16px;
            width: 100px;
            text-align: center;
        }
    
        .nav-tabs > li > a {

            margin-right: 0px;
        }

        .tab-content {
            padding: 30px 0;
        }

        #tab-container-pane-images,
        #tab-container-pane-videos {

            max-width: 55vw;
            width: 100%;
            padding: 0;
            margin: 0;
        }

        .no-media {

            padding-top: 10px;
            text-align: center;
            font-size: 15px;
            font-style: italic;
        }

        .semantic-carousel {
            height: 100%;
            width: 100%;
        }

        .carousel-container {

            display: flex;
            justify-content: center;
            height: 45vh;
            max-width: 100%;
        }

        img {
            
            height: 100%;
            width: 100%;
        }

        .semantic-carousel>.slick-slider,
        .slick-list, 
        .slick-track,
        .slick-slide {

            height: 100%;
        }

        iframe {

            height: 100%;
            width: 100%;
        }

        .semantic-carousel>.slick-slider {
            width: 100%;
            margin-left: 0%;
            margin-right: 0%;
        }

        .semantic-carousel>.slick-slider .slick-next::before, 
        .semantic-carousel>.slick-slider>.slick-prev::before {

            color: $color-btn-default;
            opacity: 1;

            &:hover,
            &:focus {

                color: $color-btn-default;
            }
        }

        .slick-prev, 
        .slick-next {

            z-index: 500;
          
            height: 35px;
            width: 35px;
          
            background: $color-white;
            border-radius: 50%;
            border: 1px solid $color-base;

            &:hover {

                border: 1px solid $color-secondary;
            }
          
          }

        .slick-prev::before {
            content: "\f104";
          }
          
          .slick-next::before {
            content: "\f105";
          }

          .slick-next {
            right: 16px;
          }
          
          .slick-prev {
            left: 16px;
          }

          .slick-dots {

            top: unset;
            bottom: -35px;

            & li,
            & li button,
            & li button::before {

                height: 25px;
                width: 16px;
            }

            & li button::before {

                font-size: 12px;
                line-height: 25px;
                color: $color-dark;
            }

          }
          
          .slick-dots li.slick-active button::before {
            
            color:$color-dark;
          }
    }

   /*  Semantic form inside a modal */
    .form-inline {

        display: flex;
        align-items: baseline;
    }

    /* .newVocabulary-confirm-message {

        position: relative;
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    } */
    
    .SplitPane .split-pane__sidebar,
    .SplitPane .split-pane__sidebar .split-pane__sidebar-header {

        background-color: $color-modal-bg;
    }

}

.modal-btn-group {

    width: 100%;
    margin-top: 25px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
}

.modal-footer {

    border-top: 1px solid $color-lighter;
}

// documentation modal 
.modal-dialog.modal-documentation__dialog {
    
    width: 100%;
    height: 100%;

    max-width: 60vw;

    margin: 8vh auto !important;

    @media screen and (max-width: 767px) {

        max-width: 90vw;
    }

    @media (min-width: 768px) and (max-width: 1400px) {

        max-width: 75vw;
    }
}

.modal-documentation__body {

    display: grid;
    grid-template-columns: .3fr auto .3fr;

    width: 100%;
}

.modal-documentation-container {

    grid-column: 2 / 3;

    .modal-documentation-pdf {

        width: fit-content;
        display: flex;
        align-items: center;
        padding: 20px 0;
        color: $color-action;
        font-size: 15px;
        font-weight: 600;

        & > span {
            text-decoration: underline;
        }

        .iconmoon {
            padding-right: 5px;
            font-size: 19px;
            text-decoration: none;
            color: inherit;
        }
    }

    h2 {
        margin-bottom: 15px;
    }

    h3 {
        margin: 25px 3px 12px 3px;
    }

    iframe {

        height: 380px;
        width: 100%;
        margin-bottom: 5px;
    }
}

.save-as-dataset-modal {

    .save-as-dataset-modal__form__collection-name {

        flex: 1;
    }
}

.save-as-dataset-modal__form__save-button {

    margin-left: 3px;
}


.newVocabulary-modal__dialog,
.editVocabulary-modal__dialog {

  //  max-height: calc(100vh - 60px);
    overflow: hidden;

    .newVocabulary-modal__body.modal-body,
    .editVocabulary-modal__body.modal-body {

        padding-top: 0;
    }

    .vocabulary-search-form {

        display: flex;
        flex-direction: column;

/*         button {
            align-self: flex-end;
        } */
    }

    .vocabularyType-checklist-input-container {

        max-height: 35vh;
        overflow: auto;
    }

}

.importVocabulary-modal {

    .dropzone-area {

        height: auto;
    }
}

.newImageModal__body {

    height: 100%;
    padding-top: 0;

    .semantic-form {

        height: 100%;
    }

    .composite-input {

        display: flex;
        flex-direction: column;

        .form-scroll-area {
            flex: 1;
        }
    }
}
