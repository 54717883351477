$toolbarHeight: 30px;

.ontodia-toolbar {
  &__language-selector {
    margin-left: 5px;
    margin-right: 5px;

    > label {
      margin-right: 5px;
      > span { vertical-align: middle; }
    }

    > select {
      height: $toolbarHeight;
    }
  }

  &__toggle {
    line-height: 1;
  }

  &__toggle:after {
    background-position: 0 0;
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 15px;
    width: 12px;
  }

  &__toggle-left:after {
    background-image: url('../images/left-panel.svg');
  }

  &__toggle-right:after {
    background-image: url('../images/right-panel.svg');
  }

  &__toggle.active {
    border-color: #c3c3c3;
  }

  &__toggle-left.active:after {
    background-image: url('../images/left-panel-active.svg');
  }

  &__toggle-right.active:after {
    background-image: url('../images/right-panel-active.svg');
  }

  &__layout-group {
    float: left;
    margin-left: 5px;
    height: $toolbarHeight;
  
    > label {
      margin-right: 5px;
    }
  
    .btn-group,button {
      height: 100%;
    }
  }
}

.ontodia-toolbar .ontodia-toolbar__undo,
.ontodia-toolbar .ontodia-toolbar__redo {
  display: none;
}

