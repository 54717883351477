/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
/* User Page */
.user-page {
    height: 100%;
    min-height: calc(100vh - #{$nav-height}  - #{$breadcrumb-height});
}

.user-top-section {
    
    height: 300px;
    margin: 30px 0;

    .rs-button-icon-M--dark {
        position: absolute;
        right: 40px;
        top: 30px;
    }
}

.user-top-container {

    margin: 0;
    padding: 20px;
    height: 100%;
}

.user-btn__edit {

    position: absolute;
    right: 40px;
    top: 30px;

    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
   
    border: 1px solid #c5c3c3a3;
    padding: 5px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 0;
    background: $color-white;
    z-index: 1;

    &:hover {
        background: #e8e8e898;
        border: 1px solid #c5c3c3;
    }

    &:focus {

        outline: none;
    }
    
    .fa-pencil {

        color: #8e8d8d;
        font-size: 1.5em;
    }
}

.user-top-imgCol {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-top-imgContainer {

    height: 70%;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;

    .rs-icon-user_profile {

        font-size: 13.2em;

        .path1 {
            color: #e7eced;
        }

        .path2 {
            color: #556080;
        }
    }

    &:hover, &:focus, &:active, &:focus-within {
        
        .user-top-img,
        .rs-icon-user_profile {

            opacity: 0.7; 
        }
        
        .user-top-img__edit {
            display: block; 
        }
    }
}

.user-top-img {

    height: 100%;
}

.user-top-img__edit {
    display: none;
    position: absolute;
    bottom: 18px;
    opacity: 1;
}
 
.user-top-col {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.user-name {
 
    font-size: 27px;
    letter-spacing: 0.1px;
    padding-bottom: 7px;
}

.user-contacts {
    padding-top: 40px;
}

.user-jobPosition {

    font-size: 15px;
    color: #888080;
    font-style: italic;
}

.user-contact-title {

    font-weight: 700;
    padding-bottom: 5px;
}

.user-tabs {

    .nav-tabs > li {
        font-size: 16px;

        & > a {
            margin-right: 0px;
            padding: 4px 20px;
        }

        &:nth-child(4) {
            float: right;
        }
    }
}

.user-tab-section {
    margin-top: 8px;
    margin-bottom: 20px;
    padding: 50px 80px;
}

.user-tag-area {

    padding: 15px 0 30px 0;

    .user-tag:not(:last-child) {

        margin-right: 15px;
    }
}

.user-tag {

    color: $color-white;
    background: $color-dark;

    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    
    border: 1px solid $color-dark;
    border-radius: 10px;
    
    height: 30px;
    padding: 0px 15px;
    
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    a {
       color: $color-white;
    }
}

.user-summary {

    line-height: 1.4;
    text-align: justify;
    margin-bottom: 40px;
}

.user-current-position {

    .user-dataRow:last-child {

        margin-bottom: 30px;
    }
}

.user-tab-section > .user-previous-position {

    border: none;
    padding-top: 10px;
    padding-bottom: 20px;
}

.user-tab-section > .user-previous-position ~ .user-previous-position  {
    
    border-top: 1px solid #80808036;
    padding-top: 20px;
}

.user-dataRow {

    display: flex;
    width: 100%;
    padding: 10px;

    .user-dataRow__title {

        font-weight: 600;
        width: 10%;
    }

    .user-dataRow__value {

        width: 80%;
        text-align: justify;
    }

    .user-timespan-start,
    .user-timespan-end {

        display: flex;
    }

    .user-timespan-start {

        width: 30%;

        .user-dataRow__title {

            width: 40%;
        }
    }

    .user-timespan-end {

        width: 70%;

        .user-dataRow__title {

            width: 12%;
        }
    }
}

.user-project {

    padding-top: 10px;
}

@media (max-width: 1450px) {

    .user-dataRow {

        .user-dataRow__title {

            font-weight: 600;
            width: 20%;
        }

        .user-dataRow__value {

            width: 70%;
            text-align: justify;
        }

        .user-timespan-start,
        .user-timespan-end {

            width: 50%;
        }

        .user-timespan-start .user-dataRow__title {
    
                width: 46%;  
        }
        .user-timespan-end .user-dataRow__title {
    
            width: 25%;  
        }
    }

}



