/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
 $material-icons-font-path: '~material-icons/iconfont/';
 $material-symbols-font-path: '~material-symbols/';

@import './basic.scss';
@import url($web-font-path);
@import './bootstrap.scss';
@import 'material-symbols';
@import 'material-icons/iconfont/material-icons.scss';
@import './global.scss';


.set-management__set-item {
  .panel-body {
    .no-image-available .add-image-button {
      visibility: hidden;
    }

    &:hover {
      .no-image-available .add-image-button {
        visibility: visible;
      }
    }
  }
}

// Remove rounded borders on select elements
select:not([multiple]) {

  position: relative;
  color: $input-color;

  border-color: $input-border-color;
  background-color: $input-color-bg;

  height: $input-height;

  appearance: none;
  -webkit-appearance: none; 
  -moz-appearance: none;

  border-radius: $input-border-radius;
  padding: 0.5em 1em;
  min-width: max-content;

  background-image: linear-gradient(45deg, transparent 50%, $color-dark 50%), linear-gradient(135deg, $color-dark 50%, transparent 50%);
  background-position: calc(100% - 15px) calc(16px), calc(100% - 10px) calc(16px);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

}


