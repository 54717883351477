/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
/* Object template - header section */
.rs-resource-header {

    padding: 1.5em 7%;
    border-radius: $border-radius;
    box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.1);

    .rs-resource-header-topContainer {

        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap-reverse;

        border-bottom: 1px solid $color-dark;
        
    }

    .rs-resource-header-topContent {

        padding: 8px 8px 8px 2px;

        &:hover {
            outline: 1px dashed $color-dark;
           // outline-offset: 5px;
        }

        .icon-template {
            margin-bottom: 2px;
        }

        .rs-icon {
            font-size: 11px;
            padding-left: 5px;
        }
    }

    .rs-resource-header-topButtons {

        margin-bottom: 2px;

        button {

            border: none;
            background: transparent;
            padding: 5px 10px;
            border-radius: 3px;

            .rs-icon,
            .iconmoon {
                font-size: 1.5em;
            }

            &:focus {
                outline: none;
            }

            &:hover,
            &:focus {
                background-color: #f2f3f682;
            }
        }
    }

    .rs-resource-header-content-container {

        padding-top: 25px;
        width: 100%;

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 3%;
    }

    .rs-resource-carousel__container,
    .rs-resource-header--image-container {

        position: relative;

        .image-thumbnail {

            svg {

                max-width: inherit;
                max-height: inherit;
            }
        }

        .rs-resource-img__btngroup {
            
            /* background-color: #f2f3f682;
            position: absolute;
            border-radius:$border-radius;
            top: 0;
            left: 0;
            padding: 3px 0px;
            z-index: 100;
            justify-content: center;
            flex-direction: column; */

            display: flex;
            align-items: center;

            padding-bottom: 5px;

            .btn-icon {

                border-radius: $border-radius;
                height: 33px;
                padding: 0 10px;

                .rs-icon {

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.4em;

                    &.rs-icon-image {
                        font-size: 1.39em;
                    }
                }

                &:hover, 
                &:focus {
                    background-color: #f2f3f682;
                }
            }
        }
    }

    .rs-resource-carousel__container {

        padding: 0;
        margin-bottom: 15px;
        height: 100%;

        .semantic-carousel {
            max-width: 500px;

            .semantic-carousel-item {

                width: 100%;
                height: 241px;

                .rs-resource-carousel__img {

                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    max-height: 241px;
                    margin: 0 auto;
                }
            }

            .rs-resource-carousel__noimg-container {
                background: $color-light;
                display: flex;
                height: 241px;
                width: 95%;
                align-items: center;

                .rs-resource-carousel__noimg {

                    width: auto;
                    height: 110px;
                    margin: 0 auto;
                }
            }
        }

        .semantic-carousel>.slick-slider {
            width: 100%;
        }
    }

    &--title {
        margin-bottom: 5px;
    }

    &--ja-title {

        margin-bottom: 24px;
      }

    &--title-margin {
        margin-bottom: 24px;
    }

    a {
        text-decoration: underline;
    }

    //custom object-representations for resource page 
    //component style in src/main/web/scss/object-representations-widget.scss
    .object-representations-modal__container {
        width: 700px !important;
    }

    .object-representations__image--focused {
        height: 280px !important;
        background-color: transparent;
    }

    .object-representations-modal .modal-body img {
        padding: 10px;
    }
}

.rs-resource-header--singletitle div:last-child {
    margin-bottom: 15px;
}

/* Person template, including Wikidata */
.rs-wikiPerson-imgContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    margin-bottom: 20px;
}

.rs-wikiPerson-img {
    height: 230px;
    width: auto;
    max-width: 90%;
    max-height: 230px;
}

.rs-wikiSignature-img {
    height: 100px;
    width: auto;
    margin: 5px 0px;
}

.rs-resource-header--image-container {
    padding: 0;
    height: 100%;
}

