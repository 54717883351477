/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@import '~basic-styles.scss';

.component {
  /**/
}

.property-values-cell {
  display: flex;
  align-items: center;
}
.no-value {
  flex: auto;
  color: rgb(181, 178, 178);
  font-style: italic;
}
.property-values {
  flex: auto;
}
.property-value {
  margin-bottom: 4px;
  border-left: 5px solid $color-dark;
  padding-left: 5px;
}
.edit-value {
  align-self: center;
}

.values-editor {
  /**/
  //font-size: 15px;
}

.property-apps {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.property-app:not(:last-child) {
  margin-bottom: 7px;
}
