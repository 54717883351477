/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.mp-rdf-graph-action {

  cursor: pointer;

  &__download-dropdown-item {
    display: block;
  }

  &__download-dropdown {
    background-color: transparent;
    margin-top: -4px;
    border: none;
    color: $color-dark;
    @include fa-icon();
    &:before {
      content: $fa-var-download;
    }
  }

  &__hide {
    opacity: 0;
    visibility: hidden;
  }

  &__spinner-wrap {
    position: relative;
  }

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;

    .system-spinner__icon {
      padding: 0;
    }
  }
}

li .mp-rdf-graph-action {
  display: flex;
}
