.ontodia-badge {
  display: inline-block;
  min-width: 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: $color-white;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: $color-dark;
  padding: 3px 7px;
  border-radius: 10px;
}
