.ontodia-list-element-view {
  position: relative;
  display: block;
  background: #808080;
  padding: 1px 1px 1px 10px;
  border-radius: 2px;
  margin: 0 0 2px 0;
  opacity: 1.0;
  cursor: grab;
  user-select: none;

  &--disabled {
    opacity: 0.4;
    cursor: default;
  }

  &__label {
    background: $color-white;
    border-radius: 0 2px 2px 0;
    font-size: 14px;
    min-height: 1.3em;
    overflow-wrap: break-word;
    padding: 4px 5px 4px 7px;

    & > span {
      display: inline-block;
      max-width: 370px;
    }
  }
}

.ontodia-text-highlight {
  font-weight: bold;
}
