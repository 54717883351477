/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@import '~basic-styles.scss';

@mixin panelHolder {
  display: flex;
  flex-direction: column;
  &:global(.panel) {
    margin-bottom: 0;
  }
  > :global(.panel-body) {
    flex: 1 1 100%;
  }
}

// main component itself
.component {
  height: 100%;
}

.leftPanel {
  @include panelHolder;
  width: 100%;
  height: 100%;
}

.sourcePanel {
  /**/
}

.rightPanel {
  height: 100%;
  display: flex;
}

.targetHolder {
  flex: auto;
  @include panelHolder;
}

.targetPanel {
  /**/
}

.controlList {
  flex: auto;
  overflow-y: scroll;
  height: 0;
  margin: 5px 0 0 0;
}

.controlPanel {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.controlHolder {
  flex: 0 0 300px;
  @include panelHolder;
}

.controlToolbar {
  display: flex;
  justify-content: space-between;
}

.swapTerminologies {
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
