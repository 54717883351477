/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.holder {
  position: relative;

  // overlay holder
  & > div:not(.inputAndButtons) {
    position: absolute;
    width: 100%;
    z-index: $zindex-page-holder;
  }
}

.inputAndButtons {
  display: flex;
  align-items: center;

  min-height: $input-height;

  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  background-color: $input-color-bg;

  :global(.clearable-input) {

    background-image:none;
    border:none;
  }
}

.textInput {
  display: flex;
  flex-wrap: wrap;
  flex: auto;
  align-items: center;
  border-right: 0;

  &:global(.selection-empty) {

    color: $input-placeholder-color;
    padding: 0 10px;
  }

  :global(.RemovableBadge) {
    margin: 3px 4px;
  }
}

.dropdown {
  overflow: auto;
  border-radius: $border-radius;
  border: 1px solid $color-border;
 // border-top-width: 0;
  margin: 2px 0 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background: $input-color-bg;
  white-space: normal;

  :global(.clearable-input) {
     border: none;
     padding: 0;
   }

  :global(.clearable-input__input-with-clear) {
   // border: none;
    border-bottom: 1px solid $color-border;
  //  border-radius: 2px;
  //  margin-top: 10px;
  //  background-color: $color-off-white;

    :global(.clearable-input__icon) {
      padding: 1px 0 0 25px;

      i {
        font-size: 16px;
        color: $color-secondary-light;
      }
    }

    :global(.clearable-input__input.form-control) {
      padding-left: 5px;
    }
  
  }
}

.tree {
  height: 135px;
}

.input {
  height: 30px;
}

.browseButton {
  flex: 0 0;

  display: flex;
  align-items: center;
  border: none;
  height: $input-height;
  padding: 0 12px;
  line-height: 0;

  &:active,
  &:active:hover,
  &.active,
  .open > &.dropdown-toggle,
  &:active:hover,
  &:active:focus,
  &:active.focus,
  &.active:hover,
  &.active:focus,
  &.active.focus,
  .open > &.dropdown-toggle:hover,
  .open > &.dropdown-toggle:focus,
  .open > &.dropdown-toggle.focus {

      background-color: transparent;
  }

}

.dropdownFooter {
  display: flex;
  gap: 10px;
  border-top: 1px solid $input-border-color;
  padding: 10px 20px;
}

.dropdownButton {

  flex: 1;
  padding: 5px 8px;
}

.dropdownButton:not(:last-child) {
  margin-right: 3px;
}

.searchSpinner {
  margin: 10px 0;
}

.searchMessage {
  text-align: center;
  display: block;
  margin: 10px;
  color: $color-dark;
}

.error::after {
  content: 'error';
  font-size: smaller;
  color: $color-white;
  background: $color-danger;
  padding: 0 5px;
  margin: 0 3px;
}

.highlighted {
  color: $color-action;
  font-weight: 600;
}
