/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@import '~basic-styles.scss';

.holder {
  :global(.DropArea--dropMessage) {
    position: absolute;
    margin: 3%;
    width: 94%;
    min-height: 150px;
  }

  :global(.DropArea--dropArea) {
    height: 100%;
    overflow: auto;
  }

  :global(.DropArea--dropArea.mp-droppable-enabled ) {
    border: 2px solid $color-success;
  }

  :global(.DropArea--dropArea.mp-droppable-disabled ) {
    border: 2px solid $color-danger;
  }

  :global(.DropArea--dropArea.mp-droppable-enabled.mp-droppable-hover) {
    border: 2px solid $color-success;
  }

  :global(.DropArea--dropArea.mp-droppable-enabled > .DropArea--messageWrapper > .DropArea--dropMessage) {

    border: none;
  }
}

.itemArea {
  display: flex;
  flex-flow: wrap;
  gap: 5px;
  
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  background-color: $input-color-bg;
  align-items: center;
  //justify-content: center;
  padding: 5px;

  width: 100%;

  &--no-header {
    border: 0 ;
  }
}

.placeholderCard {
  width: 105px;
  height: 125px;
  margin: 4px;
}

.placeholderContainer {

  width: 100%;
  min-height: 100px;
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  cursor: pointer;
  border-radius: 4px;

  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;

  background-color: $color-body-bg;
  color: $color-secondary;
  border: 1.5px dashed $color-medium;
  padding: 20px;

  i {
    color: $color-secondary;
    margin-bottom: 10px;
    font-size: 27px;
  }
}
