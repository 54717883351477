/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @author Artem Kozlov <ak@metaphacts.com>
 */

@import '~basic-styles.scss';
@import '../commons/variables.scss';

@mixin clauseItemAfter {
  border: $color-border;
  content: '';
  position: absolute;
  width: $rs-button-arrow-border;
  height: $rs-button-arrow-border;
  top: $rs-arrow-top;
  right: -14px;
  transform: scaleX(0.7) rotate(45deg);
  padding: 0;
  z-index: 1;
  border-bottom: none;
  border-left: none;
}

.searchAreaHolder {
  display: flex;
  justify-content: flex-start;
  align-items: top;
  padding: 0;

  img {
    height: 20px;
    width: 30px;
  }

  :global(.alert-warning) {
    background-color: transparent;
    border: none;
  }

  :global(.alert)  {
    text-align: right;
    padding: 0 $padding-base-horizontal;
  }
}

.searchCollapseExpand {
  color: $brand-primary;
  cursor: pointer;
  margin-right: $padding-base-horizontal;
}

.searchExpand {
  composes: searchCollapseExpand;
  @include icon(caret-up);
  transform: rotate(90deg);
}

.searchCollapse {
  composes: searchCollapseExpand;
  @include icon(caret-down);
}

.searchSummaryHolder {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.searchExpand {
  composes: searchCollapseExpand;
  @include icon(caret-up);
}

.searchCollapse {
  composes: searchCollapseExpand;
  @include icon(caret-down);
}

.guideHolder {
  position: absolute;
  right: 0;
  margin-right: $padding-base-horizontal * 2;
}

.guidePanel {
  border: 1px solid $color-border;
  border-radius: 4px;
  margin-bottom: $form-group-margin-bottom;
}

.guideLinks {
  margin: $padding-base-vertical * 1.5 0 $padding-base-vertical 0;

  li {
    font-weight: 600;
    padding: 0 ($padding-base-horizontal * 2) calc($padding-base-vertical / 2) 0;
  }
}

.panelHeader {
  background-color: #fafafa;
  border-bottom: 1px solid $color-border;
  padding: $padding-base-vertical;
}

.searchArea {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: $padding-base-vertical;

  width: 100%;
}

.searchClauseArea {
  margin: 0;
  width: 100%;
}

.searchClauseHolder {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.searchClause {
  margin: 10px 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  width: fit-content;
  height: auto;

  //safari bug
  position: relative;

  :global(.Select-control:focus) {
    border: none;
  }

  :global(.QueryBuilder--itemHolder:first-of-type) {
    border-left: 1px solid $color-border;
  }

}

@mixin categorySelector {
  li {
    border-top: $color-border;
    &:first-child {
      border-top: none;
    }
  }
}

.selectedItem {
  display: flex;
  align-items: center;
  padding-left: $padding-base-horizontal + calc($rs-button-arrow-border / 2);
  padding-right: $padding-base-horizontal;
  cursor: auto;
  letter-spacing: .1px;

  height: $rs-button-height;
  border-width: 0;

  &::after {
    @include clauseItemAfter();
  }
}

.itemHolder {
 // height: $rs-button-height;
//  min-width: $rs-widest-button-size;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-grow: 1;

  position: relative;

  padding-left: 12px;
  padding-right: 5px;
  border-bottom: 1px solid $color-border;
  border-top: 1px solid $color-border;

  &:hover {
    .editButton {
      cursor: pointer;
      visibility: visible;
    }
  }
}

.actionableItem {
  /*! don't remove the comment to keep the class without properties */
}

.domainSelection {
  @include categorySelector();
  margin-left: $padding-base-horizontal;
}

.categorySelectionItem {
  justify-content: flex-start;
}

.rangeSelection {
  @include categorySelector();
  margin: 1px 20px;
}

.relationSelector {
  margin: 1px 20px;
}

.relationPlaceholder {
  @include itemHolder();
  height: $rs-button-height + 2;
  composes: selectedItem;
  color: $color-dark;
  background-color: transparent;
  border-bottom: 1px solid $color-border;
  border-top: 1px solid $color-border;
}

.selectedDomain {
  composes: selectedItem;
  padding-left: 10px;
  font-weight: bold;
}

.selectedRange {
  composes: selectedItem;
  font-weight: bold;
}

.selectedRelation {
  composes: selectedItem;
}

.selectedTerm {
  composes: selectedItem;
}

.addConjunctButton,
.addDisjunctButton {
  //visibility: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  position: relative;
  padding: 0 20px 0 40px;
  height: $rs-button-height + 2;

  border-bottom: 1px solid $color-border;
  border-top: 1px solid $color-border;

  color: $color-secondary;
  text-transform: uppercase;
  
  composes: selectedItem actionableItem;

  :global(span) {
    opacity: .4;
  }

  &::after {
    content: none;
  }

  &:active, 
  &:hover,
  &:focus-within,
  &:focus {
    cursor: pointer;

    :global(span) {
      opacity: 1;
    }
  }
}


.mapSelectionButton {
  background-image: url(../images/MapSelection.svg);
  background-repeat: no-repeat;
  background-position: 8px 2px;
  background-size: 40px 32px;
  height: $rs-button-height;
  width: 60px;
}

.removeConjunctButton {

  height: $rs-button-height + 2;
//  border-width: 0;
//  margin-left: auto;

  flex-grow: 1;
  padding: 0 20px 0 55px;
  color: $color-secondary;
  text-transform: uppercase;
  background-color: transparent;

  border: none;
  border-bottom: 1px solid $color-border;
  border-top: 1px solid $color-border;
  border-right: 1px solid $color-border;

  :global(span) {
    opacity: .5;
  }

  &:hover,
  &:focus-within,
  &:focus {
    color: $color-secondary;
    text-decoration: none;
    cursor: pointer;
    
    :global(span) {
      opacity: 1;
    }
  }
}

.nestedSearchButton {
  padding: 0 5px;
  height: $input-height;
  width: 38px;

  position: relative;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    height: 35px;
    width: 60px;
  }
}

.magnifierIcon {
 // background-image: url(../images/magnifying_glass.svg);
 // background-repeat: no-repeat; 
 // position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  :global(.rs-icon) {
    font-size: 17px;
  }
}

.nestedSearchHolder {
  margin-left: 10vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.activeTerm {
  color: $color-dark;
}

.searchBasedTermSelectorHolder {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
}

.searchBasedTermSelector {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
    
  width: 100%;
  flex-wrap: wrap;

  button {
    border-radius: 0;
  }
  button.nestedSearchButton {
    border-radius: $border-radius;
  }
}

.searchBasedTermSelectorLabel {
  padding-right: $padding-base-horizontal;
}

.resourceSelector {
  
  min-width: 150px;
  max-width: 400px;
  flex: 1;
  height: $rs-button-height;
  z-index: $zindex-input-element;

  :global(.Select-arrow-zone) {
    visibility: hidden;
    width: 1px;
  }

  :global(.Select-control) {
    height: $rs-button-height;
  }

  :global(.Select-input) {
    height: $rs-button-height - 2;
  }
}

.hierarchySelector {
  width: $rs-input-width;
}

.andSeparator {
  padding: $padding-base-vertical $padding-base-horizontal;
  font-weight: bold;
  border: $color-dark;
  background-color: $color-dark;
  color: $color-white;
}

.whereSeparator {
  padding: $padding-base-vertical $padding-base-horizontal;
  font-weight: bold;
  border: $color-dark;
  background-color: $color-dark;
  color: $color-white;
}

.editButton {
  margin-right: $padding-base-horizontal;
  visibility: hidden;
}
