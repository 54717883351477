/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

@mixin type-button-mixin($icon) {
  @include icon($icon);
}

.semantic-chart {

  &__types {

    display: flex;
    flex-wrap: wrap;
    align-items: center;

  }

  &__type-button.chart-type-line .semantic-chart__type-icon {
    @include type-button-mixin(line-chart);
  }

  &__type-button.chart-type-bar .semantic-chart__type-icon {
    @include type-button-mixin(bar-chart);
  }

  &__type-button.chart-type-radar .semantic-chart__type-icon {
    @include type-button-mixin(certificate);
  }

  &__type-button.chart-type-pie .semantic-chart__type-icon {
    @include type-button-mixin(pie-chart);
  }

  &__type-button.chart-type-donut .semantic-chart__type-icon {
    @include type-button-mixin(pie-chart);
  }

  &__type-button.chart-type-bubble .semantic-chart__type-icon {
    @include type-button-mixin(circle-thin);
  }

  &[data-type='radar'] {
    .highcharts-xaxis .highcharts-axis-line {
      display: none;
    }
    .highcharts-xaxis-grid .highcharts-grid-line {
      stroke-width: 1px;
    }
  }

  // chartjs container should have relative position for proper resizing
  // see https://www.chartjs.org/docs/latest/general/responsive.html#important-note
  &__renderer {
    position: relative;
  }
}

.semantic-chart__type-button.btn-default {

  &.active {
    color: $color-white;
    background-color: $color-action;
  }
}

.semantic-chart__type-label {
  text-transform: capitalize;
  padding-left: 5px;
}

// needed for resize when chart component is inside growable flex element,
// to make sure that canvas doesn't grow the element
// otherwise we get into infinite growth
.chartjs-render-monitor {
  position: absolute;
}
