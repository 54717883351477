/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

$canonical: #3793ff;
$not-canonical: #50e3c2;

.body {
  margin-bottom: 15px;
  overflow: hidden;
}

.row {
  display: flex;
  padding: 5px 0;
}

.valueCell {
  flex: 1 1 auto;
}

.beliefCell {
  flex: 0 0 80px;
  width: 80px;
  text-align: center;
}

.beliefTitle {
  font-size: 13px;
}

.dotCell {
  flex: 0 0 18px;
  width: 18px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dot {
  background: #fff;
  border: 1px solid;
  border-radius: 50%;
  display: inline-block;
  height: 7px;
  width: 7px;

  &.item {
    position: relative;

    &:before,
    &:after {
      border-right: 1px solid;
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -0.5px;
      height: 500px;
      z-index: -1;
    }

    &:before {
      bottom: 100%;
    }

    &:after {
      top: 100%;
    }
  }

  &.canonical {
    color: $canonical;

    &.hasArgument {
      background: $canonical;
    }

    &.item {
      align-self: flex-start;
    }
  }

  &.notCanonical {
    color: $not-canonical;

    &.hasArgument {
      background: $not-canonical;
    }

    &.item {
      align-self: flex-end;
    }
  }
}

.label {
  display: inline-block;
  margin-bottom: 5px;

  &.canonical {
    background: $canonical;
  }

  &.notCanonical {
    background: $not-canonical;
  }
}

.note {
  font-size: 13px;
  margin-bottom: 2px;
}

.noteDot {
  margin-right: 3px;
}
