/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2021-2024, © Kartography CIC
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.form-section-collapsible {

  margin: 10px 0;
  background-color: $color-lighter;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  
  &.collapsible-in-composite {
    background-color: $color-off-white;
  }

  .collapsible-panel-trigger {
    cursor: pointer;
    display: block;

    height: 38px;
    padding: 0 12px;

    .trigger-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 100%;
    }

    .trigger-text {
      font-weight: 600;
    }

    &.collapsed-trigger .trigger-content .trigger-icon::before {
      @include materialIcon('\e5cf', 18px);
    }

    &.expanded-trigger .trigger-content .trigger-icon::before {
      @include materialIcon('\e5ce', 18px);
    }
  
  }

  .collapsible-div-content {
    padding: 0 12px 20px;
    border-top: 1px solid $color-border;
  }
}



 



 
