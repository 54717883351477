/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
/* Dropzone */
.dropzone-area {
    width: 100%;
    height: 200px;

    border-style: dashed;
    border-width: 1.5px;
    border-radius: $border-radius;
    border-color: $color-border;

    background-color: $input-color-bg;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;

    color: $input-placeholder-color;
    font-size: $font-size-base;
    letter-spacing: 0.2px;
}

.FileUploader-container {

    min-width: 300px;

    .cardinality-support__single-instance {
        border: none;
    }
}