.ontodia-paper-area {
  flex: auto;
  width: 0;
  display: flex;
  position: relative;
  cursor: default;
  background: transparent;

  &__area {
    flex: auto;
    overflow: hidden;
  }

  &__widgets {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &--hide-scrollbars {
    overflow: hidden;
  }
}

.ontodia-paper {
  position: relative;
}
