/*  Webfonts */

/* https://google-webfonts-helper.herokuapp.com/fonts
 */
/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url('./fonts/source-sans-pro-v13-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v13-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-300italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
       url('./fonts/source-sans-pro-v13-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v13-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url('./fonts/source-sans-pro-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v13-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
       url('./fonts/source-sans-pro-v13-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v13-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
       url('./fonts/source-sans-pro-v13-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v13-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-700italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
       url('./fonts/source-sans-pro-v13-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v13-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  src: local('Nunito Regular'), local('Nunito-Regular'),
       url('./fonts/nunito-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/nunito-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-700 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  src: local('Nunito Bold'), local('Nunito-Bold'),
       url('./fonts/nunito-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/nunito-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* Webfont: Lato-Bold */
@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
  url('./fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
  url('./fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Light.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
  url('./fonts/Lato-Light.woff') format('woff'), /* Modern Browsers */
  url('./fonts/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-LightItalic */
@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-LightItalic.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/Lato-LightItalic.woff2') format('woff2'), /* Modern Browsers */
  url('./fonts/Lato-LightItalic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/Lato-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
  url('./fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}


/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
       url('./fonts/roboto-v18-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/roboto-v18-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  text-rendering: optimizeLegibility;
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url('./fonts/roboto-v18-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/roboto-v18-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  text-rendering: optimizeLegibility;
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('./fonts/roboto-v18-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/roboto-v18-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  text-rendering: optimizeLegibility;
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('./fonts/roboto-v18-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/roboto-v18-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  text-rendering: optimizeLegibility;
}

/*  rs-icons generated with https://icomoon.io/app */
@font-face {
  font-family: 'rs-icon';
  src: url('./fonts/rs-icons/rs-icon.woff?pr9rui') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'iconmoon';
  src: url('./fonts/iconmoon-icons/iconmoon.woff?3k53xr') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}