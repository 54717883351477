/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';


.field-editor {

  height: 100%;

  .page__section-container {
    overflow: scroll;
    height: 100%;
    flex: 1;
    margin: 0;
    padding: 10px 20px;
  }

  .form-group:last-child {

    margin: 0;
 //   height: $input-height; //changing this value, change the all group height
  }

  .CodeMirror-sizer {
    min-height: 33px !important; //overwrite inline style
  }

  .label-default {
    color: $brand-primary;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;

    .col-md-1 {
      padding: 0px;
      width: auto;
    }
  
    .col-md-8 {
      width: calc(75% - 37px);
    }
  }

  &__input-header {

    width: 170px;
    padding-right: 15px;
    height: $input-height;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 14.5px;
  }

  &__expand {
    color: $color-base;
    cursor: pointer;
    height: $input-height;
    display: flex;
    align-items: center;
  }

  &__collapse {
    height: $input-height;

  }

  &__error {

    padding: 12px;
    font-size: $font-size-xsmall;
    line-height: 1.3;
  
    border-radius: $border-radius;
    background-color: $color-danger-light;
    border: 1px solid $color-danger-border;
    color: $color-danger-text;
  
    display: flex;
    gap: 15px;
    align-items: flex-start;
    
    white-space: pre-wrap;
    margin: 7px 0;

    .field-editor__error-icon {

      background-color: #e3909b;
      border: 1px solid #dd7b88;

      border-radius: 5px;
      padding: 6px;
      height: 37px;
      width: 37px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 20px;
        color: $color-danger-text;
      }
    }

    .field-editor__error-title {
      font-size: 15px;
      font-weight: 700;
      padding-bottom: 3px;
    }
  }

  .yasqe_buttons {
    visibility: hidden;
  }

  &__xsd-input {

    .Select-menu-outer {
      z-index: 9999 !important;
    }
  }

  &__default-input-holder {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-bottom: 10px;

    &:last-child {

      margin: 0;
    }

    .btn {
      height: $input-height;
      align-self: stretch;
    }
  }

  &__delete-default {
    flex: 0 0 auto;
  }

  &__generate-iri:hover {
    color: $color-dark;
    cursor: pointer;
  }
  &__generate-iri:after {
    @include clearfix();
  }
  &__lang-selector-holder {
    display: inline-block;
    vertical-align: middle;
    margin-left: -1px;
    width: 85px;
  }

  &__delete-label-button {
    margin-left: 11px;
  }
}

 .tree-patterns-editor {
  background-color: $color-white;
  border: 1px solid $color-border;
  border-radius: $border-radius;

  padding: 0 10px 10px;

  &__type-switch {
    margin-bottom: 0;
    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  .field-editor__row {

    align-items: center;
    padding: 5px 0;
  }

  .field-editor__input-header {
    width: 125px;
  }
}

.inputAndButton-wrapper {

  display: flex;
  gap: 3px;
  align-items: flex-start;
  flex: 1;

  .SemanticTreeInput--holder {

    flex: 1;
  }

  .btn {
    align-self: stretch;
    height: $input-height;
  }
}
