/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@import '~basic-styles.scss';

.evidenceList {
  display: flex;
  flex-wrap: wrap;
}

.evidenceItem {
  display: flex;
}

.evidenceSelector {
  display: flex;
  margin-bottom: 21px;
}

.evidenceDropArea {
  display: flex;
  flex-direction: row;

  :global(.DropArea--messageWrapper) {
    display: flex;
    flex: 1 1 100%;
  }

  :global(.DropArea--dropMessage) {
    height: auto;
    min-height: 100px;
    text-align: center;
  }
}

.evidence {
  flex: 1;
}

.evidenceCustom {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.evidenceCustomDeleteButton {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
}
