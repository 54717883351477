// KM property label 
.ontodia-link {

  &__connection {
    stroke: $color-dark !important;
  }

  &__wrap {
    stroke-width: 12px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke: transparent;
    fill: none;
  }

  &__labels {

    color: $color-dark;
    line-height: 1.2;
    font-size: 14px;

    & g {

      rect {
        fill: $color-km-bg !important;
      }
      
      text {
        fill: $color-dark !important;
        font-family: $font-family-base !important;
        font-size: 18px !important;
      }
    }
  }

  &:hover &__wrap {
    stroke: $color-dark--op20;
  }

  &__vertex {
    cursor: all-scroll;
  }

  &:not(:hover) &__vertex {
    fill: transparent;
  }

  &__vertex-tools {
    opacity: 0;
    cursor: pointer;
    > circle { fill:$color-secondary; }
    > path { stroke: white; }
    &:hover {
      > circle { fill: $color-dark; }
    }
  }
  &:hover &__vertex-tools {
    opacity: 0.8;
  }

  &--blurred {
    opacity: 0.5;
  }
}
