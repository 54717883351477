/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/* Timeline style */
// node_modules/vis/dist/vis.css
.SemanticTimeline--timeline {

  .vis-timeline,
  .vis-panel.vis-bottom, 
  .vis-panel.vis-center, 
  .vis-panel.vis-left, 
  .vis-panel.vis-right, 
  .vis-panel.vis-top {

    border-color: $color-timeline-border;
  }

  .vis-time-axis .vis-text {

    font-size: $font-size-body;
    color: $color-timeline-text;
  }

  .SemanticTimeline--fitButton {
    padding: 3px 5px;
  }

  .vis-item {

    font-size: $font-size-body;
    color: $color-dark;

    a {
      color: inherit;
      font-weight: 600;
    }
  }

  .vis-current-time {
    background-color: $color-dark;
  }

  .vis-item .vis-delete:after, .vis-item .vis-delete-rtl:after {

    color: $color-danger;
  }

  .vis-item .vis-delete:hover, .vis-item .vis-delete-rtl:hover {
    background: $color-danger;
  }

}