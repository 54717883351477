/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
/* Panel */ 
.panel {

    background-color: $color-panel-bg;
    border-radius: $border-radius;
}

.panel-default {

    border: 1px solid $color-border;
  //  box-shadow: 0px 0px 2px 1px #00000024;

    & > .panel-heading {
        color: $color-panelHeading;
        background-color: $color-panelHeading-bg;
        border: none;
        border-radius: $border-radius;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        padding: 20px;

        a {
  //          color: $color-white;
            font-weight: 600;
        }
    }
} 

.panel-body {

    border-top: 1px solid $color-border;
    padding: 20px;
}

.panel-title {

    font-size: 15px;

    a {
        display: block;
    }
}

.error-notification.panel.panel-default {

    border: 1px solid $color-danger-border;
    border-radius: $border-radius;
    margin: 10px 0;

    & > .panel-heading {

        color: $color-danger;
        background-color: $color-danger-light;
        padding: 15px;
    }

    .error-notification__title-container {

        &.panel-title {

            a {

                display: flex;
                align-items: baseline;
                justify-content: space-between;
                width: 100%;
                color: $color-danger-text;
                text-decoration: none;

                .error-notification__more {

                    font-size: $font-size-xsmall;
                    font-weight: normal;
                    text-decoration: underline;
                }
            }
        }
    }

    & .panel-body {

        padding: 0;
        border-top-color:$color-danger-border;

        .semantic-form-errors__container {

            margin: 0;
            border: 0;
            padding: 15px;
        }

        .error-notification__error {

            padding: 12px;
            font-size: $font-size-xsmall;
            line-height: 1.3;
          
            border-radius: $border-radius;
            background-color: $color-danger-light;
        //    border: 1px solid $color-danger-border;
            color: $color-danger-text;
          
            display: flex;
            gap: 15px;
            align-items: flex-start;
            
            white-space: pre-wrap;
       //     margin: 7px 0;
        
            .error-notification__error-icon {
        
              background-color: #e3909b;
              border: 1px solid #dd7b88;
        
              border-radius: 5px;
              padding: 6px;
              height: 37px;
              width: 37px;
              display: flex;
              align-items: center;
              justify-content: center;
        
              i {
                font-size: 20px;
                color: $color-danger-text;
              }
            }
        
            .error-notification__error-title {
              font-size: 15px;
              font-weight: 700;
              padding-bottom: 3px;
            }
          }
  
    }
}

