/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

$light-border: 1px solid #dddddd;
$dark-border: 1px solid #a2a2a2;

.set-management {

  height: 100%;
  display: flex;
  flex-direction: column;
  background: $color-tf-dashboard-bg;

  :global(.DropArea--dropMessage) {
    position: absolute;
    margin: 5%;
    width: 85%;
  }

  &--readonly {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  &__children {
    flex: auto;
    display: flex;
    flex-direction: column;
  }

  &.mp-droppable-enabled &__drop-area-message {
    display: flex;
    border-width: 1px;

  }
  &.mp-droppable-enabled &__drop-area-children {
    opacity: 0.2;
    pointer-events: none;
  }

  &__search {
    display: flex;
    gap: 3px;
  }

  &__search-input {
    flex: auto;
  }

  &__search-message {
    padding-top: 8px ;
    font-size: 12px;
    font-weight: 600;
    color: $color-info;
  }

  &__show-filters {

  }

  &__search-and-filters {
    background-color: $color-tf-dashboard-bg;
    z-index: 20;
    padding: 8px 20px;

  }

  &__filters {
    opacity: 1;
    transition: opacity 0.2s;
    padding-top: 5px;

    &--hidden {
      height: 0;
      visibility: hidden;
      opacity: 0;
      padding-top: 0px;
    }
  }

  &__filter {
    padding: 0;
  }

  &__sets,
  &__set-items,
  &__search-results {
    list-style-type: none;
    overflow-x: hidden;
    min-height: 30px;
    margin: 0;
  }

  &__sets {
    display: flex;
    flex-direction: column;

    flex: auto;
    overflow-y: auto;
    height: 0;
    background: $color-tf-dashboard-bg;
    z-index: 10;
    padding: 5px 20px 8px;
  }

  //sidebar set folder
  &__set {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style-type: none;
    background-color: $color-set-management-bg;
    border: 1px solid $color-border;
    border-radius: $border-radius;

    padding: 0 8px;
    min-height: 37px;
    height: 37px;
    margin-bottom: 5px;

    &:hover .set-management__item-actions {
      visibility: visible;
    }

    &:hover {
  //    background: $color-light;
      cursor: pointer;
    }
  }

  &__set-caption {
    display: flex;
    align-items: center;
    transition: background 0.15s;
    height: 37px;
    min-width: 100%;
    
    > .system-spinner {
      position: static;
    }
  }

  &__set-icon {
    margin-right: 10px;
    margin-left: 1px;
    display: flex;
    align-items: center;

  }

  &__items-view {
    flex: 1;
  }

  &__set-item-count {

    flex-shrink: 0;
    margin-left: 4px;
    height: 28px;
    min-width: 27px;

    &.badge {

    }
  }

  &__set-template {

    align-items: center;
    flex: auto;
    min-width: 0;
    font-size: $font-size-small;
    color: $color-secondary;
    font-weight: 600;

    .dropdown-toggle.btn {
      padding-right: 0;
    }

    .rs-icon-diagram {

      margin-right: 5px; 
      font-size: 1.2em;
    }
  }

  &__search-results-empty {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;

    font-size: 15px;
    font-weight: 600;
    color: $color-info;
  }

  &__search-results {

    flex: auto;
    overflow-y: auto;
    height: 0;
    padding: 0 1.3em .5em 1.7em;
    
    & .set-management__items-view {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      padding: 5px 0;
    }
  
    & .set-management__set {
      margin-bottom: 14px;
      padding: 0 8px;
      height: auto;
    }

    & .set-management__set-items {
      border-left: 3px solid $color-action;
      padding: 0px 18px 0px 10px;
      background-color: $color-set-management-bg;
    }
  }

  &--only-opened-set &__items-view {
    /* make only items scrollable when set is opened */
    overflow-y: auto;
    height: auto;
    background-color: $color-set-management-bg;

    border: none;
  }

  &__set-items {

    padding: 0;
    display: flex;
    background: $color-tf-dashboard-bg;

  }

  &--list-view {

    .set-management__set-items {
      flex-direction: column;
      gap: 8px;
      height: 100%;
  
      .set-management__reordered-item {
      //  margin-bottom: 8px;
        
        .ReorderableList--item-handle {
          display: none;
        }
      }
    }

    .set-management__set-item {
  
      & img {
        width: 12px;
        margin-left: 6.5px;
      }
  
    }

    .rs-set-item--fa {
      padding-left: 11px;
      width: 24px;
      font-size: 11px;
      flex-shrink: 0;
    }
  
    .error-notification.panel.panel-default .panel-heading {
      padding: 4px;
    }

    .set-management__opened-set {
  
      & .set-management__items-view {
  
        & .set-management__set-items {
  
          padding: 8px;
        }
      }
    }
  }

  &--grid-view {

    .set-management__set-items {

      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      gap: 8px;
      height: 100%;
  
      .set-management__reordered-item {

        

        .ReorderableList--item-handle {
          display: none;
        }
      }
    }

    .set-management__set-item {
  
      .rs-grid-element a i {
        color: $color-light;
      }
  
      .panel-footer {
        display: flex;
        flex-direction: row !important;
        justify-content: space-around !important;
        align-items: center;
        padding: 9px 9px;
        background-color: $color-light;
        border-top: none;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
  
        .set-management__item-actions {
          margin-left: 0px !important;
        }
      }
    }

    .error-notification.panel.panel-default {
      border: none;
  
      .panel-heading {
        padding: 7px;
  
        .panel-title {
          font-size: 12px;
        }
      }
    }

    .set-management__opened-set {
  
      & .set-management__items-view {
  
        & .set-management__set-items {
  
          padding: 8px;
        }
      }
    }
  }

  &__reordered-item {
    border: 2px solid $color-action;
    border-radius: $border-radius;

    &[data-dragged] {
      border: 2px solid transparent;
    }
  }

  &__set-item {
    font-size: 13px;
    word-wrap: break-word;
    word-break: break-all;
    transition: background 0.15s;

    &:hover {
      cursor: pointer;

      & .set-management__item-actions {
        visibility: visible;
      }
    }

/*     .dropdown-toggle.btn {
      padding-left: 8px;
      border: none;
      background-color: transparent;

      &:hover,
      &:focus,
      &:focus-within,
      &:active,
      &:active:hover,
      &.active,
      .open > &.dropdown-toggle,
      &:active:hover,
      &:active:focus,
      &:active.focus,
      &.active:hover,
      &.active:focus,
      &.active.focus,
      .open > &.dropdown-toggle:hover,
      .open > &.dropdown-toggle:focus,
      .open > &.dropdown-toggle.focus {
        border: none;
        background-color: transparent;
      }
    } */

    .panel {
      border-radius: 6px;
      background-color: #fdfdfd;
      border: 0.5px solid $color-light;
      max-width: 130px !important;
      height: 100%;
    }

    .panel-default {
      border-color: $color-light;
    }

    .panel-body {
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        border: 1px solid $color-white;
      }
    }

    .panel-footer input {
      flex-shrink: 0;
      margin-right: calc($padding-base-horizontal / 2);
    }
  }

  &__back-to-contents {
    display: flex;
    align-items: center;
    gap: 6px;
    width: fit-content;
    font-size: $font-size-small;

    margin: 8px 20px 3px;

    i {

      font-size: 16px;
    }
  }

  &__open-set {

    display: flex;
    align-items: center;

    padding: 0 8px;
    height: 37px;
    background-color: $color-set-management-bg;

    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border: 1px solid $color-border;
   // border-bottom: none;

    &:hover {

      & .set-management__item-actions {
        visibility: visible;
      }
    }
    > .system-spinner {
      position: static;
    }
  }

   &__toolbar {  
    padding: 8px 20px;
  } 

  &__toolbar-buttons {
    display: flex;
    justify-content: space-between;
    gap: 3px;
  }

  &__item-view-mode {

    display: flex;
    align-items: center;
    gap: 3px;
  }

  &__toolbar-spacer {
    flex: auto;
  }

  &__toolbar-reorder-confirmation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid $color-border;
  }

  &__toolbar-reorder-message {

    padding-right: 20px;
  }

  &__toolbar-reorder-buttons {

    display: flex;

    .btn {
      font-size: 13px;
      padding: 5px 8px;
    }
  }

  &__toolbar-reorder-cancel {
    margin-right: 5px;
  }

  &__toggle-reorder-items {

  }

  &__item-actions {
    visibility: visible;
    margin-left: auto;

    .btn-link {
      border: none;
      color: $color-secondary;
      padding-top: 0;
      padding-bottom: 0;
    }

    .dropdown.open {
      visibility: visible;
    }

    .remove-set-confirmation {

      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: space-between;
      padding: 9px 8px 9px 18px;
      cursor: initial;
     // border-top: 1px solid $color-border;
     background-color: $color-action;

      div {

        display: flex;
        gap: 5px;
      }

      span {
        white-space: nowrap;
        color: $color-white;
        font-size: $dropdown-font-size;
      }
  
      .btn.btn-default {
        padding: 3px 6px;
        border-color: $color-white;

        &:hover,
        &:active,
        &.active,
        .open > &.dropdown-toggle,
        &:focus,
        &:focus-within,
        &.focus {
            background-color: $color-white;
            border-color: $color-white;
        }
      }

    }
  }

  &__opened-set {
    flex: auto;
    display: flex;
    flex-direction: column;

    padding: 5px 20px 8px;

    & .set-management__items-view {

      & .set-management__set-items {

        background-color: $color-set-management-bg;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border: 1px solid $color-border;
        border-top: none;
      }
    }
  }

  &__single-set-header-top {
    display: flex;
    align-items: baseline;
    padding: 10px;
  }

  &__single-set-header-bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }

  &__single-set-caption {

    display: flex;
    align-items: center;

    & .set-management__item-actions {
      visibility: visible;
    }
    > .system-spinner {
      position: static;
    }
  }

  &__single-set-icon {
    margin: 0 10px;
    font-size: 19px;
  }

  &__single-set-label {
    font-size: 19px;
  }

  &__single-set-rename-button {
    margin: 0 10px;
    border: none;
    background: transparent;
    outline: none;
    color: $color-dark--op75;
    &:hover {
      color: $color-dark;
    }
  }

  &__single-set-header-spacer {
    flex: auto;
  }

  .set-management__single-set-header-top {

    .set-management__filter {
      border-top: none;
    }
  }

  .ReorderableList--component:not(.ReorderableList--dragging) .ReorderableList--item:hover {
    background: $color-action--op30;
  }

  .ReorderableList--item-body {
    margin: 0;

    .resource-card {

      border: none;
    }
  }

}

@mixin highlight-animation() {
  animation-name: highlight-animation;
  animation-duration: 0.3s;
  animation-delay: 0s;
  animation-iteration-count: 2;

  @keyframes highlight-animation {
    from {
      background-color: transparent;
    }

    50% {
      background-color: $color-light;
    }

    to {
      background-color: transparent;
    }
  }
}
.set-items-animation-enter.set-items-animation-enter-active {
  @include highlight-animation();
}

.set-items-animation-exit.set-items-animation-exit-active {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
}
