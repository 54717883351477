/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @author Artem Kozlov <ak@metaphacts.com>
 */

@import '~basic-styles.scss';
@import '../commons/variables.scss';

.holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contextSelector {
  min-width: 200px;
  flex: 1;
}

.selectorGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;

  :global(.control-label) {

    padding-right: 25px;
    margin: 0;
    font-weight: normal;
  }

  &:global(.form-group) {

    height: auto;
    margin-bottom: 20px;
  }
}
