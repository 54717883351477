/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

$slick-font-family: 'FontAwesome';
$slick-prev-character: '\f0a8' !default;
$slick-next-character: '\f0a9' !default;
$slick-dot-character: '\f111' !default;

@function image-url($url) {
  @return '';
}

@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';

.semantic-carousel {
  & > .slick-slider {
    width: 95%;
    margin-left: 2%;
    margin-right: 2%;
    & > .slick-prev:before,
    .slick-next:before {
      color: $gray-light;
    }
  }
}
