/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.vertical-tabs {
  display: flex;

  &__nav {
    padding: 10px;
    border-right: 1px solid #ddd;
    padding-top: 20px;
    background-color: #fff;
    border-bottom: none;

    &.nav-tabs > li.active {
      font-weight: bold;
      color: inherit;
      background-color: inherit;
      border-color: $btn-default-border;
    }

    &.nav-tabs > li.active:after,
    &.nav-tabs > li.active:before {
      position: absolute;
      right: -11px;
      top: 10px;
      width: 0;
      height: 0;
      content: '';
      border: 12px solid transparent;
      border-right-color: #333;
    }

    &.nav-tabs > li.active:after {
      top: 10px;
      padding-left: 1px;
      background: transparent;
      border-right-color: #fff;
    }

    &.nav-tabs > li > a {
      background-color: #fff;
      border: none;
      text-align: right;
      padding-right: 15px;

      &:hover {
        border: none;
      }
    }

    &.nav-tabs > li.active > a,
    &.nav-tabs > li.active > a:hover,
    &.nav-tabs > li.active > a:focus {
      border: none;
    }
  }
}
