/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 /* COMING SOON PAGE  */
.rs-coming-soon__container {
  text-align: center;
  width: 100%;
  padding-top: 10%;

  height: calc(100vh - #{$nav-height});

  .rs-coming-soon__title {
    font-size: 4rem;
    padding-bottom: 20px;
  }

  .rs-coming-soon__content {
    font-size: 2rem;
    line-height: 1.4;
  }
}


.rs-dev-documentation-page {
  a {
    text-decoration: underline;
    line-height: 1.5;
  }

  li {
    line-height: 1.5;
  }

  h3 {
    margin-top: 5px;
  }
}

.documentation-page {

  .page__content { 

    a {
      text-decoration: underline;
    }

    &-mainTitle {

      font-size: 30px;
      font-weight: 600;
    }

    &-sectionTitle {

      font-size: 23px;
      font-weight: 600;
      margin: 30px 0 15px 0;
    }
  }
}
