/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.component {
  display: flex;
  flex-direction: column;
}

.warning {
  /**/
}

.main-part {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.labeled-control {
  display: flex;
  align-items: center;
}

.override-chain {
  margin-left: 5px;
  margin-right: 20px;
}

.overridden-app {
  font-size: large;
  font-family: monospace;
}
.effective-app {
  font-size: large;
  font-family: monospace;
  font-weight: bold;
}

.override-arrow {
  margin: 0 5px;
}

.selector {
  width: 200px;
}
