/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

//@import '~basic-styles.scss';

//Edit Page style
.page-editor {
  &__app-selector {
    margin: 5px 0;
  }

  .page__body {
    margin-top: 20px;
  }

  a, 
  a:hover,
  a:active,
  a:focus {
      text-decoration: underline;
  }
}

.template-editor {
  height: auto;

  .CodeMirror {
    &-scroll {
      min-height: 300px;
    }
  }
}

.template-cancel-save {

  .btn {
    margin-left: 10px;
  }
}

.template-types {
  margin-top: $padding-base-vertical;

  label {
    margin-right: $padding-base-horizontal;

    input {
      margin-right: calc($padding-base-horizontal / 3);
    }
  }
}

.resource-viewer-modal__dialog {
  width: 90vw;
}

/* Breadcrumb right side */
// component-page-toolbar
.component-page-toolbar {

  z-index: $zindex-toolbar;
  position: fixed;
  right: 18px;
  height: $breadcrumb-height;

  & .btn-group {

    height: 100%;
    display: flex;
    align-items: center;
  }

  &__btn_show_source.btn,
  &__btn_show_graph.btn,
  &__btn_show_diagram.btn,
  &__btn_copy_iri.btn {

      background-color: transparent;
      border: none;
      font-size: 12px;
      text-decoration: underline;
      padding: 0px 10px;

      &::before {
        font-size: 11px;
      }
  }

  &__btn_edit.btn {
    background-color: transparent;
    font-size: 12px;
    border: none;
    padding: 0 10px;

    &.active {
      pointer-events: none;
      cursor: default;
    }

    i {
      padding-right: 3px;
    }
  }

  &__btn_copy_iri {
    
    &::before {
      content: "Copy resource IRI";
    }
  }

  &__btn_show_source {
    margin-left: 25px;

    &::before {
      content: "Statements";
    }
  }

  &__btn_show_graph {

    &::before {
      content: "Graph";
    }
  }

  &__btn_show_diagram {
    display: none;
  }

  &__repository_indicator {
    float: left;
    margin-right: 10px;
    padding: 4px 10px;
  }
}

// CodeMirror style
.CodeMirror,
.yasqe .CodeMirror,
.yasr_results .CodeMirror {
  height: auto !important;
  border: 1px solid $color-codeMirror-border !important;
  border-radius: $input-border-radius;
  background-color: $color-codeMirror-bg !important;

  font-size: $font-size-small;
}

.CodeMirror-hints {
  z-index: $zindex-yasqe-autocomplete !important;
}