/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @author Denis Ostapenko
 */

@import '~basic-styles.scss';
@import '../../commons/variables.scss';

.slidergraph {
  padding: 10px 0 10px 0;
}
.handle {
  position: absolute;
  transform: translate(0%, -50%);
  cursor: pointer;
  padding: 4px;
 // border: 3px solid #008cba;
  border-radius: 2px;
  background: #008cba;
  color: $color-white;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
}
.range {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  :global(input.form-control),
  :global(select.form-control) {
 //   min-width: 100px;
    padding: 0px 7px;
    color: $color-secondary;
    background-color: $color-white;
  }

  @media (min-width: $screen-sm-min) and (max-width: 1366px) {
    zoom: 0.8;
  }
}

.slider {
  margin-top: $padding-base-vertical;
  margin-bottom: $padding-base-vertical;


  & > .handle ~ .handle {
    transform: translate(-100%, -50%);
  }

  & > :global(.FacetSlider--handle:first-of-type){

    transform: translate(0%, -50%);
  }
}
