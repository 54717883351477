/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @author Artem Kozlov <ak@metaphacts.com>
 * @author Mike Kelly <mkelly@britishmuseum.org>
 */

@import '~basic-styles.scss';
@import '../commons/variables.scss';

$deviation-input-width: 5em;

.holder,
.inputHolder {
  display: flex;
  align-items: center;

  :global(div.form-group) {
    margin-bottom: 0;
  }
}

.deviationInput {
  margin-left: $padding-base-horizontal;
  margin-right: $padding-base-horizontal;
  width: $deviation-input-width;
}

.dateMenuInputItem {
  display: flex;
  align-items: center;
  color: $color-white;
  background-color: $color-btn-toolbar;
  font-size: 12px;
  font-weight: bold;
  height: 24px;
  padding: 0 6px;
  border-radius: $border-radius;
  text-transform: uppercase;
}

.dateMenuPlaceholderItem {

  color: $color-white;
  background-color: $color-btn-toolbar;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 6px;
  margin-right: 4px;
  border-radius: $border-radius;

}

.dateSeparator {
  margin: 0 10px;
}

.dateFormatSelect {
  width: $rs-input-width * 0.75;
  margin-right: $padding-base-horizontal;

  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: $color-btn-toolbar;
  }

  &__ddMmYyyy {
    composes: dateMenuPlaceholderItem;
  }

  &__ddMmYyyyDateDeviation {
    composes: dateMenuInputItem;
  }

  &__yyyyAcBc {
    composes: dateMenuInputItem;
  }

  &__ddMmYyyyYearDeviation {
    composes: dateMenuInputItem;
  }
  
  :global(.Select-menu-outer) {
    max-height: 250px;
  }

  :global(.Select-menu) {
    max-height: 270px;
  }

  :global(.Select-menu-outer .Select-option) {
      padding: 10px 14px;
  }
}