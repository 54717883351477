/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
 .rs-page-resource__field {

  ol {
    padding-left: $padding-base-horizontal;
  }
  
  &-values {
    list-style-type: none;

    ol {
      margin-left: 2em;
    }

    & > li {
      counter-increment: count-me;
    }

    & > li::before {
      content: counter(count-me) '. ';
      display: block;
      position: relative;
      max-width: 0px;
      max-height: 0px;
      left: -1.3em;
    }
  }

  &-value {
   // white-space: pre-line;
   // max-width: 600px;
  }

  &-label-holder {
  //  width: 220px;
    vertical-align: center;
  }

  &-label {
/*  display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }
}
