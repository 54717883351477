/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
 
 .login-body {

  font-size: 14px;
  line-height: 1.2;
  height: 100vh;
  background-color: $color-light;

  &::after {
    background-image: url('/assets/no_auth/images/login/book.png');
  background-position: top center; 
  background-repeat: no-repeat; 
  background-size: cover; 

  filter: grayscale(100%);

    content: "";
    opacity: 0.15;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;   
    width:auto;
    height: 100%;
    }
}

.login-container {

  height: inherit;


  .login-title-row {
    margin-top: 0px;
    padding-bottom: 10px;
    max-width: 450px;
    text-align: center;

    .login-title {
      font-size: 36px;
      line-height: 1.4;
      letter-spacing: 0.3px;
    }

    .powered-by {

      padding-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 30px;
        width: auto;
      }
    }
  }

  .panel {

    margin:0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    min-width: 330px;
    max-width: 360px;
    width: 100%;
    
  }

  .panel-body {
    padding: 10px 0 0;
    border: none;
  }

  .login-border {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $color-border;
    padding: 45px 50px 50px;
    box-shadow: 0px 0px 6px 1px $color-base;
    background-color: $color-white;
    border-radius: $border-radius;
  }

  .form-control {
    background-color: transparent;
  }

  .btn-success {

    font-family: $font-family-base;
    background-color: $color-dark;
    border-color: $color-dark;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.3px;
    padding: 14px 0;

    &:hover,
    &:focus,
    &:focus-within {

      background-color: $color-dark-darker;
      border-color: $color-dark-darker;
    }
  }

  .alert-danger {
    font-family: $font-family-base;
    color: $color-alert-message-bg;
    border: none;
    background-color: transparent;
    font-size: 16px;
    letter-spacing: 0.2px;
  }

  .alert {
    padding: 8px;
    margin-bottom: 21px;
    margin-right: 15px;
    margin-left: 15px;
    text-align: center;
    border: 1px solid transparent;
    border-radius: $border-radius;
  }
}

/* .login-body {

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100vh;
}

.login-image {

  background-image: url('/assets/images/login/book.png');
  background-position: top center; 
  background-repeat: no-repeat; 
  background-size: cover; 

  filter: grayscale(100%);

  opacity: .3;
} */