/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


// STYLE NOT IN USE
 
// Object Annotation Tab
// Disable disclosure icon chrome
details.create-new-annotation summary::-webkit-details-marker {
  display: none;
}
// disable disclosure icon firefox
details.create-new-annotation > summary:first-of-type {
  list-style-type: none;
}
details.create-new-annotation {
  margin-bottom: $padding-base-vertical * 2;
  summary {
    outline: none;
  }

  summary a {
    margin-left: $padding-base-horizontal;
  }
}

details::after {
  content: '';
  display: block;
  clear: both;
}
// end Object Annotation Tab


.icon-fc-bg {
  background-repeat: no-repeat;
  background-size: 29px 29px;
  background-position-x: 2px;
  background-position-y: 2px;
}

.icon-fc {
  &--things {
    background-image: url('/assets/images/fcs/things.svg');
    @extend .icon-fc-bg;
  }
  &--actors {
    background-image: url('/assets/images/fcs/actors.svg');
    @extend .icon-fc-bg;
  }
  &--dates {
    background-image: url('/assets/images/fcs/dates.svg');
    @extend .icon-fc-bg;
  }
  &--events {
    background-image: url('/assets/images/fcs/events.svg');
    @extend .icon-fc-bg;
  }
  &--concepts {
    background-image: url('/assets/images/fcs/concepts.svg');
    @extend .icon-fc-bg;
  }
  &--places {
    background-image: url('/assets/images/fcs/places.svg');
    @extend .icon-fc-bg;
  }
}

.icon--annotation {
  margin-right: 2px;
}

.icon--assertion {
  display: inline-block;
  background-image: url('/assets/images/icons/assertion.svg');
  vertical-align: bottom;
  width: 24px;
  height: 24px;
  margin-right: 2px;
}

.icon--clipboard {
  background-image: url('/assets/images/icons/clipboard.svg');
  @extend .icon-fc-bg;
  background-size: 26px 26px;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.icon-fc--header-title {
  display: inline-block;
  height: 30px;
  width: 30px;
  vertical-align: bottom;
  opacity: 0.3;
}

// Fields
.rs-entity-fields,
.rs-field-assertions {
  /** hide borders for values tables */
  .panel {
    border: 0;
  }

  .panel-heading {
    color: $brand-primary;
    border-color: $color-frame-borders;
    background-color: transparent;
    padding: 0;
    & > div > span,
    & > a > div > span {
      background-color: #e7f5ff;
      padding: calc($padding-base-horizontal / 2) $padding-base-horizontal;
    }
  }

  .panel-success > .panel-heading {
    & > div > span,
    & > a > div > span {
      background-color: #e9f5e9;
    }
  }
  /**/

  .rs-page__field {
    .rs-page__field-value {
      padding-bottom: $padding-base-vertical;
    }

    .rs-page__count--field-assertion,
    .rs-page__count--field-annotation {
      .badge {
        align-self: flex-start;
        margin-top: calc($padding-base-vertical / 3);
        margin-right: calc($padding-base-horizontal / 2);
      }
    }
  }

  .rs-page__make-assertion {
    padding-bottom: calc($padding-base-vertical / 2);
  }

  .badge:hover {
    background-color: $brand-primary;
  }

  .btn-agree,
  .btn-disagree {
    opacity: 0.8;
  }

  .btn-agree:hover {
    opacity: 1;
    background-color: $brand-info;
  }

  .btn-disagree:hover {
    opacity: 1;
    background-color: $brand-warning;
  }
}


.draggable-gripper {
  background-image: url('data:image/svg+xml;utf8,<svg width="100%" height="100%" viewBox="0 0 5 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><rect x="0" y="0" width="2" height="2" style="fill:%23ccc;"/><rect x="0" y="8" width="2" height="2" style="fill:%23ccc;"/><rect x="0" y="16" width="2" height="2" style="fill:%23ccc;"/><rect x="3" y="0" width="2" height="2" style="fill:%23ccc;"/><rect x="3" y="8" width="2" height="2" style="fill:%23ccc;"/><rect x="3" y="16" width="2" height="2" style="fill:%23ccc;"/><rect x="3" y="4" width="2" height="2" style="fill:%23ccc;"/><rect x="3" y="12" width="2" height="2" style="fill:%23ccc;"/><rect x="3" y="20" width="2" height="2" style="fill:%23ccc;"/><rect x="0" y="4" width="2" height="2" style="fill:%23ccc;"/><rect x="0" y="12" width="2" height="2" style="fill:%23ccc;"/><rect x="0" y="20" width="2" height="2" style="fill:%23ccc;"/></svg>');
  width: 5px;
  height: 22px;
  margin-top: 2px;
  margin-right: $padding-base-horizontal;
  cursor: move;
  display: inline-block;
  flex-shrink: 0;

  &--hover {
    @extend .draggable-gripper;
    background-image: url('data:image/svg+xml;utf8,<svg width="100%" height="100%" viewBox="0 0 5 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><rect x="0" y="0" width="2" height="2" style="fill:%23333;"/><rect x="0" y="8" width="2" height="2" style="fill:%23333;"/><rect x="0" y="16" width="2" height="2" style="fill:%23333;"/><rect x="3" y="0" width="2" height="2" style="fill:%23333;"/><rect x="3" y="8" width="2" height="2" style="fill:%23333;"/><rect x="3" y="16" width="2" height="2" style="fill:%23333;"/><rect x="3" y="4" width="2" height="2" style="fill:%23333;"/><rect x="3" y="12" width="2" height="2" style="fill:%23333;"/><rect x="3" y="20" width="2" height="2" style="fill:%23333;"/><rect x="0" y="4" width="2" height="2" style="fill:%23333;"/><rect x="0" y="12" width="2" height="2" style="fill:%23333;"/><rect x="0" y="20" width="2" height="2" style="fill:%23333;"/></svg>');
  }
}
