/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@mixin frame-icons($frameType) {

    .flexlayout__#{$frameType}_button_content::before {
        margin-right: 5px;
    }

    &.homepage-button {

         .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e88a', 15px);
        }
    }
    
    &.clipboard {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e14f', 15px);
        }
    }
    
    &.search {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e8b6', 15px);
        }
    }

    &.finance {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e6bf', 15px);
        }
    }

    &.timeline {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e922', 15px);
        }
    }

    &.dictionary {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\f539', 15px);
        }
    }

    &.insert_drive_file {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e24d', 15px);
        }
    }

    &.image {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e3f4', 15px);
        }
    }

    &.place {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e55f', 15px);
        }
    }

    &.edit_note {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e745', 15px);
        }
    }

    &.bookmark {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e866', 15px);
        }
    }

    &.settings {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e8b8', 15px);
        }
    }

    &.account_tree {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e97a', 15px);
        }
    }

    &.description {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e873', 15px);
        }
    }

    &.photo_size_select_large {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e433', 15px);
        }
    }

    &.wallpaper {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e1bc', 15px);
        }
    }

    &.history {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e889', 15px);
        }
    }

    &.compare {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e3b9', 15px);
        }
    }

    &.manage_search {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\f02f', 15px);
        }
    }

    &.gallery_thumbnail {
    
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\f86f', 15px);
        }
    }

    &.topic {
        
        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\f1c8', 15px);
        }
    }

    &.burst_mode {

        .flexlayout__#{$frameType}_button_content::before {
            @include materialIconSymbol('\e43c', 15px);
        }

    }

    
}


            