/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

//vars
//bootstrap override
//$btn-default-bg: white;

/* $rs-color-verylightgrey: #fafafa;
$rs-color-mediumgrey: #666; */

/* $rs-color-border-dark-bg: #5a5a5a;
$rs-color-text-dark-bg: #d0d2d4;
$rs-color-active-tab-dark-bg: #393c44; */


/* Customise flatly bootstrap theme */
/* $brand-success: #43ac6a;
$btn-success-color: #fff;
$link-color: $brand-info;
$btn-default-color: $gray-darker; */
//$btn-default-bg: #fff;
/* $btn-primary-color: #fff;
$btn-default-border: #ccc;
$navbar-default-link-hover-color: #fff;
$navbar-default-link-hover-bg: darken($navbar-default-bg, 10%); */

/* 
$nav-tabs-border-color: $table-border-color;
$panel-default-border: $table-border-color;

$border-radius-base: 3px;
$border-radius-large: 3px; */

/* $input-height-base: ($line-height-computed + ($padding-base-vertical * 2));
$input-border-radius: 0;
$input-border-radius-large: 0;
$input-border-radius-small: 0;
$input-border-focus: darken($btn-default-border, 20%); */
/* 
$pagination-color: $link-color;
$pagination-bg: transparent;
$pagination-border: transparent; */

/* $pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-lighter;
$pagination-hover-border: transparent;

$pagination-active-color: #fff;
$pagination-active-bg: $brand-primary;
$pagination-active-border: transparent;

$pagination-disabled-color: $gray-light;
$pagination-disabled-bg: #fff;
$pagination-disabled-border: transparent; */

@import '~basic-styles.scss';

//search
$rs-button-color: $color-dark;
$rs-button-background-color: #f7f7fa;
$rs-button-border-color: #d0d0d4;

$rs-button-height: 40px; 
$rs-widest-button-size: 140px;
$rs-input-width: 400px;
$rs-fc-button-width: 120px;

//breadcrumbs
$rs-button-arrow-border: $rs-button-height * 0.74;
$rs-arrow-top: 5px;
$rs-arrow-side: -13px;

/* $rs-breadcrumbs-scale: 0.74;
$rs-breadcrumb-button-size: $rs-button-height * $rs-breadcrumbs-scale;
$rs-breadcrumb-arrow-border: $rs-button-arrow-border * $rs-breadcrumbs-scale;
$rs-breadcrumb-arrow-top: $rs-arrow-top * $rs-breadcrumbs-scale;
$rs-breadcrumb-arrow-side: $rs-arrow-side * $rs-breadcrumbs-scale; */

@mixin rsButton() {
  @include button-variant($rs-button-color, $rs-button-background-color, $rs-button-border-color);
  height: $rs-button-height;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  padding-left: $padding-base-horizontal;
  padding-right: $padding-base-horizontal;
}

@mixin itemHolder() {

  color: $rs-button-color;
  background-color: $rs-button-background-color;
  border-color: $color-border;
  height: $rs-button-height;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  padding-left: $padding-base-horizontal;
  padding-right: $padding-base-horizontal;

  min-width: $rs-widest-button-size;
  border: none;
}
