/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
//React Semantic carousel customisation
//https://github.com/akiran/react-slick

.semantic-carousel>.slick-slider {

  margin: 0px;
}

.slick-prev, 
.slick-next {

  z-index: 500;
  height: 36px;
  width: 36px;
  background: $color-white;
  border-radius: 50%;

  &:hover,
  &:focus {

    background: $color-white;
  }

}

.slick-next {
  right: -18px;
}

.slick-prev {
  left: -18px;
}

.slick-dots {
  top: 242px;
  bottom: unset;
  padding: 0 26px;
}

.slick-dots li {

  height: 5px;
  width: 5px;
}

.slick-dots li.slick-active button::before {
  
  color:$color-dark;
}

.rs-resource-header .slick-dots li button::before {

  color:$color-dark;
  font-size: 9px;
}

.slick-next::before, .slick-prev::before {

  font-size: 25px;
  opacity: 1;
}


.slick-prev::before {
  content: "\f104";
}

.slick-next::before {
  content: "\f105";
}

.semantic-carousel>.slick-slider .slick-next::before, 
.semantic-carousel>.slick-slider>.slick-prev::before {

  color:$color-dark;

}
