/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.navbar {
  min-height: $nav-height;
  height: $nav-height;
  border: none;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  color: $color-navbar;

  i {
    color: $color-navbar;
    font-size: 22px;
  }

  a {
    color: $color-navbar;
  }
}

.rs-header,
.navbar-header,
.collapse.navbar-collapse,
.navbar-nav,
.navbar-nav > li,
.navbar-nav > li > a {
  height: 100% !important;
}

.navbar-header {
  display: flex;
  align-items: center;
}

.navbar-default {
  background-color: $color-navbar-bg;
  box-shadow: 0px 1px 2px 0px #00000061;

  & .navbar-toggle {

    display: none;
  }

  & .navbar-nav {

    .dropdown {

      &.open,
      &:hover,
      &:focus {

        .dropdown-menu {
          display: block;
        }
      }
    }
  }
  
  & .navbar-nav > li > a {
    color: $color-navbar;

    &:focus,
    &:hover {
        background-color: inherit;
    }
  }

  & .navbar-nav > .open > a, 
  & .navbar-nav > .open > a:hover, 
  & .navbar-nav > .open > a:focus {

    color: $color-navbar;
    opacity: 0.6;
    background-color: $color-dark;
  }

  .vl {
    background-image: linear-gradient($color-navbar, $color-navbar);
    background-size: 1px 100%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 60px;
  }

  #navbar-dropdown {

    color: $color-navbar;
    background-color: $color-navbar-btn-bg;
    border: none;
/*     display: flex;
    align-items: center;
    gap: 4px; */


    & .navDropdown-iconAndLabel {

      display: flex;
      align-items: center;
      gap: 8px;
      line-height: 1;
    }
  }

  .user-dropdown {
    margin-right: 20px;
  }

  .dropdown-menu {
    margin: 0;
  }

  .dropdown-sectionTitle {

    padding: 6px 25px 10px 25px;
    color: $color-dark;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.1px;
    border-bottom: 1px solid $color-light;
  }

  .dropdown-grid-container {

    padding: 10px;
    font-weight: 500;
    width: 700px;
    max-width: 700px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-rows: auto;

    & > li > a {

      align-items: start;
      gap: 15px;
      padding: 20px 20px 20px 15px;
      border-radius: $border-radius;
    }

    .dropdown-item-iconContainer {

      display: flex;
      align-items: center;
      padding: 8px;
      border: 1px solid $color-light;
      border-radius: 4px;
      background-color: $color-white;

      i {
        font-size: 25px;
      }
    }

    .dropdown-item-title {

      color: $color-dark;
      font-size: 15px;
      font-weight: 600;
      margin-top: -2px;
    }

    .dropdown-item-description {
      color: $color-secondary;
      font-size: 12px;
      white-space: pre-wrap;
      padding-top: 2px;
    }
  }

  .btn {
    height: 36px;
    border-radius: 3px;
  }

  .btn-icon, 
  .btn a {

    display: flex;
    align-items: center;
  }

  .btn-icon {

    padding: 8px;
    margin-left: 5px;
    background-color: $color-navbar-btn-bg;

    i, span {

      color: $color-navbar;
    }

  }

  .btn-icon:active, 
  .btn-icon:active:hover, 
  .btn-icon:active:focus, 
  .btn-icon:active.focus, 
  .btn-icon.active, 
  .btn-icon.active:hover, 
  .btn-icon.active:focus, 
  .btn-icon.active.focus {

    background-color: transparent;
  }

  .btn-default {
    background-color: $color-navbar-btn-bg;
    color: $color-navbar;
    border-color: transparent;

    &:hover,
    &:focus,
    &:focus-within {
      background-color: $color-navbar-btn-bg;
      color: $color-navbar;
      border-color: transparent;
    }
  }
}

.rs-header {
  padding: 0 10px 0 4px;
}

.navbar-header {
  display: flex;
  align-items: center;
}

.rs-header-logo {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  height: 36px;
  padding: 8px 3px 5px;
  border-radius: 2px;
//  border: 1px solid $color-navbar-btn-bg;
//  background-color: $color-navbar-btn-bg;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  img {
    height: 25px;
  }
}

.rs-header-title {
  font-size: 17px;
  font-weight: 200;
  letter-spacing: .1px;
//  color: #4879ff;
}

.navbar-left {

  display: flex;
  gap: 7px;
}

.navbar-right {

  display: flex;
  align-items: center;
}

.nav > li > a {
  padding: 0px 15px;
}

@media (max-width: 767px) {

  .navbar-right {
    margin: 0;
  }

  .navbar-default .navbar-toggle {
    border: none;
    margin: 0;
    display: flex; 
    justify-content:space-between;
    width: 100%;
    align-items: center;
    gap: 10px;
    padding: 0 3px;

    &:hover,
    &:focus {
      background-color: transparent;
    }

    img {
      height: 25px;
      cursor: pointer;
    }
  }

  .rs-header-logo {

    display: none;
  }
}

@media (min-width: 768px) {

  .collapse.navbar-collapse {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .navbar-collapse::before,
  .navbar-collapse::after {
    content: unset !important;
    display: unset !important;
  }

  .navbar-nav {
    margin: 0;

    & > li,
    & > li > a {
      display: flex;
      align-items: center;
    }
  }

}

#new-resource-tab-container {
  
  margin-top: -15px;
  color: $color-dark;
  font-size: 15px;

  & .nav.nav-tabs {
    margin: 0;
  }

  .resources-tab-container {

    .griddle-custom-row {
      display: grid !important;
      grid-template-columns: repeat(auto-fill, 120px);
      grid-auto-flow: row dense;
      gap: 15px;
    }

  }

  .new-resource-card {

    height: 120px;
    border:1px solid $color-border;
    border-radius:$border-radius;
    background-color: $color-white;
    padding: 10px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    cursor: pointer;

    &:hover,
    &:focus,
    &:focus-within {

      background-color: $color-lighter;
    }

    i {
      color: $color-base;
      font-size: 35px;
    }
  }
}

.header-contact-modal__body {
  padding: 0;
}

.contact-container {

  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-template-rows: 200px auto;
  grid-template-areas:
      ". contactHeader . "
      ". contactContent .";
  column-gap: 40px;
  row-gap: 50px;

  .contact-motif {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    background: $color-secondary-light;
    background: linear-gradient(208deg, $color-secondary-light 0%, $color-secondary-light 11%, $color-medium 60%, $color-light 80%, $color-lighter 98%);
  }

  .contact-header {
    grid-area: contactHeader;
    align-self: center;

    .title {
        font-size: 40px;
        font-weight: 600;
        line-height: 1.4;
        color: $color-secondary;
    }

    .contact-icons-container {

      display: flex;
      padding-top: 13px;
  
      a {
  
          margin-right: 15px;
          text-decoration: none;
  
          &:hover,
          &:focus {
  
              text-decoration: none;
          }
  
      }
  
      a:first-child {
  
          margin-right: 18px;
      }
  
      .rs-icon, .fa {
  
          color: $color-secondary-light;
          opacity: .7;
  
          &:hover,
          &:focus {
              opacity: 1;
          }
      }

      .rs-icon {
        font-size: 27px;
      }

      .fa {
        font-size: 32px;
        margin-top: -2px;
      }
  
      img {
          height: 28px;
          opacity: .7;
  
          &:hover,
          &:focus {
              opacity: 1;
          }
      }
    }
  }

  .contact-content {
    grid-area: contactContent;
    
    .contact-content-buttons {

      margin: 20px 0;
      display: flex;
      justify-content: flex-end;
    }

    .contact-card-container {

      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 20px;
    }

    .contact-card {

      padding: 20px;
      border-radius: $border-radius;
      border: 1px solid $color-border;
      background-color: $color-white;

      display: flex;
      align-items: flex-start;
      gap: 20px;

      i {
        font-size: 34px;
        color: $color-base;
      }

      .card-title {
        color: $color-dark;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 5px;
      }

      .card-content {
        color: $color-secondary;
        font-size: 12.5px;
        line-height: 1.3;
      }
    }
  }
}