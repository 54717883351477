/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';


.overlay-lightbox {
  &__dialog {
    text-align: left;
    &.modal-dialog {
      margin: 0;
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      width: 98.5%;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  // Modal Header -------------------------------

  &__header {
    flex: 0 0 auto;
  }

  // Modal Body ---------------------------------

  &__body {
    flex: 1 1 100%;
    overflow: auto;
  }

  // Media Queries ------------------------------

  @media (min-width: $screen-sm-min) {
    margin: 0;
    width: 100%;
  }
}

.overlay-modal {
  display: flex !important;
  flex-direction: column;

  &__dialog {
    display: flex;
    max-height: 100%;

    &.modal-dialog {
   //   margin: 0 auto;
   //   padding: 10px;
    }

    .modal-content {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
    }
  }

  // Modal Header -------------------------------
  &__header {
    flex: 0 0 auto;
  }

  // Modal Body ---------------------------------
  &__body {
    flex: 1 1 auto;
    overflow: auto;
  }
}
