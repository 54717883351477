/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.Dashboard--template:has(.resource-persisted-container) {

  container-name: resource-persisted-container;
  container-type: inline-size;
}

 .resource-persisted-container {

  height: 100%;
  width: 100%;
  overflow: hidden;

  display: grid;
  grid-template-columns: 4fr minmax(280px, 1fr);
  gap: 5px;
  padding: 25px;

  .resource-persisted-visualisation {
  
    & .semantic-chart,
    & .semantic-chart__renderer {
      height: 100% !important;
    }
  }

  .resource-persisted-visualisation,
  .resource-persisted-details {

    background-color: $color-white;
    border: 1px solid $color-border;
    height: 100%;
    padding: 20px 30px;

    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
 }

 @container resource-persisted-container (width <= 950px) {

  .resource-persisted-container {
    grid-template-columns: 100%;
    gap: 10px;
    overflow: auto;
  }

  .resource-persisted-container:has(.SemanticTimeline--timeline) {
    grid-template-rows: min-content auto;
  }
}
