/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.footer {
  z-index: $zindex-footer;
  width: 100%;
  background-color: $color-footer-bg;
  color: $color-white; 

  &__pixel-groove {
    margin-top: 6px;
    border-top: 1px solid #020202;
    border-bottom: 1px solid #5a5a5a;
  }

  &__outer-flex-row {
    padding: 30px 20px 20px 40px;
  }

  a:hover {
    color: #fff;
  }
  .style--secondary-font a.text-muted {
    color: #fff;
  }

  &__sponsor-logos {
    margin-right: 50px;
    text-align: center;
  }

  &__sponsor-logo--bm {
    padding: 0 0 20px;
    img {
      width: 140px;
    }
  }

  &__sponsor-logo--mellon {
    img {
      float: right;
      width: 80px;
    }
  }

  &__sponsor-text,
  &__links-col {
    margin-top: 10px;
  }
}

/* Footer */

.rs-hp-supported {

  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $color-white;
}

.rs-hp-supported::before, 
.rs-hp-supported::after {

  content: '';
  border-top: 1px solid #706e87;
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}

.rs-hp-supported::after {

  margin: 0 0 0 20px;
}

.footer-support {

  padding-top: 28px;
  padding-bottom: 24px;
}

.footer-logo-row {

  margin-bottom: 30px;
  justify-content: space-between;
}


.footer-text {

  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #261280;

  a {
      color: $color-white;
  }
}

.footer-logo--img {

  width: auto;
}

.footer-logo--img {

      height: 21px;
  }

.footer-text-padding {

  font-size: 11px !important;

  &:not(:last-child)::after {

  color: $color-white;
  content: "|";
  padding: 0 10px;

  }
}

@media screen and (max-width: 767px) {

  .footer-text-padding {

      font-size: 8px !important;
  }

  .footer-support {

      padding-top: 24px;
      padding-bottom: 22px;
  }

  .footer-logo-row {
      margin-bottom: 25px;
  }
}


@media (min-width: 768px) {

  .footer-text-padding {

      font-size: 8px !important;
  }

  .footer-support {

      padding-top: 20px;
      padding-bottom: 18px;
  }


  .footer-logo--img {

      height: 20px;
  }

  .footer-logo-row {
      margin-bottom: 18px;
  }
}

@media (min-width: 992px) {

  .footer-text-padding {

      font-size: 9px !important;
  }

  .footer-support {

      padding-top: 20px;
      padding-bottom: 17px;
  }
  
  .footer-logo-row {
      margin-bottom: 17px;
  }

  .footer-logo--img {

      height: 22px;
  }  
}

@media (min-width: 1300px) {

  .footer-text-padding {

      font-size: 10px !important;
  }

  .xl-width {

      width: 75%;
  }

  .footer-support {

      padding-top: 28px;
      padding-bottom: 24px;
  }

  .footer-logo-row {
      margin-bottom: 38px;
  }

  .footer-logo--img {

      height: 25px;
  }
}

@media (min-width: 1500px) {

  .footer-text-padding {

      font-size: 11px !important;
  }

  .footer-logo--img {

      height: 25px;
  }
}

@media (min-width: 1700px) {

  .footer-logo--img {

      height: 28px;
  }
}
