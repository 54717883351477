/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

// .yasr style in node_modules/yasgui-yasr/dist/yasr.css

.sparqlQueryEditor {
  margin: 10px;
  margin-top: 20px;

  /* hide result fullscreen option */
  :global(.yasr_btn.btn_fullscreen.btn_icon) {
    display: none;
  }

  /* hide html snippet button */
  :global(.yasr_btn.yasr_embedBtn) {
    display: none;
  }

  /* hide table result selection if it is disable anyway, e.g for construct queries */
  :global(.yasr_btn.select_yasrTable.disabled) {
    display: none;
  }

  /* set minimal height for editor pane so "get query link dialog" is always visible */
  :global(.yasqe .CodeMirror) {
    min-height: 64px;
  //  height: auto !important;
    width: auto;
  }

  /** add top margin in fullscreen view to offset navbar and breadcrumbs height  */
  :global(.yasqe .CodeMirror-fullscreen) {
    margin-top: $nav-height + $breadcrumb-height;
  }
}

.controls {
  margin-top: 20px;
  display: flex;
  align-items: center;

  button {

    padding: 8px 10px;
    border-radius: $border-radius;
    margin-right: 5px;
  }
}

.repositorySelector {
  margin-right: 40px;
}

.repositorySelectorDropdown {
  margin-left: 10px;
}
