.ontodia-form-control {
  display: block;
  width: 100%;
  height: $input-height;
  margin: 0;
  padding: 0px 12px;
  font-size: $font-size-base;
  line-height: 1.2;
  transition: border-color ease-in-out .15s;

  color: $input-color;

  background-color: $input-color-bg;

  border-radius: $input-border-radius;
  border: 1px solid $input-border-color;


  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: $input-placeholder-color;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}
