/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 @import '~basic-styles.scss';

.component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-border;
  background-color: $color-km-bg;
  flex-wrap: wrap;

  padding: 4px 10px;

/*   :global(.btn-action) {
    padding: 7px 10px;
  } */

  :global(.dropdown-menu) {
    right: 0;
    left: unset;
  } 

  :global(.btn-group) {
    display: flex;
  }
}

.buttonsContainer {

  display: flex;
  align-items: center;
  gap:5px;
}

.group {


}

.groupSave {
}

.groupButtons {
  display: flex;
  gap: 5px;

/*   &::before {
    content: "|";
    font-size: 12px;
    height: 30px;
    padding: 6px 10px;

    align-self: center;
  } */

/*   &:first-child:before { 
    content: ""; 
    padding: 0; 
  } */
}

.languageSelector {
  height: 33px;
  display: inline-flex;
  align-items: center;
  label {
    margin: 0;
  }
}

